import axios, { AxiosRequestConfig } from "axios";
import authService from "./auth";

const setSessionId = (sessionId: string): void => {
  const now = new Date();
  localStorage.setItem("sessionIdCreatedAt", String(now.getTime()));
  localStorage.setItem("sessionId", sessionId);
};

const getSessionId = (): string | null => {
  return localStorage.getItem("sessionId");
};

const removeSessionId = (): void => {
  localStorage.removeItem("sessionId");
};

const createSession = async (
  merchantUrl: string,
  visitedFrom: string
): Promise<void> => {
  const sessionIdCreatedAt = new Date();
  sessionIdCreatedAt.setTime(
    Number(localStorage.getItem("sessionIdCreatedAt"))
  );

  const now = new Date();

  // The session is considered valid if it's been created less than 2 hour ago
  const isSessionValid =
    now.getTime() - sessionIdCreatedAt.getTime() < 1000 * 60 * 60;

  if ((!getSessionId() || !isSessionValid) && !authService.getToken()) {
    const options: AxiosRequestConfig = {
      method: "POST",
      url: `/api/stats/create-session?forMerchant=${merchantUrl}&from=${visitedFrom}`,
    };

    try {
      const response = await axios(options);
      const { sessionId } = response.data;

      setSessionId(sessionId);
    } catch (error) {}
  }
};

const wineClick = async (
  wineId: number,
  merchantUrl: string,
  wine: string,
  from: string
): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "POST",
    url: "/api/stats/wine-click",
    headers: { "x-visitor-session": getSessionId() },
    data: { wineId, merchantUrl, wine },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    if (error.response.status === 403) {
      removeSessionId();
      await createSession(merchantUrl, from);
    }
    return error.response.data;
  }
};

const getVisitors = async (merchantId: number): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: `/api/stats/get-visitors?merchantId=${merchantId}`,
    headers: { "x-access-token": authService.getToken() },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getWineClicks = async (merchantId: number): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: "/api/stats/get-wine-clicks?merchantId=" + String(merchantId),
    headers: { "x-access-token": authService.getToken() },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export default {
  createSession,
  wineClick,
  getVisitors,
  getWineClicks,
};
