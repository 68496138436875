import * as React from "react";

import { Container, Button } from "react-floating-action-button";
import { Link } from "react-router-dom";
import { FABStyle } from "./ActionButton";

import "./board.css";

export interface MapActionButtonProps {
  link: string;
  icon: string;
}

const MapActionButton: React.FunctionComponent<MapActionButtonProps> = ({
  link,
  icon,
}) => {
  const buttonStyle: FABStyle = { backgroundColor: "#7a0c4b", color: "#fff" };

  return (
    <Container className="fab-container-map">
      <Link to={link}>
        <Button icon={`fas ${icon}`} styles={buttonStyle} />
      </Link>
    </Container>
  );
};

export default MapActionButton;
