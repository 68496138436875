import React, { SyntheticEvent } from "react";
import WineCardPublic from "../WineCardPublic";
import WineCardMagazzino from "../WineCardMagazzino/WineCardMagazzino";
import { Font } from "helpers";
import { UserSettings, Wine } from "interfaces/wine_interfaces";

export interface WineSectionProps {
  whatsappHandler?: string;
  showing: boolean;
  wines: { [key: string]: Wine[] };
  fonts: Font[][];
  editable: boolean;
  handleChange?: (e: SyntheticEvent<HTMLInputElement>, id: number) => void;
  updateWineQuickAction?: (
    e: SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    wineName: string,
    skipAlert?: boolean
  ) => Promise<void>;
  updatePriceQuickAction?: (
    e: SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    glassPrice: number
  ) => void;
  sellWine?: (id: number, price: number, availability: number) => void;
  toggleWineUpdateAction?: (id: number | undefined, data: any) => void;
  inputFocused?: (e: SyntheticEvent<HTMLInputElement>) => void;
  userSettings: UserSettings;
  onWineClick?: (wineId: number, wine: Wine) => void;
  hideMap?: boolean;
}

const WineSection: React.FunctionComponent<WineSectionProps> = ({
  wines,
  fonts,
  editable,
  handleChange,
  updateWineQuickAction,
  updatePriceQuickAction,
  sellWine,
  toggleWineUpdateAction,
  inputFocused,
  userSettings,
  onWineClick,
  showing,
  whatsappHandler,
  hideMap,
}) => {
  return (
    <div
      className="wine-section"
      style={{
        transform: showing ? "scaleY(1)" : "scaleY(0)",
        opacity: showing ? 100 : 0,
        transformOrigin: "top",
      }}
    >
      {showing &&
        Object.keys(wines).map((region) => {
          return (
            <React.Fragment key={region}>
              <div id="wine-region">
                <div className="container" style={{ paddingLeft: "0" }}>
                  <div className="row">
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: editable ? "rgb(87, 36, 86)" : "#333",
                      }}
                      className="col-6"
                    ></div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div
                      style={{
                        paddingRight: "0",
                        fontFamily: fonts[userSettings.font][1].fontFamily,
                        fontWeight: 500,
                        color: editable ? "" : "#333",
                        marginBottom: 0,
                      }}
                      className={"col-6 text-left region h5"}
                    >
                      {region}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: editable ? "#fff" : "#ffffff00",
                  borderRadius: "3px",
                }}
              >
                {wines[region].map((wine) => {
                  if (editable)
                    return (
                      <WineCardMagazzino
                        key={wine.id}
                        wine={wine}
                        font={fonts[1]}
                        onChange={handleChange!}
                        updateWineQuickAction={updateWineQuickAction!}
                        updatePriceQuickAction={updatePriceQuickAction!}
                        sellWine={sellWine!}
                        onWineClick={toggleWineUpdateAction!}
                        onInputFocus={inputFocused!}
                      />
                    );
                  else
                    return (
                      <WineCardPublic
                        key={wine.id}
                        font={fonts[userSettings.font]}
                        wine={wine}
                        userSettings={userSettings}
                        onWineUserClick={onWineClick!}
                        whatsappHandler={whatsappHandler || ""}
                        hideMap={hideMap}
                      />
                    );
                })}
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default WineSection;
