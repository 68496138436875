import React from "react";
import helpers from "../../helpers";

const Settings = ({ userSettings, toggleSetting }) => {
  return (
    <>
      <label className="mt-4 mb-3">
        <b>Mostra</b>
      </label>
      {Object.keys(userSettings).map((s) => {
        return (
          s !== "id" &&
          s !== "userId" &&
          s !== "font" &&
          s !== "colorPalette" &&
          s !== "sorting" &&
          s !== "layout" &&
          s !== "sortingType" &&
          s !== "showDailyWine" &&
          !s.startsWith("ricarico") &&
          s !== "createdAt" &&
          s !== "updatedAt" && (
            <div className="row">
              <div className="col-10">
                <p className="h5">{helpers.getSettingLabel(s).label}</p>
                <p className="pl-1 text-muted mb-3">
                  {helpers.getSettingLabel(s).description}
                </p>
              </div>
              <div
                key={s}
                className="custom-control custom-switch my-auto col text-right"
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"setting" + s}
                  name={s}
                  checked={userSettings[s]}
                  onChange={toggleSetting}
                />

                <label
                  className="custom-control-label"
                  htmlFor={"setting" + s}
                ></label>
              </div>
            </div>
          )
        );
      })}
    </>
  );
};

export default Settings;
