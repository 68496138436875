import * as React from "react";

import { WineImages } from "interfaces/admin_interfaces";
import { getWineryImages } from "services/admin";
import { MerchantWine } from "interfaces/wine_interfaces";
import { getWineById } from "services/wine";

export const useWine = (wineId: number, wineryId: number) => {
  const [wineImages, setWineImages] = React.useState({} as WineImages);
  const [wine, setWine] = React.useState({} as MerchantWine);

  const [apiCalling, setApiCalling] = React.useState(true);

  React.useEffect(() => {
    const fetchWineImages = async (wineryId: number) => {
      const wineImages = await getWineryImages(wineryId);

      setWineImages(wineImages as WineImages);
    };

    const fetchWine = async (wineId: number): Promise<void> => {
      const wine = await getWineById(wineId);
      setWine(wine);
    };

    if (!wine.wineId) fetchWine(wineId);
    if (!wineImages.wineryId) fetchWineImages(wineryId);

    if (wine.wineId && wineImages.wineryId) setApiCalling(false);
  }, [wine, wineImages, apiCalling, wineId, wineryId]);

  return [wine, wineImages, apiCalling] as const;
};
