import React, { Component } from "react";
import { Link } from "react-router-dom";
import qs from "qs";

import Loader from "../Loader";
import Carta from "../Magazzino/Carta";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/free-solid-svg-icons";

import wineService from "../../services/wine";
import authService from "../../services/auth";
import statsService from "../../services/stats";
import helpers from "helpers";

import "./board.css";
import { Route, Switch } from "react-router-dom";
import MapBoardView from "./MapBoardView";
import MapActionButton from "./MapActionButton";

class Board extends Component {
  state = {
    notSortedWines: [],
    propic: undefined,
    apiCalling: true,
    wineFilter: "",
    fonts: [
      [
        {
          fontFamily: "Futura,Trebuchet MS,Arial,sans-serif",
        },
        {
          fontFamily:
            "Bodoni MT,Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif",
        },
      ],
      [
        {
          fontFamily: "Gill Sans,Gill Sans MT,Calibri,sans-serif",
        },
        {
          fontFamily:
            "Garamond,Baskerville,Baskerville Old Face,Hoefler Text,Times New Roman,serif",
        },
      ],
      [
        {
          fontFamily: "Audrey",
        },
        {
          fontFamily: "Muli",
        },
      ],
    ],
    userSettings: {
      font: 0,
    },
    whatsappHandler: "",
  };

  async componentDidMount() {
    let id = "";

    if (this.props.match) id = this.props.match.params.id;
    else id = (await authService.getLoggedUser()).cartaViniUrl;

    if (this.props.location)
      await statsService.createSession(
        id,
        qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
          .from || "web"
      );
    else await statsService.createSession(id, "web");

    const res = await wineService.getWinesForMerchantPublic(id);
    const userSettings = await authService.getUserSettingsPublic(id);

    document.title = res.name + " Carta Vini";

    this.setState({
      notSortedWines: res.wines,
      apiCalling: false,
      propic: res.propic ? "data:image/png;base64," + res.propic : "",
      userSettings,
      whatsappHandler: res.whatsappHandler,
    });
  }

  handleFilter = (e) => {
    const { value } = e.currentTarget;

    this.setState({ wineFilter: value });
  };

  handleWineClick = async (wineId, wine) => {
    if (this.props.match && this.props.location) {
      await statsService.wineClick(
        wineId,
        this.props.match.params.id,
        wine,
        qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).from
      );
    }
  };

  renderBoard = () => {
    const {
      notSortedWines,
      fonts,
      propic,
      wineFilter,
      userSettings,
      whatsappHandler,
    } = this.state;

    return (
      <div
        style={{ backgroundColor: "#fff", borderRadius: "3px" }}
        className="container pt-2 pb-4 mx-auto mb-4"
      >
        <div className="row">
          <h1
            style={{
              fontFamily: fonts[userSettings.font || 0][0].fontFamily,
            }}
            className="h1 w-100 text-center pt-4"
          >
            Carta dei Vini
          </h1>
        </div>

        <div className="row mt-2 p-4 mx-auto">
          {propic && propic !== "" && Boolean(userSettings.showLogo) && (
            <div className="col-sm-12 col-md text-center">
              <img src={propic} height="120" width="120" alt="Profile Pic" />
            </div>
          )}
          <div className="col-sm-12 col-md my-auto text-center pt-3">
            <Link to="/">
              <img src="/logo-header-black.png" height="18" alt="" />
            </Link>
          </div>
        </div>

        {!this.props.pdf && Boolean(userSettings.showSearchBar) && (
          <div className="row my-4">
            <div className="col-12">
              <input
                id="winefilter"
                className="form-control mx-auto w-100 board-input-field"
                style={{ borderColor: "#33333330" }}
                name="wine"
                placeholder="Cerca un vino, vitigno, annata, cantina o regione…"
                onChange={this.handleFilter}
                value={wineFilter}
              />

              {wineFilter.length > 0 && (
                <div
                  className="clear-input"
                  onClick={() =>
                    this.handleFilter({ currentTarget: { value: "" } })
                  }
                >
                  <FontAwesomeIcon icon={faBackspace} />
                </div>
              )}
            </div>
          </div>
        )}

        {notSortedWines && notSortedWines.length > 0 && (
          <Carta
            className="mt-2 mx-auto"
            wines={
              Boolean(userSettings.allowCustomSorting)
                ? helpers.sortByMerchantChoice(
                    notSortedWines,
                    userSettings.sortingType
                  )
                : notSortedWines
            }
            fonts={fonts}
            chosenFont={userSettings.font}
            editable={false}
            wineToLookFor={wineFilter}
            userSettings={userSettings}
            onWineClick={this.handleWineClick}
            whatsappHandler={whatsappHandler}
            showFAB={!this.props.pdf}
            pdf={this.props.pdf}
          />
        )}

        <section style={{ height: "150px" }}></section>

        {!this.props.pdf && (
          <MapActionButton
            link={window.location.pathname + "/map"}
            icon="fa-map"
          />
        )}
      </div>
    );
  };

  render() {
    const {
      notSortedWines,
      apiCalling,
      fonts,
      userSettings,
      whatsappHandler,
    } = this.state;

    if (apiCalling)
      return (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      );

    if (this.props.pdf) return this.renderBoard();

    return (
      <Switch>
        <Route
          path="/board/:url/map"
          component={() => (
            <MapBoardView
              wines={notSortedWines}
              fonts={fonts}
              chosenFont={userSettings.font}
              editable={false}
              wineToLookFor={""}
              userSettings={userSettings}
              onWineClick={this.handleWineClick}
              whatsappHandler={whatsappHandler}
            />
          )}
        />
        <Route exact path="/board/:url" component={this.renderBoard} />
      </Switch>
    );
  }
}

export default Board;
