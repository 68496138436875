import React from "react";
import LatestShoppingTable from "./LatestShoppingTable";
import TableCard from "../common/TableCard";

const LatestShopping = ({ latestShopping, className }) => {
  return (
    <TableCard
      className={className}
      title="Acquisti Recenti"
      linkTo="/app/dashboard/ultimi-acquisti"
      subtitle={`Ultimi ${latestShopping.length}`}
    >
      <LatestShoppingTable latestShopping={latestShopping} />
    </TableCard>
  );
};

export default LatestShopping;
