import * as React from "react";
import { Route, Switch } from "react-router-dom";
import Admin from "./Admin";
import WineDetails from "./WineDetails/WineDetails";

export interface AdminIndexProps {
  merchantId: number;
}

const AdminIndex: React.FunctionComponent<AdminIndexProps> = ({
  merchantId,
}) => {
  return (
    <Switch>
      <Route
        path="/app/admin/wine/:wineId/winery/:wineryId"
        component={WineDetails}
      />
      <Route
        exact
        path="/app/admin"
        component={() => <Admin merchantId={merchantId} />}
      />
    </Switch>
  );
};

export default AdminIndex;
