import * as React from "react";
import { Link } from "react-router-dom";

export interface TableCardProps {
  className: string;
  linkTo: string;
  title: string;
  subtitle: string;
}

const TableCard: React.FunctionComponent<TableCardProps> = ({
  className,
  linkTo,
  title,
  subtitle,
  children,
}) => {
  return (
    <div className={className}>
      <div className="chart-card">
        <div className="row">
          <div className="col-8">
            <h5 className="card-title ml-4 h5">
              <Link className="anchor-link" to={linkTo}>
                {title}
              </Link>
            </h5>
            <h6 className="card-title pb-2 ml-4 h6">{subtitle}</h6>
          </div>
          <div className="col-4 mb-auto">
            <Link className="anchor-link" to={linkTo}>
              Mostra di più &gt;
            </Link>
          </div>
          <div className="col-11 mx-auto">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default TableCard;
