import React, { useState, SyntheticEvent } from "react";
import { UserSettings, Wine } from "interfaces/wine_interfaces";
import helpers, { Font } from "../../helpers";
import SectionTitle from "./CartaCommon/SectionTitle";
import WineSection from "./CartaCommon/WineSection";
import ActionButton from "Components/Board/ActionButton";

interface CartaSection {
  title: string;
  data: Wine[];
  show: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  color?: string;
}

export interface CartaProps {
  whatsappHandler?: string;
  wines: Wine[];
  fonts: Font[][];
  handleChange?: (e: SyntheticEvent<HTMLInputElement>, id: number) => void;
  updateWineQuickAction?: (
    e: SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    wineName: string,
    skipAlert?: boolean
  ) => Promise<void>;
  updatePriceQuickAction?: (
    e: SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    glassPrice: number
  ) => void;
  sellWine?: (id: number, price: number, availability: number) => void;
  toggleWineUpdateAction?: (id: number | undefined, data: any) => void;
  inputFocused?: (e: SyntheticEvent<HTMLInputElement>) => void;
  editable: boolean;
  className?: string;
  wineToLookFor: string;
  userSettings: UserSettings;
  onWineClick?: (wineId: number, wine: Wine) => void;
  hideMap?: boolean;
  showFAB?: boolean;
  pdf?: boolean;
}

const Carta: React.FunctionComponent<CartaProps> = ({
  wines,
  fonts,
  handleChange,
  updateWineQuickAction,
  updatePriceQuickAction,
  sellWine,
  toggleWineUpdateAction,
  inputFocused,
  editable,
  className,
  wineToLookFor,
  userSettings,
  onWineClick,
  whatsappHandler,
  hideMap,
  showFAB,
  pdf,
}) => {
  const totalWines = wines.length;

  if (wineToLookFor && wineToLookFor.length > 0)
    wines = wines.filter(
      (w) =>
        // Wine name (winefamily, wine and fantasyName)

        w.merchantWine.wine
          .toLowerCase()
          .includes(wineToLookFor.toLowerCase()) ||
        (w.merchantWine.fantasyName &&
          w.merchantWine.fantasyName
            .toLowerCase()
            .includes(wineToLookFor.toLowerCase())) ||
        (w.merchantWine.winefamily &&
          w.merchantWine.winefamily.winefamilyText &&
          w.merchantWine.winefamily.winefamilyText
            .toLowerCase()
            .includes(wineToLookFor.toLowerCase())) ||
        (w.merchantWine.winefamily &&
          w.merchantWine.winefamily.winefamily &&
          w.merchantWine.winefamily.winefamily
            .toLowerCase()
            .includes(wineToLookFor.toLowerCase())) ||
        // Winery

        (w.merchantWine.winery &&
          w.merchantWine.winery.winery &&
          w.merchantWine.winery.winery
            .toLowerCase()
            .includes(wineToLookFor.toLowerCase())) ||
        //Region and country

        (w.merchantWine.winefamily &&
          w.merchantWine.winefamily.winedenom &&
          (w.merchantWine.winefamily.winedenom.region?.region
            .toLowerCase()
            .includes(wineToLookFor.toLowerCase()) ||
            w.merchantWine.winefamily.winedenom.region?.country?.country
              .toLowerCase()
              .includes(wineToLookFor.toLowerCase()))) ||
        (w.merchantWine.region &&
          (w.merchantWine.region.region
            .toLowerCase()
            .includes(wineToLookFor.toLowerCase()) ||
            w.merchantWine.region.country?.country
              .toLowerCase()
              .includes(wineToLookFor.toLowerCase()))) ||
        // Winevintage

        (w.vintage && w.vintage.toString().toLowerCase() === wineToLookFor) ||
        //Winegrapes

        w.merchantWine.winegrapes.filter((wg) =>
          wg.winegrape.toLowerCase().includes(wineToLookFor.toLowerCase())
        ).length > 0
    );

  const rossi = wines.filter(
    (w) =>
      helpers.winecolorEquals(w.merchantWine, "Rosso") &&
      (helpers.winetypeEquals(w.merchantWine, "Fermo") ||
        helpers.winetypeEquals(w.merchantWine, "Frizzante"))
  );
  const [showRossi, toggleRossi] = useState(true);

  const bianchi = wines.filter(
    (w) =>
      helpers.winecolorEquals(w.merchantWine, "Bianco") &&
      (helpers.winetypeEquals(w.merchantWine, "Fermo") ||
        helpers.winetypeEquals(w.merchantWine, "Frizzante"))
  );
  const [showBianchi, toggleBianchi] = useState(true);

  const rosati = wines.filter(
    (w) =>
      helpers.winecolorEquals(w.merchantWine, "Rosato") &&
      (helpers.winetypeEquals(w.merchantWine, "Fermo") ||
        helpers.winetypeEquals(w.merchantWine, "Frizzante"))
  );
  const [showRosati, toggleRosati] = useState(true);

  const bolle = wines.filter(
    (w) =>
      w.merchantWine.winefamily?.winetype.winetype.slice(0, 8) === "Spumante" ||
      w.merchantWine.winetype?.winetype.slice(0, 8) === "Spumante"
  );
  const [showBolle, toggleBolle] = useState(true);

  const passiti = wines.filter((w) =>
    helpers.winetypeEquals(w.merchantWine, "Passito")
  );
  const [showPassiti, togglePassiti] = useState(true);

  const liquorosi = wines.filter((w) =>
    helpers.winetypeEquals(w.merchantWine, "Liquoroso")
  );
  const [showLiquorosi, toggleLiquorosi] = useState(true);

  const winesWithMissingData = wines.filter((w) => {
    return (
      !liquorosi.includes(w) &&
      !passiti.includes(w) &&
      !rossi.includes(w) &&
      !rosati.includes(w) &&
      !bianchi.includes(w) &&
      !bolle.includes(w)
    );
  });
  const [showAltro, setShowAltro] = React.useState(true);

  const sections: CartaSection[] = [
    {
      title: "Bollicine",
      data: bolle,
      show: showBolle,
      toggle: toggleBolle,
      color: "#9e934d",
    },
    {
      title: "Vini Bianchi",
      data: bianchi,
      show: showBianchi,
      toggle: toggleBianchi,
      color: "#bcb37b",
    },
    {
      title: "Vini Rosati",
      data: rosati,
      show: showRosati,
      toggle: toggleRosati,
      color: "#9f5057",
    },
    {
      title: "Vini Rossi",
      data: rossi,
      show: showRossi,
      toggle: toggleRossi,
      color: "#790000",
    },
    {
      title: "Passiti",
      data: passiti,
      show: showPassiti,
      toggle: togglePassiti,
      color: "#e5be01",
    },
    {
      title: "Liquorosi",
      data: liquorosi,
      show: showLiquorosi,
      toggle: toggleLiquorosi,
      color: "#5b0b0b",
    },
    {
      title: "Non Classificati",
      data: winesWithMissingData,
      show: showAltro,
      toggle: setShowAltro,
    },
  ];

  return (
    <div className={className}>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            {wineToLookFor.length > 0 && (
              <p className="lead">
                <strong>{wines.length}</strong>
                {wines.length === 1 ? " Vino Trovato " : " Vini Trovati "}
                <strong>su {totalWines}</strong>
              </p>
            )}
          </div>
        </div>
      </div>

      {sections.map((section) => (
        <React.Fragment key={section.title}>
          {section.data.length > 0 && (
            <React.Fragment>
              <SectionTitle
                title={section.title}
                winesNumber={section.data.length}
                editable={editable}
                fonts={fonts}
                userSettings={userSettings}
                onCollapse={section.toggle}
                showing={section.show}
                pdf={pdf}
              />
              <WineSection
                hideMap={hideMap}
                showing={section.show}
                wines={helpers.groupByRegion(section.data)}
                fonts={fonts}
                editable={editable}
                handleChange={handleChange}
                updateWineQuickAction={updateWineQuickAction}
                updatePriceQuickAction={updatePriceQuickAction}
                sellWine={sellWine}
                toggleWineUpdateAction={toggleWineUpdateAction}
                inputFocused={inputFocused}
                userSettings={userSettings}
                onWineClick={onWineClick}
                whatsappHandler={whatsappHandler}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      ))}

      {showFAB && <ActionButton sections={sections} />}
    </div>
  );
};

export default Carta;
