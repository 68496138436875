import React, { Dispatch, ReactElement, SetStateAction } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { Font } from "helpers";
import { UserSettings } from "interfaces/wine_interfaces";

export interface SectionTitleProps {
  title: string;
  winesNumber: number;
  editable: boolean;
  fonts: Font[][];
  userSettings: UserSettings;
  onCollapse: Dispatch<SetStateAction<boolean>>;
  showing: boolean;
  pdf?: boolean;
}

const SectionTitle: React.FunctionComponent<SectionTitleProps> = ({
  title,
  winesNumber,
  editable,
  fonts,
  userSettings,
  onCollapse,
  showing,
  pdf,
}): ReactElement => {
  return (
    <section className="wine-title" id={title}>
      <div
        style={{ cursor: "pointer" }}
        className={"container" /* + (showing ? "" : " pb-4")*/}
        id="wine-title"
        onClick={() => onCollapse(!showing)}
      >
        <div className={"row " + (editable ? "mt-4" : "mt-2")}>
          <div
            style={{
              paddingRight: "0",
              fontFamily: fonts[userSettings.font][0].fontFamily,
              textTransform: "uppercase",
            }}
            className={"col-8 text-left h4 " + (!editable ? "h4" : "")}
          >
            <b style={{ fontWeight: 700, color: editable ? "" : "#333" }}>
              {title}
            </b>{" "}
            <i style={{ fontSize: "10pt" }}>
              {!showing && ` ${winesNumber} vini nascosti`}
            </i>
          </div>
          {!pdf && (
            <div className="col-4 my-auto">
              <FontAwesomeIcon
                style={{
                  color: "#93024e",
                  transform: showing ? "none" : "rotate(90deg)",
                  transition: "transform 250ms ease-in-out",
                }}
                icon={faChevronCircleDown}
                size="lg"
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SectionTitle;
