import React from "react";
import Form from "../common/Form";
import { Link } from "react-router-dom";
import authService from "../../services/auth";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import "./css/Register.css";
import Loader from "Components/Loader";

const required = (field) => {
  return { message: `Il campo '${field}' non può essere lasciato vuoto` };
};

const requiredPassword = (field, length) => {
  return {
    message: `Il campo '${field}' deve contenere almeno ${length} caratteri, di cui uno maiuscolo, un numero e un carattere speciale`,
  };
};

class Register extends Form {
  constructor() {
    super();
    this.captchaRef = React.createRef();
  }

  state = {
    data: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      countryId: 1,
    },
    error: null,
    showPassword: false,
    termsAccepted: false,
    apiCalling: true,
    step: 0,
    merchantId: null,
    regions: [],
    provinces: [],
    sortedProvinces: [],
  };

  async componentDidMount() {
    const data = await authService.getProvincesAndRegions();

    if (data.success) {
      this.setState({
        regions: data.regions,
        provinces: data.provinces,
        apiCalling: false,
      });
    }
  }

  schema = {
    name: Joi.string()
      .required()
      .error(() => required("Nome")),
    socialReason: Joi.string()
      .required()
      .error(() => required("Ragione Sociale")),
    regionId: Joi.number()
      .required()
      .error(() => required("Regione")),
    provinceId: Joi.number()
      .required()
      .error(() => required("Provincia")),
    city: Joi.string()
      .required()
      .error(() => required("Città")),
    email: Joi.string()
      .email({ minDomainSegments: 2 })
      .required()
      .error(() => required("Email")),
    password: Joi.string()
      .required()
      .min(8)
      .error(() => requiredPassword("Password", 8)),
    confirmPassword: Joi.string()
      .required()
      .min(8)
      .error(() => requiredPassword("Conferma Password", 8)),
    countryId: Joi.string()
      .required()
      .error(() => required("Nazione")),
    p_iva: Joi.string()
      .required()
      .alphanum()
      .length(11)
      .error(() => {
        return {
          message:
            required("Partiva IVA").message + " e deve contenere 11 caratteri",
        };
      }),
    address: Joi.string()
      .required()
      .error(() => required("Indirizzo")),
    postal_code: Joi.string()
      .required()
      .length(5)
      .alphanum()
      .error(() => required("Codice Postale")),
    tel_number: Joi.string()
      .required()
      .alphanum()
      .min(3)
      .max(20)
      .error(() => required("Numero di Telefono")),
    invoiceCode: Joi.string().error(() =>
      required("Codice di Fatturazione Elettronica")
    ),
    PEC: Joi.string()
      .email({ minDomainSegments: 2 })
      .error(() => required("PEC")),
    houseNumber: Joi.string()
      .required()
      .error(() => required("Numero Civico")),
    captcha: Joi.string().error(() => required("Captcha")),
  };

  handleSelectChange = (e) => {
    const { name, value } = e.currentTarget;
    const { data } = this.state;

    data[name] = Number(value);

    if (name === "regionId") {
      const { provinces } = this.state;

      this.setState({
        sortedProvinces: provinces.filter((p) => p.idRegion === Number(value)),
      });
    }

    this.setState({ data });
  };

  handleFirstStepSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = this.state.data;
    const { termsAccepted } = this.state;

    let captcha = this.captchaRef.current.getValue() || " ";

    if (captcha === null) captcha = " ";

    if (password === confirmPassword) {
      this.setState({ apiCalling: true });

      try {
        const response = await authService.firstStepRegister(
          name,
          email,
          password,
          captcha,
          termsAccepted
        );
        const { success, merchantId } = response.data;

        this.setState({ apiCalling: false });

        if (success) {
          this.setState({ step: this.state.step + 1, merchantId });
        }
      } catch (err) {
        let error = {};

        this.setState({ apiCalling: false });

        if (err.response) {
          const { alreadyExisting, message } = err.response.data;

          if (alreadyExisting) {
            const { token } = await authService.login(email, password);

            authService.setToken(token);
            this.props.onAuthentication(token);
          } else {
            //Handle the 2 different errors (Validation/Authentication)
            if (message.details) {
              error = { details: message.details };
            } else {
              error = { details: [{ message }] };
            }
          }

          this.setState({ error });
        }
      }
    } else {
      const error = {
        details: [{ message: "Le password non corrispondono." }],
      };

      this.setState({ error });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.validate();

    const { error, merchantId } = this.state;

    if (!error) {
      const {
        socialReason,
        regionId,
        provinceId,
        city,
        email,
        password,
        confirmPassword,
        countryId,
        p_iva,
        address,
        postal_code,
        tel_number,
        name,
        invoiceCode,
        PEC,
        houseNumber,
      } = this.state.data;

      if (password === confirmPassword) {
        this.setState({ apiCalling: true });

        try {
          const response = await authService.register(
            {
              merchantId,
              socialReason,
              regionId,
              provinceId,
              city,
              email,
              countryId,
              p_iva,
              address,
              postal_code,
              tel_number,
              name,
              invoiceCode,
              PEC,
              houseNumber,
            },
            password
          );

          const { token } = response;

          authService.setToken(token);
          this.props.onAuthentication(token);
        } catch (err) {
          let error = {};

          if (err.response) {
            const { message } = err.response.data;

            //Handle the 2 different errors (Validation/Authentication)
            if (message.details) {
              error = { details: message.details };
            } else {
              error = { details: [{ message }] };
            }

            this.setState({ error, apiCalling: false });
          }
        }
      } else {
        const error = {
          details: [{ message: "Le password non corrispondono." }],
        };

        this.setState({ error });
      }
    }
  };

  toggleTermsAcceptance = () => {
    this.setState({ termsAccepted: !this.state.termsAccepted });
  };

  onLoadRecaptcha = () => {
    if (this.captchaRef) {
      this.captchaRef.reset();
      this.captchaRef.execute();
    }
  };

  verifyCallback = (/* token */) => {
    //console.log(token);
  };

  login = async (email, password) => {
    this.setState({ apiCalling: true });

    try {
      const { token } = await authService.login(email, password);
      authService.setToken(token);
      this.props.onAuthentication(token);
    } catch (err) {
      const error = { details: ["Email o password non valide"] };
      this.setState({ error, apiCalling: false });
    }
  };

  render() {
    const { name, email, password, confirmPassword } = this.state.data;
    const { error, data, termsAccepted, showPassword, apiCalling } = this.state;
    const { toggleTermsAcceptance } = this;

    return (
      <div
        style={{ height: "100vh" }}
        className="container d-flex justify-content-center align-middle my-auto mx-auto"
      >
        <div className="form-signup align-middle mx-auto">
          {this.state.step === 0 && (
            <form onSubmit={this.handleFirstStepSubmit}>
              <div className="d-flex justify-content-center mb-4">
                <img
                  src="/logo-header-black.png"
                  alt=""
                  width="auto"
                  height="28"
                />
              </div>
              <div className="input-group m-2 mt-3 mx-auto">
                {this.renderInput(
                  "name",
                  "Nome del Locale",
                  "text",
                  true,
                  data.name
                )}
              </div>

              <div className="input-group m-2 mt-3 mx-auto">
                {this.renderInput(
                  "email",
                  "Indirizzo Email",
                  "text",
                  true,
                  data.email
                )}
              </div>

              <div className="input-group m-2 mt-3 mx-auto">
                {this.renderInput(
                  "password",
                  "Password",
                  this.state.showPassword ? "text" : "password",
                  false,
                  data.password
                )}

                {this.renderInput(
                  "confirmPassword",
                  "Conferma Password",
                  this.state.showPassword ? "text" : "password",
                  false,
                  data.confirmPassword
                )}

                <div className="input-group-prepend">
                  <i
                    className="btn btn-md btn-outline-secondary"
                    onClick={this.togglePassword}
                  >
                    {showPassword && <FontAwesomeIcon icon={faEye} size="md" />}
                    {!showPassword && (
                      <FontAwesomeIcon icon={faEyeSlash} size="sm" />
                    )}
                  </i>
                </div>
              </div>

              <div className="w-50 mx-auto my-2">
                <div style={{ width: "304px" }} className="mx-auto">
                  <ReCAPTCHA
                    ref={this.captchaRef}
                    theme="dark"
                    sitekey="6LddUcYZAAAAACGfL-KMRM9NPcndJuajy2fblDn3"
                  />
                </div>
              </div>

              <div className="custom-control custom-checkbox mt-4">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="terms"
                  value={termsAccepted}
                  onChange={toggleTermsAcceptance}
                />
                <label className="custom-control-label" htmlFor="terms">
                  Effettuando la registrazione di un nuovo account accetto il
                  trattamento dei miei dati come descritto nei{" "}
                  <a
                    className="anchor-link"
                    href="https://www.iubenda.com/termini-e-condizioni/67730356"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termini e condizioni
                  </a>{" "}
                  e nella{" "}
                  <a
                    className="anchor-link"
                    href="https://www.iubenda.com/privacy-policy/67730356"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>

              {!apiCalling && (
                <button
                  disabled={
                    name.trim().length === 0 ||
                    email.trim().length === 0 ||
                    password.trim().length === 0 ||
                    confirmPassword.trim().length === 0 ||
                    error ||
                    !termsAccepted
                  }
                  className="mx-auto btn btn-lg btn-primary btn-block m-2 mt-4"
                  type="submit"
                >
                  Crea un account
                </button>
              )}
              {apiCalling && <Loader />}
            </form>
          )}

          {this.state.step === 1 && (
            <form onSubmit={this.handleSubmit}>
              <h1 className="h3 mb-3 font-weight-normal text-center">
                Dati di fatturazione
              </h1>

              <div className="input-group m-2 mx-auto">
                {this.renderInput(
                  "socialReason",
                  "Ragione Sociale",
                  "text",
                  true,
                  data.socialReason
                )}
              </div>

              <div className="input-group m-2 mt-3 mx-auto">
                {this.renderInput(
                  "PEC",
                  "Posta Elettronica Certificata",
                  "text",
                  true,
                  data.PEC
                )}
              </div>

              <div className="input-group m-2 mt-3 mx-auto">
                {this.renderInput(
                  "invoiceCode",
                  "Codice di Fatturazione Elettronica",
                  "text",
                  true,
                  data.invoiceCode
                )}
              </div>

              <div className="input-group m-2 mt-3 mx-auto">
                {this.renderInput(
                  "p_iva",
                  "Partita IVA",
                  "text",
                  false,
                  data.p_iva
                )}
              </div>

              <div className="input-group m-2 mt-3 mx-auto">
                <select
                  onChange={this.handleSelectChange}
                  name="regionId"
                  className="form-control"
                >
                  <option>Seleziona una regione</option>
                  {this.state.regions.map((r) => (
                    <option key={r.regionId} value={r.regionId}>
                      {r.region}
                    </option>
                  ))}
                </select>

                <select
                  onChange={this.handleSelectChange}
                  name="provinceId"
                  className="form-control"
                >
                  <option>Seleziona una provincia</option>
                  {this.state.sortedProvinces.map((p) => (
                    <option key={p.idProvince} value={p.idProvince}>
                      {p.name}
                    </option>
                  ))}
                </select>

                {this.renderInput(
                  "postal_code",
                  "Codice Postale",
                  "text",
                  false,
                  data.postal_code
                )}
              </div>

              <div className="input-group m-2 mt-3 mx-auto">
                {this.renderInput("city", "Città", "text", false, data.city)}
                {this.renderInput(
                  "address",
                  "Indirizzo",
                  "text",
                  false,
                  data.address
                )}
                {this.renderInput(
                  "houseNumber",
                  "Numero Civico",
                  "text",
                  false,
                  data.houseNumber
                )}
              </div>

              <div className="input-group m-2 mt-3 mx-auto">
                {this.renderInput(
                  "tel_number",
                  "Numero di telefono",
                  "text",
                  false,
                  data.tel_number
                )}
              </div>

              {!apiCalling && (
                <div className="row">
                  <div className="col">
                    <button
                      disabled={email === "" || password === "" || error}
                      className="mx-auto btn btn-lg btn-secondary btn-block m-2 mt-3"
                      onClick={() => this.login(email, password)}
                    >
                      Salta
                    </button>
                  </div>
                  <div className="col">
                    <button
                      disabled={email === "" || password === "" || error}
                      className="mx-auto btn btn-lg btn-primary btn-block m-2 mt-3"
                      type="submit"
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              )}

              {apiCalling && <Loader />}
            </form>
          )}

          {error && (
            <div className="alert alert-danger mx-auto">
              {error.details.map((ed) => (
                <p key={ed.message}>{ed.message}</p>
              ))}
            </div>
          )}

          <p className="mx-auto pt-3 text-center">
            Hai già un account?{" "}
            <Link className="anchor-link" to="/app/login">
              Effettua il Login
            </Link>
          </p>
          <p className="mt-5 mb-3 text-muted mx-auto text-center">
            {this.props.footer}
          </p>
        </div>
      </div>
    );
  }
}

export default Register;
