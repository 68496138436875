import React from "react";
import SectionHeader from "./SectionHeader";

const TypographyComponent = ({ fonts, userSettings, onSettingChange }) => {
  return (
    <div className="row">
      <SectionHeader title="Tipografia" />

      {fonts.map((f, index) => (
        <div
          className="col-md-12 col-lg m-2"
          onClick={() => onSettingChange("font", index)}
          key={index}
        >
          <div
            className={
              "card " +
              (index === userSettings.font ? "border-primary-custom" : "")
            }
            style={{ background: "none" }}
          >
            <img className="card-img" src={"/" + f[0].img} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TypographyComponent;
