import { Bottle } from "interfaces/wine_interfaces";
import React, { ReactElement } from "react";
import { PrecompiledWineData } from "../Magazzino";

export interface Step3Props {
  step: number;
  data: any;
  renderInput: (
    name: string,
    label: string,
    value: number,
    placeholder: string,
    schema?: any,
    autofocus?: boolean,
    type?: string,
    unit?: string,
    required?: boolean,
    ricarico?: any
  ) => ReactElement;
  onChange: (data: PrecompiledWineData) => void;
  onWineGlass: () => void;
  onBestBefore: () => void;
  schema: any;
  ricarico: any;
  suggestedPrice: number;
  bottles: Bottle[];
}

const Step3: React.FunctionComponent<Step3Props> = ({
  step,
  data,
  renderInput,
  onChange,
  onWineGlass,
  onBestBefore,
  schema,
  ricarico,
  suggestedPrice,
  bottles,
}) => {
  if (step < 3) return null;

  /**
   *
   * @param {number} price The price of which to take the "ricarico"
   * @param {number} takeLower If true, it takes a one step back ricarico (e.g. if the ricarico should be 50, it takes the 20 one)
   */
  const getRicaricoForPrice = (price: number, takeLower = false): number => {
    if (price < 5 || (price < 10 && takeLower)) return ricarico.ricarico5;
    else if (price < 10 || (price < 20 && takeLower))
      return ricarico.ricarico10;
    else if (price < 20 || (price < 50 && takeLower))
      return ricarico.ricarico20;
    else if (price < 50 || takeLower) return ricarico.ricarico50;
    else return ricarico.ricaricoMax;
  };

  const getPriceFromSell = (suggestedPrice: number): number => {
    const price: number =
      (suggestedPrice * 100) / (100 + getRicaricoForPrice(suggestedPrice));

    const isRicaricoRight: boolean =
      getRicaricoForPrice(price) === getRicaricoForPrice(suggestedPrice);

    const correctedPrice: number | undefined = isRicaricoRight
      ? undefined
      : (suggestedPrice * 100) /
        (100 + getRicaricoForPrice(suggestedPrice, true));

    return Number(
      suggestedPrice > 0
        ? correctedPrice
          ? correctedPrice.toFixed(2)
          : price.toFixed(2)
        : -1
    );
  };

  const renderOptionalInputField = (
    condition: boolean,
    onChange: () => void,
    checkboxId: string,
    checkboxLabel: string,
    inputLabel: string,
    inputName: string,
    inputData: number,
    unit?: string,
    paragraph?: string,
    placeholder?: string
  ) => (
    <>
      <div className="mt-2">
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input my-auto"
            id={checkboxId}
            checked={condition}
            onChange={onChange}
          />
          <label className="custom-control-label my-auto" htmlFor={checkboxId}>
            {checkboxLabel}
          </label>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          {condition &&
            renderInput(
              inputName,
              inputLabel,
              inputData,
              placeholder || "",
              null,
              false,
              "number",
              unit,
              false
            )}
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          {condition && <p className="lead mt-2">{paragraph}</p>}
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="row mt-4">
        <h1 className="h3 font-weight-normal text-center w-100 mx-auto">
          Scorte
        </h1>
      </div>

      <div className="row mb-2">
        <div className="col mr-2">
          {renderInput(
            "wineprice",
            "Prezzo d'acquisto",
            data.wineprice,
            getPriceFromSell(suggestedPrice) > 0
              ? "Consigliato € " + getPriceFromSell(suggestedPrice)
              : "",
            schema,
            false,
            "number",
            "€",
            true,
            ricarico
          )}
        </div>

        <div className="col ml-2">
          {renderInput(
            "wineSellPrice",
            "Prezzo di Vendita",
            data.wineSellPrice,
            suggestedPrice > 0 ? "Consigliato € " + String(suggestedPrice) : "",
            schema,
            false,
            "number",
            "€",
            true
          )}
        </div>
      </div>

      {renderOptionalInputField(
        data.wineGlass,
        onWineGlass,
        "wineglass",
        "Disponibile al calice",
        "Prezzo al calice",
        "wineGlassPrice",
        data.wineGlassPrice,
        "€"
      )}

      {renderOptionalInputField(
        data.showBestBefore,
        onBestBefore,
        "wineBestBefore",
        "Imposta un anno di deterioramento",
        "Deterioramento",
        "bestBefore",
        isNaN(parseInt(data.bestBefore)) ? 0 : parseInt(data.bestBefore),
        undefined,
        "Ti avviseremo quando i tuoi vini saranno a rischio deterioramento",
        `${new Date().getFullYear()}`
      )}

      <div className="row mt-2">
        <div className="col my-auto">
          {renderInput(
            "availability",
            "Bottiglie disponibili",
            data.availability,
            "",
            schema,
            false,
            "number",
            undefined,
            true
          )}
        </div>

        <div className="col my-auto">
          <div className="mt-2">
            <label htmlFor="vintage">Formato</label>
            <select
              id="vintage"
              value={data.idBottle}
              className="custom-select w-100 mt-1"
              onChange={(e) =>
                onChange({ idBottle: Number(e.currentTarget.value) })
              }
            >
              {bottles.map((b) => {
                return (
                  <option key={b.idBottle} value={b.idBottle}>
                    {b.bottle} - ({Number(b.liters).toFixed(2)} L)
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
