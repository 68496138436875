import React, { Component } from "react";
import SubscriptionCard from "./SubscriptionCard";
import Loader from "../Loader";
import subscriptionsService from "../../services/subscriptions";
import "./subscription.css";

class Subscribe extends Component {
  state = {
    subscriptions: [],
    subscriptionDone: false,
    currentSub: undefined,
    apiCalled: false,
  };

  async componentDidMount() {
    if (!this.props.hasSubscription) {
      const subscriptions = await subscriptionsService.getSubscriptions();
      const currentSub = await subscriptionsService.getSubscription();

      this.setState({ subscriptions, currentSub, apiCalled: true });
    } else {
      subscriptionsService.retrieveCustomerPortal(
        this.props.user.stripeCustomerId,
        this.props.history
      );
    }
  }

  handleSubSelection = async (planId) => {
    this.setState({ apiCalled: false });
    const response = await subscriptionsService.createStripeSession(planId);

    if (response) {
      const { sessionId } = response;
      subscriptionsService.redirectToSession(sessionId);
    }
  };

  handleSubscriptionRenewChange = async () => {
    const { currentSub: sub } = this.state;

    sub.cancelOnEnd = !sub.cancelOnEnd;

    this.setState({ currentSub: sub });

    const { subId, customerId, stripeId, cancelOnEnd } = sub;

    await subscriptionsService.changePlan(
      subId,
      customerId,
      stripeId,
      cancelOnEnd
    );

    this.props.onSubRefresh();
  };

  toggleSelectedCard = (subId) => {
    if (this.state.selectedSub === subId) return "selectedSub";
  };

  toggleConfetti = () => {
    this.setState({ subscriptionDone: true });
  };

  render() {
    const { subscriptions, apiCalled } = this.state;

    if (!apiCalled) return <Loader />;

    return (
      <div className="container">
        <div className="row mt-4">
          <h2 className="col text-center">Scegli un piano</h2>
        </div>
        <div className="row">
          {subscriptions &&
            subscriptions.length > 0 &&
            subscriptions.map((sub) => (
              <SubscriptionCard
                toggleSelectedCard={this.toggleSelectedCard}
                onSubSelection={this.handleSubSelection}
                sub={sub}
                key={sub.planId}
              />
            ))}
        </div>
        <div className="row p-4">
          <p className="lead">
            Wineboard è disponibili attraverso sottoscrizioni{" "}
            <b>autorinnovanti</b>. Puoi cancellare la sottoscrizione quando vuoi
            <b>senza il pagamento di costi aggiuntivi</b>. I pagamenti saranno
            addebitati in automatico <b>mensilmente</b> sul numero di carta che
            indicherai di seguito. Le sottoscrizioni si rinnoveranno
            automaticamente a meno che tu non decida di interrompere la
            sottoscrizione almeno <b>24 ore prima</b> del rinnovo automatico. Il
            tuo account sarà addebitato per il rinnovo automatico nelle 24 ore
            precedenti la scadenza del periodo di abbonamento in corso. Puoi
            gestire e cancellare le tue sottoscrizioni accedendo al tuo profilo
            ed andando alla sezione “Il tuo piano”. Non puoi cancellare la
            sottoscrizione per la quale hai già pagato, ma puoi impedirne il
            rinnovo automatico.
          </p>
        </div>
      </div>
    );
  }
}

export default Subscribe;
