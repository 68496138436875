import Loader from "Components/Loader";
import React, { Component } from "react";
import Confetti from "react-confetti";
import subscriptionService from "../../services/subscriptions";

class Success extends Component {
  state = {
    subscriptionDone: false,
  };

  async componentDidMount() {
    const queryStrings = this.props.location.search.split("=");

    if (queryStrings.length > 1 && this.props.merchantId) {
      const sessionId = queryStrings[1];
      const response = await subscriptionService.registerCustomerForMerchant(
        this.props.merchantId,
        sessionId
      );

      if (response && response.success)
        this.setState({ subscriptionDone: true });
    }
  }

  render() {
    const { subscriptionDone } = this.state;
    const { innerWidth, innerHeight } = window;

    return (
      <div
        style={{ height: "100vh" }}
        className="container mt-4 d-flex align-items-center"
      >
        {subscriptionDone && (
          <>
            <Confetti width={innerWidth} height={innerHeight} />

            <div className="row">
              <div className="col">
                <h2 className="h2 text-center">Congratulazioni</h2>
                <p className="lead w-75">
                  Sei ufficialmente parte di Wineboard e non potremmo essere più
                  felici!
                  <br />
                  <a className="anchor-link" href="/app/magazzino">
                    Torna al magazzino
                  </a>{" "}
                  per cominciare a costruire la tua personale carta vini o{" "}
                  <a className="anchor-link" href="/app/dashboard">
                    vai alla Dashboard
                  </a>{" "}
                  per ottenere dati sulla vendita dei tuoi vini e consigli sui
                  vini più apprezzati dai tuoi clienti
                </p>
              </div>
            </div>
          </>
        )}

        {!subscriptionDone && <Loader />}
      </div>
    );
  }
}

export default Success;
