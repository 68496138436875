import React from "react";
import Form from "../common/Form";
import Joi from "joi-browser";
import Step1 from "./Form/Step1";
import Step2 from "./Form/Step2";
import Step3 from "./Form/Step3";
import wineService from "../../services/wine";
import Loader from "../Loader";
import "./wine.css";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import authService from "../../services/auth";

class AddWine extends Form {
  state = {
    data: {
      wine: -1,
      wineText: "",
      wineDesc: "",
      winery: -1,
      wineryText: "",
      winegrapes: [
        { winegrapeId: undefined, winegrape: "", percentage: 100.0 },
      ],
      winefamilyText: "",
      winefamily: -1,
      wineregion: -1,
      winecountry: 1,
      winecolor: -1,
      winetype: 1,
      winevintage: -1,
      wineprice: 0,
      wineSellPrice: 0,
      wineGlass: false,
      wineGlassPrice: 0,
      availability: 1,
      winealcohol: -1,
      suggestedPrice: -1,
      idBottle: 4,
      createdByMerchant: false,
      clonedWineId: -1,
      isWinePublic: false,
      bestBefore: undefined,
      showBestBefore: false,
    },
    suggestions: {},
    ricarico: {
      ricarico5: 0,
      ricarico10: 0,
      ricarico20: 0,
      ricarico50: 0,
      ricaricoMax: 0,
    },
    step: 1,
    totalSteps: 3,
    error: undefined,
    apiCalled: false,
    editMode: false, // To check if the user is editing a wine or creating a new one
    originalWineId: -1,
  };

  async componentDidMount() {
    const suggestions = await wineService.getFormData();
    const userSettings = await authService.getUserSettings();

    let { data, editMode } = this.state;
    const { data: wineMerchant } = this.props;

    let step = 1;

    if (wineMerchant) {
      const { merchantWine: wine } = wineMerchant;
      const { winery, winefamily } = wine;
      const { region } = winefamily ? winefamily.winedenom : wine;

      data.wineMerchantId = wineMerchant.id; // The id of the relationship

      data.wine = wine.wineId; // The id of the wine
      data.wineText = wine.wine;

      data.winealcohol = wineMerchant.alcohol;

      data.wineDesc = wineMerchant.description;

      data.winery = winery.wineryId;
      data.wineryText = winery.winery;

      data.winefamily = winefamily ? winefamily.winefamilyId : -1;
      data.winefamilyText = winefamily ? winefamily.winefamily : "";

      data.wineregion = region ? region.regionId : -1;
      data.winecountry = region ? region.countryId : -1;
      data.winecolor = winefamily ? winefamily.winecolorId : wine.winecolorId;
      data.winetype = winefamily ? winefamily.winetypeId : wine.winetypeId;

      data.winevintage = wineMerchant.vintage || -1;
      data.wineprice = wineMerchant.purchasePrice;
      data.wineSellPrice = wineMerchant.price;

      data.wineGlass = wineMerchant.glassPrice > 0;
      data.wineGlassPrice = wineMerchant.glassPrice;

      data.showBestBefore = !!wineMerchant.bestBefore;
      data.bestBefore = wineMerchant.bestBefore
        ? wineMerchant.bestBefore
        : null;

      data.availability = wineMerchant.availability;
      data.idBottle = wineMerchant.idBottle;

      data.createdByMerchant = !!wineMerchant.merchantWine.createdByMerchant;

      data.clonedWineId = wine.wineId;

      wineMerchant.merchantWine.winegrapes.forEach((wg) => {
        const winegrape = {
          winegrapeId: wg.winegrapeId,
          winegrape: wg.winegrape,
          percentage: wg.m_wine_winegrape.winegrapePercentage,
        };

        data.winegrapes.unshift(winegrape);
      });

      step = 3;

      editMode = true;
    }

    const ricarico = {
      ricarico5: userSettings.ricarico5,
      ricarico10: userSettings.ricarico10,
      ricarico20: userSettings.ricarico20,
      ricarico50: userSettings.ricarico50,
      ricaricoMax: userSettings.ricaricoMax,
    };

    this.setState({
      suggestions,
      apiCalled: true,
      data,
      editMode,
      step,
      ricarico,
      originalWineId: wineMerchant ? wineMerchant.id : -1,
    });
  }

  componentWillUnmount() {
    this.props.onStopAddingWine();
  }

  schemaStep1 = {
    wineText: Joi.string().error(() => {
      return {
        message: "Vino non può essere lasciato vuoto",
      };
    }),
    wineryText: Joi.string().error(() => {
      return {
        message: "Produttore non può essere lasciato vuoto",
      };
    }),
  };

  schemaStep2 = {
    winecolor: Joi.number()
      .integer()
      .min(0)
      .error(() => {
        return {
          message: "Seleziona un colore",
        };
      }),
    winetype: Joi.number()
      .integer()
      .min(0)
      .error(() => {
        return {
          message: "Seleziona una tipologia",
        };
      }),
    winecountry: Joi.number()
      .integer()
      .min(0)
      .error(() => {
        return {
          message: "Seleziona una nazione",
        };
      }),
  };

  schemaStep3 = {
    wineSellPrice: Joi.number()
      .min(1)
      .error(() => {
        return {
          message: "Specifica il prezzo di vendita per questa bottiglia",
        };
      }),
    availability: Joi.number()
      .integer()
      .min(0)
      .error(() => {
        return {
          message: "Specifica il numero di bottiglie disponibili in magazzino",
        };
      }),
  };

  onSubmit = (e) => {
    if (this.state.editMode) this.props.onWineUpdate(this.state.data);
    else this.props.onWineAdd(this.state.data);
  };

  handleBack = () => {
    let { step } = this.state;

    step = step === 1 ? step : step - 1;

    this.setState({ step: step });
  };

  handleNext = (e) => {
    const { step } = this.state;

    const schema = `schemaStep${step}`;

    this.validateWithSchema(this[schema], (error) => {
      if (!error) {
        if (step === this.state.totalSteps) {
          this.onSubmit(e);
          return;
        }

        this.setState({ step: step + 1 });
      }
    });
  };

  _next = () => {
    const finalAction = this.props.data ? "Applica Modifiche" : "Aggiungi Vino";

    const value =
      this.state.step === this.state.totalSteps ? finalAction : "Avanti";

    return (
      <button
        className="btn btn-primary w-100"
        onClick={this.handleNext}
        disabled={this.state.error}
      >
        {value}
      </button>
    );
  };

  renderInput = (
    name,
    label,
    value,
    placeholder,
    schema = undefined,
    autofocus = false,
    type = "text",
    unit = undefined,
    required = false,
    ricarico = undefined
  ) => {
    return (
      <div className="mt-2">
        <label htmlFor={name}>
          {label}
          {required && <span className="required-form-field">*</span>}
        </label>
        <input
          id={name}
          className="form-control mx-auto"
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={(e) =>
            this.handleChangeWithValidationSchema(schema, e, type, ricarico)
          }
          autoFocus={autofocus}
          value={value || ""}
        />
        {unit && <span className="unit">{unit}</span>}
      </div>
    );
  };

  // Used to take form data from the steps in this state
  handleDataUpdate = (newData) => {
    const { data, step } = this.state;
    const schema = `schemaStep${step}`;

    Object.keys(newData).forEach((key) => {
      data[key] = newData[key];
    });

    this.setState({ data, step: newData.step || step });
    this.validateWithSchema(schema);
  };

  handleWinegrapeChange = (value, index) => {
    const { data } = this.state;

    data.winegrapes[index].winegrape = value.winegrape || value;

    if (value.winegrapeId) {
      data.winegrapes[index].winegrapeId = value.winegrapeId;
    }

    data.wine = -1;

    this.setState({ data });
  };

  handleWinegrapePercentageChange = (e, index) => {
    const { data } = this.state;
    const { value } = e.currentTarget;

    if (!isNaN(value)) {
      data.winegrapes[index].percentage = value <= 100 ? value : 100;
      data.wine = -1;
    }

    this.setState({ data });
  };

  handleAddWinegrape = () => {
    const { data } = this.state;
    let totalPercentage = 0;

    data.winegrapes.forEach((wg) => {
      totalPercentage += Number(wg.percentage);
    });

    data.winegrapes.push({
      winegrape: "",
      percentage: 100 - totalPercentage,
    });

    data.wine = -1;

    this.setState({ data });
  };

  handleWinegrapeDelete = (index) => {
    const { data } = this.state;

    data.winegrapes.splice(index, 1);
    data.wine = -1;

    this.setState({ data });
  };

  handleWineGlass = () => {
    const { data } = this.state;
    data.wineGlass = !data.wineGlass;

    this.setState({ data });
  };

  handleBestBefore = () => {
    const { data } = this.state;
    data.showBestBefore = !data.showBestBefore;

    if (!data.showBestBefore) data.bestBefore = null;

    this.setState({ data });
  };

  handleWineDelete = (wineId) => {
    const alertOptions = {
      title: "Elimina vino",
      message: `Sei sicuro di voler eliminare definitivamente questo vino? (L'azione è irreversibile)`,
      buttons: [
        {
          label: "Sì",
          onClick: async () => {
            await this.props.onWineDelete(wineId);
            this.props.onStopAddingWine();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    };

    confirmAlert(alertOptions);
  };

  render() {
    const {
      step,
      error,
      data,
      suggestions,
      apiCalled,
      editMode,
      ricarico,
      suggestedPrice,
      originalWineId,
    } = this.state;

    if (!apiCalled) return <Loader />;

    return (
      <div className="mt-4 mx-auto overflow-visible">
        <Step1
          step={step}
          data={data}
          suggestions={suggestions}
          schema={this.schemaStep1}
          onChange={this.handleDataUpdate}
          userIsEditingWine={editMode}
          originalWineId={originalWineId}
          onStopAddingWine={this.props.onStopAddingWine}
        />

        <Step2
          step={step}
          data={data}
          onWinegrapePercentageChange={this.handleWinegrapePercentageChange}
          suggestions={suggestions}
          winegrapes={data.winegrapes}
          onWinegrapeChange={this.handleWinegrapeChange}
          onAdd={this.handleAddWinegrape}
          onDataChange={this.handleDataUpdate}
          userIsEditingWine={editMode}
          onWinegrapeDelete={this.handleWinegrapeDelete}
        />

        <Step3
          step={step}
          data={data}
          bottles={suggestions.bottle}
          schema={this.schemaStep3}
          renderInput={this.renderInput}
          onChange={this.handleDataUpdate}
          onWineGlass={this.handleWineGlass}
          onBestBefore={this.handleBestBefore}
          ricarico={ricarico}
          suggestedPrice={suggestedPrice}
        />

        <div className="row mt-4">
          <div className="col">
            {error && Object.keys(error).length > 0 && (
              <div className="alert alert-danger">
                {error.details[0].message}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-4 pb-4">
          <div className="col-12 mb-4">{this._next()}</div>

          {this.props.data && (
            <div className="col-12 mb-3">
              <button
                className="btn btn-dark w-100"
                onClick={() => this.handleWineDelete(originalWineId)}
              >
                Elimina Definitivamente
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AddWine;
