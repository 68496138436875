import React, { Component } from "react";
import authService from "../../services/auth";
import "./css/Profile.css";
import ModifyForm from "./ModifyForm";
import ImageUploader from "./ImageUploader";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../Loader";

class Profile extends Component {
  state = {
    user: {},
    finishedLoading: false,
  };

  componentDidMount() {
    const user = this.props.user;

    if (
      this.props.location &&
      this.props.location.search === "?from=cartaViniPreviewButton" &&
      !this.props.userAlreadyAlerted &&
      user.cartaViniUrl.trim().length === 0
    ) {
      toast("Aggiungi la tua ragione sociale per avere una carta vini", {
        type: "info",
      });
      this.props.onUserAlert();
    }

    this.setState({ user, finishedLoading: true });
  }

  handleUserDestroy = () => {
    const alertOptions = {
      title: "Attenzione",
      message: `L'eliminazione di un utente comporta la perdita permanente di tutti i dati registrati sulla nostra piattaforma,
      comprensivi di vini aggiunti e l'abbonamento attivo verrà definitivamente cancellato. Sei sicuro di voler continuare?`,
      buttons: [
        {
          label: "Sì",
          onClick: async () => {
            const response = await authService.destroyUser();

            if (response.success) window.location.reload();
            else
              toast("Oops! Qualcosa è andato storto! Riprova più tardi!", {
                type: "error",
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    };

    confirmAlert(alertOptions);
  };

  render() {
    const { user, finishedLoading } = this.state;

    if (!finishedLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <div className="container mt-4">
          <div className="row">
            <h1 className="h3 mb-3 font-weight-normal">Immagine profilo</h1>
          </div>
          <ImageUploader currentPic={this.props.currentPic} />
        </div>

        <ModifyForm user={user} onUserEdit={this.props.onUserEdit} />

        <hr />

        <div className="container mt-4">
          <div className="row">
            <button
              onClick={this.handleUserDestroy}
              className="btn btn-danger w-100"
            >
              Elimina Account
            </button>
          </div>
        </div>

        <div className="w-100 mb-4"></div>
      </React.Fragment>
    );
  }
}

export default Profile;
