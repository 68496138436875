import React, { SyntheticEvent } from "react";
import axios, { AxiosRequestConfig } from "axios";
import authService from "../../services/auth";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

export interface WineImageUploaderProps {
  toBottleUrl: string;
  toLabelUrl: string;
  wineId: number;
  show: boolean;
  onClose: (close: boolean) => void;
}

enum FileType {
  Etichetta = 0,
  Bottiglia = 1,
}

export interface WineImageUploaderState {
  file: File | string;
  type: FileType;
}

class WineImageUploader extends React.Component<
  WineImageUploaderProps,
  WineImageUploaderState
> {
  state = {
    file: "",
    type: FileType.Bottiglia,
  };

  onFileChange = (e: any) => {
    this.setState({ file: e.target.files[0] });
  };

  handleUpload = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { type } = this.state;
    const { toBottleUrl, wineId, onClose, toLabelUrl } = this.props;

    const url = type === FileType.Bottiglia ? toBottleUrl : toLabelUrl;

    const wineImage = new FormData();

    const splittedFilename = ((this.state.file as unknown) as File).name.split(
      "."
    );

    wineImage.append(
      "file",
      this.state.file,
      String(wineId) + "." + splittedFilename[splittedFilename.length - 1]
    );

    const options: AxiosRequestConfig = {
      method: "POST",
      headers: { "x-access-token": authService.getToken() },
      data: wineImage,
      url,
    };

    try {
      await axios(options);

      toast("Immagine caricata correttamente!", { type: "success" });

      const updateWineOptions: AxiosRequestConfig = {
        method: "PUT",
        headers: { "x-access-token": authService.getToken() },
        data: { wineId, wine: { waitingForReview: 1 } },
        url: "/api/admin/wine",
      };

      try {
        await axios(updateWineOptions);

        toast("Il vino è ora in attesa di revisione!", { type: "success" });
        onClose(false);
      } catch (error) {
        toast("Oops! Qualcosa è andato storto!", { type: "error" });
        onClose(false);
      }
    } catch (err) {
      toast("Oops! Qualcosa è andato storto!", { type: "error" });
      onClose(false);
    }
  };

  handleSelectChange = (e: SyntheticEvent<HTMLSelectElement>) => {
    this.setState({ type: Number(e.currentTarget.value) });
  };

  render() {
    const { type } = this.state;
    const { show, onClose } = this.props;

    return (
      <React.Fragment>
        <Modal show={show} onHide={() => onClose(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Carica un'immagine</Modal.Title>
          </Modal.Header>
          <form method="post" action="#" id="#" onSubmit={this.handleUpload}>
            <div className="form-group files">
              <input
                id="picFile"
                type="file"
                className="form-control"
                multiple={false}
                onChange={this.onFileChange}
              />
              <label htmlFor="picFile"></label>
            </div>

            <select
              className="form-control w-75 mx-auto mb-4"
              id="sortingType"
              value={type}
              onChange={this.handleSelectChange}
            >
              <option value={FileType.Bottiglia}>Bottiglia</option>
              <option value={FileType.Etichetta}>Etichetta</option>
            </select>

            <Modal.Footer>
              {this.state.file !== "" && (
                <button
                  disabled={!this.state.file}
                  className="btn btn-primary w-100"
                  type="submit"
                >
                  Upload
                </button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default WineImageUploader;
