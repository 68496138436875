import React from "react";

const Select = ({ keys, selectedKey, onChange }) => {
  return (
    <select
      value={selectedKey}
      onChange={onChange}
      className="col custom-select my-2"
    >
      {keys.map((key) => {
        return (
          <option key={key.id} value={key.id}>
            {key.name}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
