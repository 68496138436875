import styled from "styled-components";

export const StyledMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  background: url(${require("../../../img/Pattern_Sidebar.jpg")});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-align: left;
  padding: 1.2rem;
  flex-wrap: none;
  flex: 1 1 auto;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  box-shadow: ${({ open }) =>
    open ? "1px 1px 15px 1px rgba(126, 0, 61, 1);" : "none"};
  width: 62%;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  z-index: 10 !important;

  .nav-link {
    color: #fff !important;
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 0;
    text-decoration: none;
    transition: color 0.3s linear;
  }
`;
