import React from "react";
import WineSorting from "./WineSorting";
import Settings from "./Settings";

const SettingsComponent = ({
  userSettings,
  sortingTypes,
  toggleSetting,
  onSettingChange,
}) => {
  return (
    <div className="col-md-12 col-lg">
      <Settings toggleSetting={toggleSetting} userSettings={userSettings} />

      <WineSorting
        sortingTypes={sortingTypes}
        userSettings={userSettings}
        onSettingChange={onSettingChange}
      />
    </div>
  );
};

export default SettingsComponent;
