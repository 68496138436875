import React, { ReactElement, SyntheticEvent } from "react";
import {
  Redirect,
  Route,
  NavLink,
  Link,
  RouteComponentProps,
} from "react-router-dom";
import PWAPrompt from "react-ios-pwa-prompt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faScroll,
  faChartLine,
  faWineBottle,
  faBox,
  faRibbon,
  faTools,
  faFileSignature,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Dashboard from "./Dashboard/Dashboard";
import Magazzino from "./Magazzino/Magazzino";
import CartaVini from "./CartaVini/CartaVini";
import Subscribe from "./Subscribe/Subscribe";
import Profile from "./Profile/Profile";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Burger from "./Burger/Burger";
import Menu from "./Burger/Menu/Menu";
import Settings from "./Settings/Settings";
import helpers, { SidebarLink } from "../helpers";
import authService from "../services/auth";
import subscriptionsService, { Roles } from "../services/subscriptions";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import Loader from "./Loader";

import { UserSettings } from "interfaces/wine_interfaces";

import "./Landing/css/style.css";
import AdminIndex from "./Admin";
import { User } from "interfaces/user_interfaces";
import Success from "./Subscribe/Success";

export interface MainProps {}

export interface MainState {
  sidebarLinks: Array<SidebarLink>;
  roles: Roles;
  footer: string;
  token?: string | null;
  hasSubscription?: boolean;
  menuOpen: boolean;
  propic?: string;
  isSearching: boolean;
  wineToLookFor: string;
  userSettings: UserSettings;
  isAddingWine: boolean;
  apiCalling: boolean;
  user: User;
  fetchingUser: boolean;
  alertedUserInProfile: boolean;
}

class Main extends React.Component<RouteComponentProps, MainState> {
  burgerMenuRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.burgerMenuRef = React.createRef();
  }

  state = {
    sidebarLinks: [
      {
        name: "Dashboard",
        path: "/app/dashboard",
        component: Dashboard,
        icon: <FontAwesomeIcon icon={faChartLine} />,
      },
      {
        name: "Carta Vini",
        path: "/app/carta-vini",
        component: CartaVini,
        icon: <FontAwesomeIcon icon={faWineBottle} />,
      },
      {
        name: "Magazzino",
        path: "/app/magazzino",
        component: Magazzino,
        icon: <FontAwesomeIcon icon={faBox} />,
      },
      {
        name: "Il tuo Piano",
        path: "/app/subscribe",
        component: Subscribe,
        icon: <FontAwesomeIcon icon={faRibbon} />,
      },
      {
        name: "Impostazioni",
        path: "/app/settings",
        component: Settings,
        icon: <FontAwesomeIcon icon={faTools} />,
      },
      {
        name: "Contattaci",
        path: "mailto:info@wineboard.io?subject=Contatto",
        component: undefined,
        icon: <FontAwesomeIcon icon={faFileSignature} />,
      },
    ],
    roles: {
      maxQuery: 0,
      hasDashboard: 1,
      canRemoveWatermark: 1,
    },
    footer: "Wineboard © 2020",
    token: authService.getToken(),
    hasSubscription: undefined,
    menuOpen: false,
    propic: undefined,
    isSearching: false,
    wineToLookFor: "",
    userSettings: {} as UserSettings,
    isAddingWine: false,
    apiCalling: true,
    user: {} as User,
    fetchingUser: true,
    alertedUserInProfile: false,
  };

  async getPropic(): Promise<string> {
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: { "x-access-token": this.state.token },
      url: "/api/image/propic",
    };

    const response = await axios(options);

    if (response.data.error) return "propic.error";

    return response.data;
  }

  getData(): void {
    if (this.state.token) {
      Promise.all([
        subscriptionsService.hasSubscription(),
        subscriptionsService.getMerchantRoles(),
        authService.getUserSettings(),
        authService.getLoggedUser(),
        this.getPropic(),
      ])
        .then((value) => {
          this.setState({
            hasSubscription: value[0],
            roles: value[1],
            userSettings: value[2],
            user: value[3],
            propic: "data:image/png;base64," + value[4],
            apiCalling: false,
            fetchingUser: false,
          });
        })
        .catch(() => {});
    } else {
      this.setState({ apiCalling: false });
    }
  }

  componentDidMount() {
    this.getData();
  }

  async componentDidUpdate() {
    //If there's no propic, it means that the user is coming from the login page
    // so we now get its data
    if (!this.state.propic) {
      Promise.all([
        this.getPropic(),
        authService.getUserSettings(),
        subscriptionsService.hasSubscription(),
        subscriptionsService.getMerchantRoles(),
        authService.getLoggedUser(),
      ])
        .then(async (value) => {
          this.setState({ apiCalling: true });
          if (value[0] !== "propic.error")
            this.setState({
              propic: "data:image/png;base64," + value[0],
              userSettings: value[1],
              hasSubscription: value[2],
              roles: value[3],
              user: value[4],
              apiCalling: false,
              fetchingUser: false,
            });
          else if (this.state.token) await authService.logout();
        })
        .catch(() => {});
    }
  }

  burgerMenuListener = (e: MouseEvent | TouchEvent) => {
    if (
      !this.burgerMenuRef.current ||
      this.burgerMenuRef.current.contains(e.target as Node)
    ) {
      return;
    }

    this.setState({ menuOpen: !this.state.menuOpen });

    document.removeEventListener("mousedown", this.burgerMenuListener);
    document.removeEventListener("touchstart", this.burgerMenuListener);
  };

  toggleMenu = () => {
    const menuOpen = !this.state.menuOpen;

    if (menuOpen) {
      document.addEventListener("mousedown", this.burgerMenuListener);
      document.addEventListener("touchstart", this.burgerMenuListener);
    } else {
      document.removeEventListener("mousedown", this.burgerMenuListener);
      document.removeEventListener("touchstart", this.burgerMenuListener);
    }

    this.setState({ menuOpen });
  };

  handleAuthentication = (token: string) => {
    this.setState({ token });
  };

  handleLogout = async () => {
    await authService.logout();
  };

  toggleSearch = () => {
    this.setState({ isSearching: !this.state.isSearching, wineToLookFor: "" });
  };

  handleWineSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ wineToLookFor: value });
  };

  handleSubRefresh = async () => {
    toast(
      "Il tuo nuovo abbonamento è attivo! Per ragioni di sicurezza ora effettua di nuovo l'accesso!",
      { type: "success" }
    );

    setTimeout(() => authService.logout(), 5000);
  };

  renderFooter = (customClasses: string): ReactElement => {
    const { footer } = this.state;

    return (
      <div className={`sidebar-footer ${customClasses}`}>
        <footer className="text-center">
          {footer} •{" "}
          <a
            className="footer-link"
            href="https://www.iubenda.com/privacy-policy/67730356/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>{" "}
          •{" "}
          <a
            className="footer-link"
            href="https://www.iubenda.com/privacy-policy/67730356"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </footer>
      </div>
    );
  };

  renderPWAPrompt = (): ReactElement => {
    return (
      <PWAPrompt
        copyTitle="Aggiungimi alla Home"
        copyBody="Questo sito ha anche funzionalità da app. Aggiungilo alla schermata home per usarlo in fullscreen e offline."
        copyShareButtonLabel="1) Premi il pulsante condividi"
        copyAddHomeButtonLabel="2) Clicca su Aggiungi alla Schermata Home"
        timesToShow={2}
      />
    );
  };

  toggleSetting = async (e: SyntheticEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;

    const { userSettings } = this.state;
    userSettings[name] = !userSettings[name];

    this.setState({ userSettings });

    const res = await authService.updateUserSettings(userSettings);

    if (res.success)
      toast("Impostazioni aggiornate con successo!", { type: "success" });
    else
      toast("Oops! Qualcosa è andato storto! Riprova tra un po'", {
        type: "error",
      });
  };

  handleSettingChange = async (name: string, value: string) => {
    const userSettings: UserSettings = this.state.userSettings;

    userSettings[name] = value;

    this.setState({ userSettings });

    const res = await authService.updateUserSettings(userSettings);

    if (res.success)
      toast("Impostazioni aggiornate con successo!", { type: "success" });
    else
      toast("Oops! Qualcosa è andato storto! Riprova tra un po'", {
        type: "error",
      });
  };

  handlePreview = () => {
    if (
      this.state.user.cartaViniUrl &&
      this.state.user.cartaViniUrl.trim().length > 0
    )
      window.open("/board/" + this.state.user.cartaViniUrl);
    else {
      window.location.href =
        window.location.href.split("/")[0] +
        "/app/profile?from=cartaViniPreviewButton";
    }
  };

  handleWantsToAddWine = () => {
    this.setState({ isAddingWine: true });
    this.props.history.push("/app/magazzino");
  };

  handleFinishAddingWine = () => {
    this.setState({ isAddingWine: false });
  };

  handleUserEdit = (userUpdate: User) => {
    const { user } = this.state;

    Object.keys(userUpdate).forEach((key) => {
      user[key] = userUpdate[key];
    });

    this.setState({ user });
  };

  handleUserAlertProfilePage = () => {
    this.setState({ alertedUserInProfile: true });
  };

  render() {
    const {
      menuOpen,
      propic,
      footer,
      wineToLookFor,
      userSettings,
      isAddingWine,
    } = this.state;

    if (this.state.apiCalling)
      return (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      );

    if (!this.state.token || this.state.fetchingUser) {
      return (
        <React.Fragment>
          <Route
            path="/app/login"
            component={() => (
              <Login
                onAuthentication={this.handleAuthentication}
                footer={footer}
              />
            )}
          />
          <Route
            path="/app/register"
            component={() => (
              <Register
                onAuthentication={this.handleAuthentication}
                footer={this.state.footer}
              />
            )}
          />
          <Redirect from="/app" to="/app/login" />
        </React.Fragment>
      );
    }

    if (
      !this.state.apiCalling &&
      !this.state.fetchingUser &&
      !this.state.user.active &&
      this.state.token
    )
      return (
        <div
          style={{ height: "70vh" }}
          className="container d-flex align-items-center"
        >
          <div className="row">
            <div className="col text-center">
              <h1 className="h1 text-center">Tutto pronto!</h1>
              <p className="lead mb-4">
                Tutto pronto, ma prima di eseguire qualsiasi operazione, devi
                prima <b>confermare</b> il tuo account, controlla la tua casella
                di posta e completa il processo di registrazione
              </p>

              <a className="anchor-link my-4" href="/">
                <img src="/logo-header-black.png" height="24" alt="" />
              </a>
            </div>
          </div>
        </div>
      );

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <nav className="col-md-2 d-none d-md-block bg-dark sidebar">
              <div className="sidebar-sticky pt-4">
                <ul className="nav flex-column mb-4">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link profile-picture-sidebar"
                      to="/app/profile"
                    >
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col text-center">
                            {this.state.propic && (
                              <img
                                className="my-auto"
                                style={{
                                  borderRadius: "100%",
                                }}
                                height="128"
                                width="128"
                                src={this.state.propic}
                                alt="Immagine Profilo (Logo Aziendale)"
                              />
                            )}

                            <p className="py-4 user-name">
                              {this.state.user.name || ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  {this.state.sidebarLinks.map((link) =>
                    helpers.renderLink(link)
                  )}

                  <li className="nav-item w-100">
                    <div className="justify-content-center text-left">
                      <Link
                        onClick={this.handleLogout}
                        className="nav-link"
                        to="/logout"
                      >
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faSignOutAlt} />
                        </span>
                        Sign out
                      </Link>
                    </div>
                  </li>
                </ul>
                {this.renderFooter("w-75 mt-4")}
              </div>
            </nav>

            <main role="main" className="col-md-9 ml-lg-auto col-lg-10 px-4">
              <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow align-middle">
                <div
                  className="d-flex mobile-only mr-auto"
                  ref={this.burgerMenuRef}
                >
                  <Burger open={menuOpen} toggleMenu={this.toggleMenu} />

                  <Menu
                    open={menuOpen}
                    links={this.state.sidebarLinks}
                    propic={this.state.propic}
                    toggleMenu={this.toggleMenu}
                    handleLogout={this.handleLogout}
                    renderFooter={this.renderFooter}
                    user={this.state.user}
                  />
                </div>

                <div className="mx-auto my-auto centered-logo text-center d-flex align-items-center">
                  <div className="my-auto w-100 text-center">
                    <a className="anchor-link my-auto" href="/">
                      <img src="/logo-header-black.png" height="18" alt="" />
                    </a>
                  </div>
                </div>

                <div className="trailing-buttons">
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faPlus}
                    className="mr-3"
                    onClick={this.handleWantsToAddWine}
                  />

                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faScroll}
                    className="ml-3"
                    onClick={this.handlePreview}
                  />
                </div>
              </nav>
              <Route
                path="/app/dashboard"
                component={() => (
                  <Dashboard
                    hasDashboard={this.state.roles.hasDashboard}
                    user={this.state.user}
                  />
                )}
              />
              <Route
                path="/app/magazzino"
                component={() => (
                  <Magazzino
                    wineToLookFor={wineToLookFor}
                    userSettings={userSettings}
                    isAddingWine={isAddingWine}
                    onStopAddingWine={this.handleFinishAddingWine}
                    user={this.state.user}
                  />
                )}
              />
              <Route
                path="/app/carta-vini"
                component={() => (
                  <CartaVini
                    userSettings={userSettings}
                    toggleSetting={this.toggleSetting}
                    handleSettingChange={this.handleSettingChange}
                    user={this.state.user}
                  />
                )}
              />
              <Route
                path="/app/subscribe/success"
                component={(props) => (
                  <Success merchantId={this.state.user.id} {...props} />
                )}
              />
              <Route
                exact
                path="/app/subscribe"
                component={(props) => (
                  <Subscribe
                    hasSubscription={this.state.hasSubscription}
                    onSubRefresh={this.handleSubRefresh}
                    user={this.state.user}
                    {...props}
                  />
                )}
              />
              <Route
                path="/app/profile"
                component={(props) => (
                  <Profile
                    currentPic={propic}
                    onUserEdit={this.handleUserEdit}
                    user={this.state.user}
                    userAlreadyAlerted={this.state.alertedUserInProfile}
                    onUserAlert={this.handleUserAlertProfilePage}
                    {...props}
                  />
                )}
              />
              <Route path="/app/settings" component={Settings} />
              <Route
                path="/app/admin"
                component={() => <AdminIndex merchantId={this.state.user.id} />}
              />

              <Route
                exact
                path="/app"
                component={() => <Redirect to="/app/magazzino" />}
              />
              <Route
                exact
                path="/app/login"
                component={() => <Redirect to="/app/magazzino" />}
              />
              <Route
                exact
                path="/app/register"
                component={() => <Redirect to="/app/magazzino" />}
              />
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
