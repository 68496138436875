import React, { Component } from "react";
import {
  LineChart,
  Line,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
  Legend,
} from "recharts";
import { SegmentedControl } from "segmented-control";
import WinesChart from "./WinesChart";
import Loader from "../Loader";
import { Switch, Route, Link } from "react-router-dom";

import wineService from "../../services/wine";
import statsService from "../../services/stats";
import helpers from "../../helpers";

import "./Dashboard.css";
import LatestSales from "./SalesPage/LatestSales";
import LatestShopping from "./ShopsPage/LatestShopping";
import SalesPage from "./SalesPage/SalesPage";
import ShopsPage from "./ShopsPage/ShopsPage";

class DashboardPage extends Component {
  state = {
    winesWithoutPurchasePrice: [],
    soldWines: [],
    sortedSoldWines: [],
    groupedByDaySoldWines: {},
    groupedByDayBoughtWines: {},
    groupedByDayVisitors: {},
    groupedWineClicks: {},
    latestSales: [],
    latestShopping: [],
    visitorsFilter: "inside",
    wineFilter: "sold",
    apiCalling: true,
  };

  async componentDidMount() {
    const winesWithoutPurchasePrice = await wineService.getWinesWithoutPurchasePrice();

    const soldWines = await wineService.getSoldWines();
    const boughtWines = await wineService.getBoughtWines();
    const wineClicks = await statsService.getWineClicks(this.props.user.id);

    const visitors = await statsService.getVisitors(this.props.user.id);

    if (
      !soldWines.error &&
      !boughtWines.error &&
      !visitors.error &&
      !wineClicks.error
    ) {
      const groupedSoldWines = helpers.groupBy(soldWines, "merchantWineId");
      const groupedWineClicks = helpers.groupBy(wineClicks, "merchantWineId");

      let sortedSoldWines = [];
      for (let wine in groupedSoldWines) {
        sortedSoldWines.push([wine, groupedSoldWines[wine]]);
      }

      const groupedByDayVisitors = {};

      visitors.forEach((visitor) => {
        const date = new Date(visitor.createdAt);
        const day = date.getDate();
        const month = date.getMonth();

        const key = String(day) + "/" + String(month + 1);

        if (groupedByDayVisitors[key]) groupedByDayVisitors[key].push(visitor);
        else groupedByDayVisitors[key] = [visitor];
      });

      const groupedByDaySoldWines = {};

      soldWines.forEach((wine) => {
        const date = new Date(wine.timestamp);
        const day = date.getDate();
        const month = date.getMonth();

        const key = String(day) + "/" + String(month + 1);

        if (groupedByDaySoldWines[key]) groupedByDaySoldWines[key].push(wine);
        else groupedByDaySoldWines[key] = [wine];
      });

      const groupedByDayBoughtWines = {};

      boughtWines.forEach((wine) => {
        const date = new Date(wine.timestamp);
        const day = date.getDate();
        const month = date.getMonth();

        const key = String(day) + "/" + String(month + 1);

        if (groupedByDayBoughtWines[key])
          groupedByDayBoughtWines[key].push(wine);
        else groupedByDayBoughtWines[key] = [wine];
      });

      const latestSales = soldWines.slice(0, 3);
      const latestShopping = boughtWines.slice(0, 3);

      this.setState({
        winesWithoutPurchasePrice,
        soldWines,
        sortedSoldWines,
        groupedByDaySoldWines,
        groupedByDayBoughtWines,
        groupedByDayVisitors,
        groupedWineClicks,
        latestSales,
        latestShopping,
        apiCalling: false,
      });
    }
  }

  handleVisitorFilter = (newValue) => {
    this.setState({ visitorsFilter: newValue });
  };

  handleWineFilter = (newValue) => {
    this.setState({ wineFilter: newValue });
  };

  render() {
    const {
      sortedSoldWines,
      apiCalling,
      groupedByDaySoldWines,
      groupedByDayBoughtWines,
      groupedByDayVisitors,
      groupedWineClicks,
      wineFilter,
      winesWithoutPurchasePrice,
      latestSales,
      latestShopping,
    } = this.state;

    let expense = 0;
    let priceReturn = 0;
    let profit = 0;

    const soldStuff = Object.keys(groupedByDaySoldWines).map((day) => {
      groupedByDaySoldWines[day].forEach((wine) => {
        priceReturn += wine.priceReturn * wine.numberOfBottles;
        profit += wine.profit * wine.numberOfBottles;
      });

      return {
        name: day,
        priceReturn,
        profit,
      };
    });

    const boughtStuff = Object.keys(groupedByDayBoughtWines).map((day) => {
      groupedByDayBoughtWines[day].forEach((wine) => {
        expense += wine.price * wine.numberOfBottles;
      });

      return {
        name: day,
        expense,
      };
    });

    const dataLine = [];

    const cursor = new Date();
    cursor.setTime(cursor.getTime() - 1000 * 60 * 60 * 24 * 15);

    for (let i = 0; i <= 15; i++) {
      const entry = {};

      const name =
        String(cursor.getDate()) + "/" + String(cursor.getMonth() + 1);
      entry.name = String(cursor.getDate());

      const expense = boughtStuff.filter((e) => e.name === name);

      const profit = soldStuff.filter((e) => e.name === name);

      if (expense[0]) entry["Spese €"] = expense[0].expense;

      if (profit[0]) {
        entry["Profitti €"] = profit[0].profit;
        entry["Ricavi €"] = profit[0].priceReturn;
      }

      if (!entry["Spese €"]) {
        if (dataLine[i - 1]) entry["Spese €"] = dataLine[i - 1]["Spese €"];
        else entry["Spese €"] = 0;
      }

      if (!entry["Profitti €"]) {
        if (dataLine[i - 1])
          entry["Profitti €"] = dataLine[i - 1]["Profitti €"];
        else entry["Profitti €"] = 0;
      }

      if (!entry["Ricavi €"]) {
        if (dataLine[i - 1]) entry["Ricavi €"] = dataLine[i - 1]["Ricavi €"];
        else entry["Ricavi €"] = 0;
      }

      dataLine[i] = entry;
      cursor.setTime(cursor.getTime() + 1000 * 60 * 60 * 24);
    }

    const dataBar = sortedSoldWines.map((wine) => {
      let numberOfBottles = 0;

      wine[1].forEach((w) => {
        numberOfBottles += w.numberOfBottles;
      });

      return {
        name: wine[1][0].wine,
        "Bottiglie Vendute": numberOfBottles,
      };
    });

    if (dataBar.length === 0) dataBar.push({ name: "Nessun dato da mostrare" });

    dataBar.sort((a, b) => {
      return b["Bottiglie Vendute"] - a["Bottiglie Vendute"];
    });

    const dataLineVisitors = Object.keys(groupedByDayVisitors).map((day) => {
      return {
        name: day,
        Utenti: groupedByDayVisitors[day].filter(
          (v) => v.visitedFrom === this.state.visitorsFilter
        ).length,
      };
    });

    if (dataLineVisitors.length === 0)
      dataLineVisitors.push({ name: "Nessun dato da mostrare" });

    const dataWineClicks = Object.keys(groupedWineClicks).map((w) => {
      return {
        name: groupedWineClicks[w][0].wine,
        Clicks: groupedWineClicks[w].length,
      };
    });

    dataWineClicks.sort((a, b) => {
      return b.Clicks - a.Clicks;
    });

    if (apiCalling)
      return (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      );

    if (this.props.hasDashboard) {
      return (
        <React.Fragment>
          <div style={{ overflowX: "none" }} className="container mt-4">
            <div className="row mb-2">
              <div className="col">
                <h1 className="h1">Dashboard</h1>
              </div>
            </div>

            {winesWithoutPurchasePrice.length > 0 && (
              <div className="row my-2">
                <div className="alert alert-danger mx-auto">
                  Ci sono <b>{winesWithoutPurchasePrice.length}</b> vini senza
                  prezzo d'acquisto, aggiungilo per ottenere il meglio dal
                  supporto alla vendita!
                </div>
              </div>
            )}

            <div className="row mt-2">
              <LatestSales
                className="col-md-12 col-lg-6 my-auto p-1"
                latestSales={latestSales}
              />
              <LatestShopping
                className="col-md-12 col-lg-6 my-auto p-1"
                latestShopping={latestShopping}
              />
            </div>

            <div className="row mt-2">
              <div className="col-12 p-1">
                <div className="chart-card">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title ml-4 h5">
                        Vini più{" "}
                        {wineFilter === "sold" ? "Venduti" : "Cliccati"}
                      </h5>
                      <h6 className="card-title pb-2 ml-4 h6">
                        Ultimi 30 giorni
                      </h6>
                    </div>
                    <div className="col">
                      <SegmentedControl
                        name="wineFilter"
                        className="segmented-control"
                        options={[
                          { label: "Venduti", value: "sold", default: true },
                          { label: "Cliccati", value: "clicked" },
                        ]}
                        setValue={(newValue) => this.handleWineFilter(newValue)}
                        style={{
                          color: "#93024e",
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    {wineFilter === "sold" && (
                      <WinesChart
                        data={dataBar || [{}]}
                        max={10}
                        dataKey={"Bottiglie Vendute"}
                      />
                    )}

                    {wineFilter === "clicked" && (
                      <WinesChart
                        data={dataWineClicks || [{}]}
                        max={10}
                        dataKey={"Clicks"}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 p-1 mt-2">
                <div className="chart-card">
                  <h5 className="card-title ml-4 h5">Rendimenti</h5>
                  <h6 className="card-title pb-2 ml-4 h6">Ultimi 15 giorni</h6>
                  <div className="mt-2">
                    <ResponsiveContainer width="100%" height={200}>
                      <LineChart data={dataLine}>
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line
                          type="monotone"
                          dataKey="Ricavi €"
                          stroke="#7A3257"
                          yAxisId={0}
                        />
                        <Line stroke="#07A654" dataKey="Profitti €" />
                        <Line stroke="#D93489" dataKey="Spese €" />
                        <Tooltip />
                        <XAxis dataKey="name">
                          <Label
                            value="Giorno"
                            offset={0}
                            position="insideBottomRight"
                          />
                        </XAxis>
                        <YAxis />
                        <Legend />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2 p-1">
                <div className="chart-card">
                  <div className="row">
                    <div className="col-md-12 col-lg">
                      <h5 className="card-title pb-2 ml-4">
                        Visitatori Carta Vini
                      </h5>
                      <h6 className="card-title pb-2 ml-4 h6">
                        Ultimi 7 giorni
                      </h6>
                    </div>
                    <div className="col-md-12 col-lg">
                      <SegmentedControl
                        name="visitorsFilter"
                        className="segmented-control"
                        options={[
                          { label: "Web", value: "web", default: true },
                          { label: "Esterno", value: "outside" },
                          { label: "Interno", value: "inside" },
                        ]}
                        setValue={(newValue) =>
                          this.handleVisitorFilter(newValue)
                        }
                        style={{
                          color: "#93024e",
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={
                          dataLineVisitors.slice(
                            dataLineVisitors.length - 7,
                            dataLineVisitors.length
                          ) || []
                        }
                      >
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line
                          type="monotone"
                          dataKey="Utenti"
                          stroke="#A26EAB"
                          yAxisId={0}
                        />
                        <Tooltip />
                        <XAxis dataKey="name">
                          <Label
                            value="Data"
                            offset={0}
                            position="insideBottomRight"
                          />
                        </XAxis>
                        <YAxis />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="container">
        <div className="row mt-4 p-4">
          <p className="lead text-center mt-4">
            Per poter accedere a queste funzionalità, hai bisogno di
            sottoscrivere un abbonamento. <br />
            <Link className="btn btn-primary mx-auto mt-4" to="/app/subscribe">
              Attivalo subito
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

class Dashboard extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/app/dashboard"
          component={(props) => <DashboardPage {...props} {...this.props} />}
        />
        <Route path="/app/dashboard/ultime-vendite" component={SalesPage} />
        <Route path="/app/dashboard/ultimi-acquisti" component={ShopsPage} />
      </Switch>
    );
  }
}

export default Dashboard;
