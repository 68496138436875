import React, { Component } from "react";
import { toast } from "react-toastify";
import Board from "../Board/Board";
import Header from "./Header";
import TypographyComponent from "./TypographyComponent";
import SettingsComponent from "./SettingsComponent";
import QRs from "./QRs";
import wineService from "../../services/wine";
import helpers from "../../helpers";
import "./cartaVini.css";

class CartaVini extends Component {
  state = {
    fonts: [
      [
        {
          fontFamily: "Futura,Trebuchet MS,Arial,sans-serif",
          img: "classy.png",
        },
        {
          fontFamily:
            "Bodoni MT,Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif",
        },
      ],
      [
        {
          fontFamily: "Gill Sans,Gill Sans MT,Calibri,sans-serif",
          img: "retro.png",
        },
        {
          fontFamily:
            "Garamond,Baskerville,Baskerville Old Face,Hoefler Text,Times New Roman,serif",
        },
      ],
      [
        {
          fontFamily: "Audrey",
          img: "modern.png",
        },
        {
          fontFamily: "Muli",
        },
      ],
    ],
    layouts: [
      { id: 1, name: "Classic" },
      { id: 2, name: "Modern" },
      { id: 3, name: "Vintage" },
      { id: 4, name: "Fuzzy" },
    ],
    colors: [
      { id: 1, name: "Wineboard" },
      { id: 2, name: "Classic" },
      { id: 3, name: "Mood" },
    ],
    sortingTypes: helpers.sortingTypes,
    qrs: [
      { id: 1, title: "Interno", from: "inside" },
      { id: 2, title: "Esterno", from: "outside" },
      { id: 3, title: "Web", from: "web" },
    ],
    cartaViniUrl: "",
    notSortedWines: [],
    inputValueOnFocus: "",
    downloadingPdf: false,
  };

  async componentDidMount() {
    const notSortedWines = await wineService.getWinesForMerchant(
      this.props.user.id
    );

    this.setState({
      cartaViniUrl: `https://wineboard.io/board/${this.props.user.cartaViniUrl}`,
      notSortedWines,
    });
  }

  downloadQR = (e, id) => {
    e.preventDefault();

    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "carta-vini-" + id + ".png";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  handlePreview = (e) => {
    e.preventDefault();

    window.open(this.state.cartaViniUrl);
  };

  handleCopyToClipboard = (e, url) => {
    e.preventDefault();
    /* Get the text field */
    var copyText = document.getElementById("link-to-copy");

    /* Select the text field */
    copyText.value = url;
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    toast("Testo copiato negli appunti", { type: "success" });
  };

  render() {
    const { fonts, sortingTypes, downloadingPdf } = this.state;
    const { userSettings } = this.props;

    return (
      <div className="container my-4">
        <input
          id="link-to-copy"
          style={{
            position: "absolute",
            bottom: "1000%",
          }}
          value={this.state.cartaViniUrl}
          readOnly={true}
        />

        <div
          style={{
            position: "absolute",
            bottom: "1000%",
            right: "1000%",
            display: "block",
            overflowY: "hidden",
            size: "7in 9.25in",
            margin: "27mm 16mm 27mm 16mm",
          }}
        >
          <div className="container" id="carta-vini">
            <Board ref={(el) => (this.componentToPrintRef = el)} pdf={true} />
          </div>
        </div>

        <Header
          downloadingPdf={downloadingPdf}
          componentToPrintRef={this.componentToPrintRef}
        />

        <TypographyComponent
          fonts={fonts}
          userSettings={userSettings}
          onSettingChange={this.props.handleSettingChange}
        />

        <div className="row">
          <SettingsComponent
            userSettings={userSettings}
            sortingTypes={sortingTypes}
            toggleSetting={this.props.toggleSetting}
            onSettingChange={this.props.onSettingChange}
          />

          <QRs
            qrs={this.state.qrs}
            cartaViniUrl={this.state.cartaViniUrl}
            downloadQR={this.downloadQR}
          />
        </div>

        <div className="row">
          <button
            className="mt-4 btn btn-primary w-75 mx-auto"
            onClick={(e) =>
              this.handleCopyToClipboard(e, this.state.cartaViniUrl)
            }
          >
            Copia Link
          </button>
        </div>

        <div className="row">
          <button
            className="mt-4 btn btn-primary w-75 mx-auto"
            onClick={() => window.open(this.state.cartaViniUrl)}
          >
            Anteprima Carta Vini
          </button>
        </div>
      </div>
    );
  }
}

export default CartaVini;
