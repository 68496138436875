import React, { Component } from "react";
import Loader from "../Loader";
import authService from "../../services/auth";

class AccountActivation extends Component {
  state = {
    success: false,
    error: false,
    message: "",
    apiCalling: true,
  };

  async componentDidMount() {
    const activationUrl = this.props.location.search.split("=")[1];

    const response = await authService.confirmRegistration(activationUrl);

    this.setState({
      success: response.success,
      error: response.error,
      message: response.message,
      apiCalling: false,
    });
  }

  componentDidUpdate() {
    if (this.state.success) window.location.href = "/app";
  }

  render() {
    const { apiCalling, success, error, message } = this.state;

    if (apiCalling) return <Loader />;

    return (
      <div className="container mt-4">
        <div className="row mt-4">
          <div className="col mt-4">
            <p>{success && message}</p>
            <p>{error && message}</p>
            {success && (
              <p>
                Se non vieni reindirizzato automaticamente,{" "}
                <a className="anchor-link" href="/app">
                  clicca qui per accedere a Wineboard
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AccountActivation;
