import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import {
  MerchantWine,
  MerchantWinesResponse,
  Wine,
  Winefamily,
  Winegrape,
  Winery,
} from "interfaces/wine_interfaces";
import authService from "./auth";

export const getFormData = async () => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: "/api/wine/winedata",
    headers: { "x-access-token": authService.getToken() },
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return;
  }
};

export const addWine = async (data: any) => {
  const options: AxiosRequestConfig = {
    method: "POST",
    url: "/api/wine/addWine",
    headers: { "x-access-token": authService.getToken() },
    data,
  };

  try {
    const response = await axios(options);
    toast("Vino aggiunto correttamente!", { type: "success" });
    return response.data;
  } catch (error) {
    toast("Oops! Qualcosa è andato storto!", { type: "error" });
    return error.response.data;
  }
};

export const getWineries = async (inputValue: string): Promise<Winery[]> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: `/api/wine/wineries?winery=${inputValue}`,
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return [];
  }
};

export const getWinefamilies = async (
  inputValue: string
): Promise<Winefamily[]> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: `/api/wine/winefamilies?winefamily=${inputValue}`,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getWinegrapes = async (
  inputValue: string
): Promise<Winegrape[]> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: `/api/wine/winegrapes?winegrape=${inputValue}`,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getWines = async (inputValue: string): Promise<MerchantWine[]> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: `/api/wine/wines?wine=${inputValue}`,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getWinesForMerchant = async (
  merchantId: number
): Promise<MerchantWinesResponse> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: `/api/wine/getWinesForMerchant?m=${merchantId}`,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return {} as MerchantWinesResponse;
  }
};

export const getWinesForMerchantPublic = async (
  cartaViniUrl: string
): Promise<Wine[]> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: `/api/wine/getWinesForMerchantPublic?m=${cartaViniUrl}`,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const updateWineQuickAction = async (
  id: number,
  price: number,
  availability: number,
  wantsToUpdateGlass: boolean,
  purchasePrice?: number,
  wineName?: string,
  soldGlasses?: number
) => {
  const options: AxiosRequestConfig = {
    method: "PUT",
    headers: { "x-access-token": authService.getToken() },
    url: "/api/wine/updateWineQuickAction",
    data: {
      id,
      price,
      availability,
      wantsToUpdateGlass,
      purchasePrice,
      wineName,
      soldGlasses,
    },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateWine = async (data: any) => {
  const options: AxiosRequestConfig = {
    method: "PUT",
    headers: { "x-access-token": authService.getToken() },
    url: "/api/wine/updateWine",
    data,
  };

  try {
    const response = await axios(options);
    toast("Vino modificato correttamente!", { type: "success" });
    return response.data;
  } catch (error) {
    toast("Oops! Qualcosa è andato storto!", { type: "error" });
    return error.response.data;
  }
};

interface QueryOptions {
  skip: number;
  limit: number;
}

export const getSoldWines = async (
  queryOptions: QueryOptions = { skip: 0, limit: 100 }
) => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url:
      "/api/stats/get-most-sold-wines?skip=" +
      queryOptions.skip +
      "&limit=" +
      queryOptions.limit,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getBoughtWines = async (
  queryOptions: QueryOptions = { skip: 0, limit: 100 }
) => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url:
      "/api/stats/get-bought-wines?skip=" +
      queryOptions.skip +
      "&limit=" +
      queryOptions.limit,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateRicarico = async (ricarico: string, value: number) => {
  const options: AxiosRequestConfig = {
    method: "PUT",
    headers: { "x-access-token": authService.getToken() },
    url: "/api/auth/update-ricarico",
    data: { ricarico, value: Number(value) },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getWineryImage = async (
  wineryId: number
): Promise<string | undefined> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: `/api/wine/getWineryImage?wineryId=${wineryId}`,
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const getWineImage = async (
  wineId: number
): Promise<string | undefined> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: `/api/wine/getWineImage?wineId=${wineId}`,
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const getWinesWithoutPurchasePrice = async () => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url:
      "/api/wine/getWinesWithoutPurchasePrice?m=" +
      authService.getLoggedUserFromToken().id,
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const getWineById = async (wineId: number): Promise<MerchantWine> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: "/api/wine/" + String(wineId),
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteWine = async (wineId: number): Promise<{ success: boolean }> => {
  const options: AxiosRequestConfig = {
    method: "DELETE",
    headers: { "x-access-token": authService.getToken() },
    url: "/api/wine/merchant-wine/" + String(wineId),
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export default {
  getFormData,
  addWine,
  getWineries,
  getWinefamilies,
  getWinegrapes,
  getWines,
  getWinesForMerchant,
  getWinesForMerchantPublic,
  updateWineQuickAction,
  updateWine,
  getSoldWines,
  getBoughtWines,
  updateRicarico,
  getWineryImage,
  getWineImage,
  getWinesWithoutPurchasePrice,
  deleteWine,
};
