import * as React from "react";
import { MerchantWine, WineboardError } from "interfaces/wine_interfaces";
import { Link } from "react-router-dom";

export interface WineTableProps {
  error: WineboardError;
  wines: MerchantWine[];
}

const WineTable: React.FunctionComponent<WineTableProps> = ({
  error,
  wines,
}) => {
  return (
    <table className="table table-borderless">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Produttore</th>
          <th scope="col">Alcohol</th>
          <th scope="col">Colore</th>
          <th scope="col">Annata</th>
        </tr>
      </thead>
      <tbody>
        {!error.error &&
          wines.map((w) => (
            <tr key={w.wineId}>
              <th scope="row">{w.wineId}</th>
              <td>
                <Link
                  className="anchor-link"
                  to={"/app/admin/wine/" + w.wineId + "/winery/" + w.wineryId}
                >
                  {w.wine}
                </Link>
              </td>
              <td>{w.winery.winery}</td>
              <td>{w.wineAlcohol}</td>
              <td>
                {w.winefamily?.winecolor.winecolor || w.winecolor?.winecolor}
              </td>
              <td>{w.wineYear}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default WineTable;
