import React from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const WinesChart = ({ data, max, dataKey }) => {
  return (
    <ResponsiveContainer width="100%" height={600}>
      <ComposedChart
        layout="vertical"
        width="100%"
        height={200}
        data={data.slice(0, max)}
      >
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="5 5" />
        <XAxis type="number" />
        <YAxis width={400} dataKey="name" type="category" />
        <Tooltip />
        <Bar dataKey={dataKey} barSize={30} fill="#93024e" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default WinesChart;
