import * as React from "react";
import TablePagination from "@material-ui/core/TablePagination";

export interface TablePaginationITProps {
  data: Array<any>;
  currentPage: number;
  onChangePage: () => void;
  rowsPerPage: number;
  onChangeRowsPerPage: () => void;
}

const TablePaginationIT: React.FunctionComponent<TablePaginationITProps> = ({
  data,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage,
}) => {
  return (
    <TablePagination
      component="div"
      count={data.length}
      page={currentPage}
      onChangePage={onChangePage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      labelRowsPerPage={"Righe per pagina"}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
    />
  );
};

export default TablePaginationIT;
