import React from "react";

const SectionHeader = ({ title }) => {
  return (
    <div className="col-12">
      <label className="mb-2 mt-4">
        <b>{title}</b>
      </label>
    </div>
  );
};

export default SectionHeader;
