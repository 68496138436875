import React, { SyntheticEvent } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign, faPen } from "@fortawesome/free-solid-svg-icons";

import helpers, { Font } from "helpers";
import { Wine } from "interfaces/wine_interfaces";

import "../wine.css";
import WineCardMagazzinoModal from "./WineCardMagazzinoModal";
import AvailabilityIcon from "./AvailabilityIcon";

export interface WineCardMagazzinoProps {
  font: Font[];
  onChange: (e: SyntheticEvent<HTMLInputElement>, wineId: number) => void;
  updateWineQuickAction: (
    e: SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    wineName: string,
    skipAlert?: boolean
  ) => Promise<void>;
  updatePriceQuickAction: (
    e: SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    glassPrice: number
  ) => void;
  sellWine: (
    wineId: number,
    winePrice: number,
    wineAvailability: number,
    winePurchasePrice: number,
    wineName: string
  ) => void;
  wine: Wine;
  onWineClick: (wineId: number, wine: Wine) => void;
  onInputFocus: (e: SyntheticEvent<HTMLInputElement>) => void;
  onDelete?: (wineId: number) => void;
}

const WineCardMagazzino: React.FunctionComponent<WineCardMagazzinoProps> = ({
  font,
  onChange,
  updateWineQuickAction,
  updatePriceQuickAction,
  wine,
  sellWine,
  onWineClick,
  onInputFocus,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div className="container wine-card mx-auto my-2">
      <WineCardMagazzinoModal
        wine={wine}
        showModal={showModal}
        setShowModal={setShowModal}
        sellWine={sellWine}
        updateWineQuickAction={updateWineQuickAction}
      />

      <div className="row py-3">
        <div className="col-8">
          <div className="row">
            <div className="col">
              <p
                style={{ cursor: "pointer", fontFamily: font[0].fontFamily }}
                className="primary-wine-row h-5 fs-12"
                onClick={() => onWineClick(wine.id, wine)}
              >
                {helpers.outputWineNameCV(
                  wine.merchantWine,
                  wine.bottle,
                  wine.vintage
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p style={{ fontFamily: font[1].fontFamily }}>
                {wine.merchantWine.winery.winery}{" "}
                {wine.alcohol > 0 && (
                  <>
                    - <strong className="volume">vol {wine.alcohol}%</strong>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="row">
            <div className="col-8">
              <div className="row">
                <div className="col-md-12 col-lg-6 mt-1">
                  <div className="row">
                    <div className="mx-auto my-auto">
                      <AvailabilityIcon />
                    </div>

                    <input
                      type="text"
                      name="availability"
                      className="material-input"
                      style={font[0]}
                      value={wine.availability}
                      onChange={(e) => onChange(e, wine.id)}
                      onBlur={(e) =>
                        updateWineQuickAction(
                          e,
                          wine.id,
                          wine.price,
                          wine.availability,
                          helpers.outputWineNameCV(
                            wine.merchantWine,
                            wine.bottle,
                            wine.vintage > 0 ? wine.vintage : undefined
                          )
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.currentTarget.blur();
                        }
                      }}
                      onFocus={onInputFocus}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 mt-1">
                  <div className="row">
                    <div className="mx-auto my-auto">
                      <FontAwesomeIcon icon={faEuroSign} size="lg" />
                    </div>

                    <input
                      type="text"
                      name="price"
                      className="material-input"
                      style={font[0]}
                      value={wine.price}
                      onChange={(e) => onChange(e, wine.id)}
                      onBlur={(e) =>
                        updatePriceQuickAction(
                          e,
                          wine.id,
                          wine.price,
                          wine.availability,
                          wine.glassPrice
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.currentTarget.blur();
                        }
                      }}
                      onFocus={onInputFocus}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 my-auto">
              <div
                className="availability-change text-right my-auto"
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon icon={faPen} color="white" size="sm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WineCardMagazzino;
