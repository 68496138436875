import React from "react";
import QRCode from "qrcode.react";

const QRs = ({ qrs, cartaViniUrl, downloadQR }) => {
  return (
    <div className="col-md-12 col-lg my-auto text-center">
      <label className="mb-3 mt-4">
        <b>Smart Links</b>
      </label>

      <div className="row justify-content-center mb-4">
        {qrs.map((qr) => (
          <div key={qr.id} className="col-12 mb-4 mx-auto text-center">
            <QRCode
              className="mx-auto"
              style={{ cursor: "pointer" }}
              id={"qr-" + qr.from}
              bgColor="#fff"
              fgColor="#000"
              value={cartaViniUrl + "?from=" + qr.from}
              level="H"
            />
            <h5 className="h5">{qr.title}</h5>
            <button
              className="mb-4 mt-2 btn btn-primary w-75 mx-auto"
              onClick={(e) => downloadQR(e, "qr-" + qr.from)}
            >
              Download QR Code
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QRs;
