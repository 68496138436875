import React, { SyntheticEvent } from "react";
import Autosuggest from "react-autosuggest";
import { Winegrape } from "interfaces/wine_interfaces";
import wineService from "../../../services/wine";
import { MagazzinoWinegrape } from "../Magazzino";

export interface WinegrapesProps {
  winegrapes: MagazzinoWinegrape[];
  onWinegrapePercentageChange: (
    e: SyntheticEvent<HTMLInputElement>,
    index: number
  ) => void;
  onWinegrapeChange: (value: string, index: number) => void;
  onAdd: () => void;
  onWinegrapeDelete: (index: number) => void;
}

export interface WinegrapesState {
  suggestions: Winegrape[];
}

class Winegrapes extends React.Component<WinegrapesProps, WinegrapesState> {
  state = {
    suggestions: [] as Winegrape[],
  };

  getSuggestions = async (value: string): Promise<Winegrape[]> => {
    const inputValue = value.trim().toLowerCase();
    let suggestions: Winegrape[] = [];

    suggestions = await wineService.getWinegrapes(inputValue);

    return suggestions;
  };

  getSuggestionValue = (suggestion: string) => suggestion;

  renderSuggestion = (suggestion: Winegrape) => (
    <div>{suggestion.winegrape}</div>
  );

  onSuggestionsFetchRequested = async ({ value }) => {
    let { suggestions } = this.state;

    suggestions = await this.getSuggestions(value);

    this.setState({ suggestions });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderInputComponent = (
    inputProps: any,
    name: string,
    placeholder: string,
    index: number
  ) => (
    <input
      id={name + String(index)}
      name={name}
      placeholder={placeholder}
      {...inputProps}
    />
  );

  renderSuggestionsContainer = ({ containerProps, children }) => {
    containerProps.className += " w-100";
    return (
      <div
        style={{ marginTop: "-25px", marginBottom: "20px" }}
        {...containerProps}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      winegrapes,
      onWinegrapePercentageChange,
      onAdd,
      onWinegrapeDelete,
    } = this.props;
    const { suggestions } = this.state;

    return (
      <React.Fragment>
        {winegrapes.map((winegrape, index) => {
          const inputProps = {
            className: "form-control mx-auto w-100",
            type: "text",
            value: winegrape.winegrape || "",
            onChange: (e: SyntheticEvent<HTMLInputElement>, { newValue }) =>
              this.props.onWinegrapeChange(newValue, index),
          };

          return (
            <div className="row" key={index}>
              <div style={{ paddingLeft: "0" }} className="col-5">
                <label htmlFor={"winegrape" + String(index)}>Vitigno</label>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                  renderInputComponent={(inputProps) =>
                    this.renderInputComponent(
                      inputProps,
                      "winegrape",
                      "Es. Chardonnay",
                      index
                    )
                  }
                  renderSuggestionsContainer={this.renderSuggestionsContainer}
                />
              </div>

              <div className="col-5">
                <label htmlFor={"percentage" + String(index)}>
                  Percentuale %
                </label>
                <input
                  id={"percentage" + String(index)}
                  className="form-control mx-auto"
                  type="text"
                  name="percentage"
                  placeholder="Percentuale"
                  onChange={(e) => onWinegrapePercentageChange(e, index)}
                  value={winegrape.percentage || ""}
                />
              </div>

              <div style={{ paddingRight: "0" }} className="col-2 mt-2">
                {index < winegrapes.length - 1 && winegrapes.length > 1 && (
                  <div className="input-group plus-minus-input">
                    <div className="input-group-button">
                      <button
                        type="button"
                        className="button hollow circle minus"
                        data-quantity="minus"
                        data-field="quantity"
                        onClick={() => onWinegrapeDelete(index)}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                )}
                {index === winegrapes.length - 1 && (
                  <div className="input-group plus-minus-input">
                    <div className="input-group-button">
                      <button
                        type="button"
                        className="button hollow circle"
                        data-quantity="minus"
                        data-field="quantity"
                        onClick={onAdd}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default Winegrapes;
