import React from "react";
import { NavLink } from "react-router-dom";
import { Bottle, MerchantWine, Wine } from "./interfaces/wine_interfaces";

export interface SidebarLink {
  name: string;
  path: string;
  component: any;
  icon: JSX.Element;
}

export interface Font {
  fontFamily: string;
}

interface SortingType {
  id: number;
  type: string;
  key: string;
  order?: "ASC" | "DESC";
}

export const domain = "https://wineboard.io";

const sortingTypes: Array<SortingType> = [
  { id: 1, type: "Ordine alfabetico per produttore", key: "winery" },
  { id: 2, type: "Ordine alfabetico per denominazione", key: "denom" },
  { id: 3, type: "Ordine alfabetico per nome vino", key: "wine" },
  {
    id: 4,
    type: "Ordine per prezzo crescente",
    key: "price",
    order: "ASC",
  },
  {
    id: 5,
    type: "Ordine per prezzo decrescente",
    key: "price",
    order: "DESC",
  },
  {
    id: 6,
    type: "Ordine per annata crescente",
    key: "vintage",
    order: "ASC",
  },
  {
    id: 7,
    type: "Ordine per annata decrescente",
    key: "vintage",
    order: "DESC",
  },
];

const stringifyDate = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const h = date.getHours();
  let m = String(date.getMinutes());

  if (String(m).length === 1) {
    m = "0" + m;
  }

  return [day, month, year].join("/") + " " + [h, m].join(":");
};

const renderLink = (link: SidebarLink) => {
  return (
    <li className="nav-item" key={link.path}>
      <div className="justify-content-center text-left">
        {link.component && (
          <NavLink className="nav-link" exact to={link.path}>
            <span className="mr-2">{link.icon}</span>
            {link.name}
          </NavLink>
        )}

        {!link.component && (
          <a className="nav-link" href={link.path}>
            <span className="mr-2">{link.icon}</span>
            {link.name}
          </a>
        )}
      </div>
    </li>
  );
};

const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const capitalizeEachWord = (string: string): string => {
  return string.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  );
};

const getBase64 = (
  file: Blob,
  cb: (encodedString: string | ArrayBuffer | null) => void
): void => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
  reader.onerror = (error) => {
    console.log("Error: ", error);
  };
};

//Check if 2 objects have the same values (they must have the same length)
const isObjectEqual = (obj1: any, obj2: any): boolean => {
  const values1 = Object.values(obj1);
  const values2 = Object.values(obj2);

  for (let i = 0; i < values1.length; i++) {
    if (values1[i] !== values2[i]) return false;
  }

  return true;
};

const range = (size: number, startAt: number = 0): Array<number> =>
  [...Array(size).keys()].map((i) => i + startAt).reverse();

const arrayRange = (start: number, end: number, step: number): Array<any> => {
  let array: Array<any> = [];

  for (let i = start; i <= end; i += step) {
    array.push(i);
  }

  return array;
};

const groupByRegion = (xs: Array<Wine>): { [key: string]: Wine[] } => {
  return xs.reduce((rv, x) => {
    if (x.merchantWine.winefamily?.winedenom.region)
      (rv[x.merchantWine.winefamily?.winedenom.region.region] =
        rv[x.merchantWine.winefamily?.winedenom.region.region] || []).push(x);
    else
      (rv[
        x.merchantWine.region?.region ||
          x.merchantWine.country?.country ||
          "Altro"
      ] =
        rv[
          x.merchantWine.region?.region ||
            x.merchantWine.country?.country ||
            "Altro"
        ] || []).push(x);
    return rv;
  }, {});
};

const groupByWinery = (xs: Array<Wine>): { [key: string]: Wine[] } => {
  return xs.reduce((rv, x) => {
    (rv[x.merchantWine.winery.winery] =
      rv[x.merchantWine.winery.winery] || []).push(x);
    return rv;
  }, {});
};

const sortWinesByType = (
  key: string,
  notSortedWines: Array<Wine>,
  wines: Array<Wine>
) => {
  switch (key) {
    case "rossi":
      notSortedWines.forEach((wine) => {
        const winecolor =
          wine.merchantWine.winefamily?.winecolor.winecolor || "";
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";

        if (
          winecolor === "Rosso" &&
          (winetype === "Fermo" || winetype === "Frizzante")
        )
          wines.push(wine);
      });

      break;
    case "rosati":
      notSortedWines.forEach((wine) => {
        const winecolor =
          wine.merchantWine.winefamily?.winecolor.winecolor || "";
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";

        if (
          winecolor === "Rosato" &&
          (winetype === "Fermo" || winetype === "Frizzante")
        )
          wines.push(wine);
      });

      break;
    case "bianchi":
      notSortedWines.forEach((wine) => {
        const winecolor =
          wine.merchantWine.winefamily?.winecolor.winecolor || "";
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";

        if (
          winecolor === "Bianco" &&
          (winetype === "Fermo" || winetype === "Frizzante")
        )
          wines.push(wine);
      });

      break;

    case "spumanti":
      notSortedWines.forEach((wine) => {
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";
        if (winetype.slice(0, 8) === "Spumante") wines.push(wine);
      });

      break;

    case "spumanti-classico":
      notSortedWines.forEach((wine) => {
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";
        if (winetype === "Spumante Metodo Classico") wines.push(wine);
      });

      break;

    case "spumanti-charmat":
      notSortedWines.forEach((wine) => {
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";
        if (winetype === "Spumante Metodo Charmat") wines.push(wine);
      });

      break;

    case "liquorosi":
      notSortedWines.forEach((wine) => {
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";
        if (winetype === "Liquoroso") wines.push(wine);
      });

      break;

    case "passiti":
      notSortedWines.forEach((wine) => {
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";
        if (winetype === "Passito") wines.push(wine);
      });

      break;

    case "fermi":
      notSortedWines.forEach((wine) => {
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";
        if (winetype === "Fermo") wines.push(wine);
      });

      break;

    case "frizzanti":
      notSortedWines.forEach((wine) => {
        const winetype = wine.merchantWine.winefamily?.winetype.winetype || "";
        if (winetype === "Frizzante") wines.push(wine);
      });

      break;

    default:
      break;
  }
};

const sortWinesByAvailability = (
  key: string,
  notSortedWines: Array<Wine>,
  wines: Array<Wine>
): void => {
  switch (key) {
    case "nuovi":
      const date = new Date();
      const weekAgo = date.getDate() - 30;
      date.setDate(weekAgo);

      notSortedWines.forEach((wine) => {
        const wineDate = new Date(wine.createdAt);

        if (wineDate.getTime() > date.getTime()) wines.push(wine);
      });

      break;

    case "esauriti":
      notSortedWines.forEach((wine) => {
        if (wine.availability === 0) wines.push(wine);
      });

      break;

    case "in-esaurimento":
      notSortedWines.forEach((wine) => {
        if (wine.availability <= 3 && wine.availability > 0) wines.push(wine);
      });

      break;

    case "disponibili":
      notSortedWines.forEach((wine) => {
        if (wine.availability > 0) wines.push(wine);
      });

      break;

    case "magazzino-tutti":
      notSortedWines.forEach((wine) => {
        wines.push(wine);
      });

      break;

    case "deterioramento":
      notSortedWines.forEach((wine) => {
        const now = new Date();

        if (wine.bestBefore > 0 && wine.bestBefore <= now.getFullYear())
          wines.push(wine);
      });

      break;

    default:
      break;
  }
};

const objectArrayLength = (obj: any) => {
  let length = 0;
  Object.keys(obj).forEach((key) => (length += obj[key].length));
  return length;
};

const dummyPlural = (string: string) => {
  switch (string) {
    case "Bianco":
      return "Bianchi";
    case "Rosso":
      return "Rossi";
    case "Rosato":
      return "Rosati";

    default:
      return "";
  }
};

const groupBy = (array: Array<any>, key: string): any => {
  return array.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const getSettingLabel = (s: string): { label: string; description: string } => {
  switch (s) {
    case "showPrice":
      return {
        label: "Prezzo",
        description: "Mostreremo il prezzo di ogni vino",
      };

    case "showLogo":
      return {
        label: "Logo Aziendale",
        description:
          "Mostreremo il logo dell'enoteca (la tua immagine profilo) sulla carta vini",
      };

    case "showGlassPrice":
      return {
        label: "Prezzo al Calice",
        description: "Mostreremo il prezzo al calice lì dove disponibile",
      };

    case "showAlcol":
      return {
        label: "Titolo Alcolometrico",
        description: "Mostreremo il titolo alcolometrico dei vini",
      };

    case "showCurrency":
      return {
        label: "Valuta",
        description: "Mostreremo la valuta accanto ai prezzi",
      };

    case "showDailyWine":
      return {
        label: "Vino del Giorno",
        description: "Mostreremo il vino del giorno in cima alla carta",
      };

    case "showWinegrapes":
      return {
        label: "Uvaggi",
        description: "Mostreremo gli uvaggi per ogni vino",
      };

    case "showSearchBar":
      return {
        label: "Barra di ricerca intelligente",
        description:
          "Mostreremo una barra di ricerca all'utente che gli permetterà di cercare un vino per ogni suo attributo (uvaggio, cantina...)",
      };

    case "showProducer":
      return {
        label: "Produttore",
        description: "Mostreremo il produttore per ogni vino",
      };

    case "allowCustomSorting":
      return {
        label: "Ordinamento Personalizzato",
        description:
          "Ordineremo i vini nelle sezioni come tu desideri, diccelo nel box lì sotto",
      };

    case "showYear":
      return {
        label: "Annata",
        description: "Mostreremo l'annata nel nome del vino",
      };

    default:
      return { label: "", description: "" };
  }
};

const monthNames: Array<string> = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

/**
 * Output the wine name following these rules:
 *  - If it has a fantasyName -> **winery, winefamily, fantasyName**
 *  - Else -> **winery, winefamily**
 *  - If it has not a *winefamily* or is *createdByMerchant* -> **winery, wine**
 * @param {MerchantWine} wine An istance of the Wine object declared in backend models
 * @param {Bottle} bottle The bottle of the Wine istance
 *
 * @returns {string} The created wine name
 */
const outputWineName = (wine: MerchantWine): string => {
  if (wine.fantasyName) {
    if (wine.winefamily?.winefamilyText)
      return capitalizeEachWord(
        (
          wine.winefamily?.winefamilyText +
          " '" +
          wine.fantasyName +
          "'"
        ).toLowerCase()
      );
    else
      return capitalizeEachWord(
        (wine.wine + " '" + wine.fantasyName + "'").toLowerCase()
      );
  } else {
    return capitalizeEachWord(wine.wine.toLowerCase());
  }
};

/**
 * Output the wine name following these rules:
 *  - If it has a fantasyName -> **winefamily, fantasyName**
 *  - Else -> **winefamily**
 *  - If it has not a *winefamily* or is *createdByMerchant* -> **wine**
 * @param {object} wine An istance of the Wine object declared in backend models
 * @param {Bottle} bottle The bottle of the Wine istance
 * @param {number=} wineYear The wineYear to output next to the name (if it's not specified, it will be -1)
 *
 * @returns {string} The created wine name
 */
const outputWineNameCV = (
  wine: MerchantWine,
  bottle: Bottle,
  wineYear?: number
): string => {
  const vintage = wineYear && wineYear > 0 ? " - " + wineYear : "";
  const bottleLiters =
    Number(bottle.liters).toFixed(2) !== "0.75"
      ? " - " + Number(bottle.liters).toFixed(2) + " L"
      : "";

  return outputWineName(wine) + vintage + bottleLiters;
};

/**
 *
 * @param {string} string The String in which you want to bold things
 * @param {string} sub The substring to bold in string
 */
const boldSubString = (string: string, sub: string): string => {
  const searchMask = new RegExp(sub, "ig");
  return capitalizeEachWord(string.replace(searchMask, "<b>" + sub + "</b>"));
};

const sortByMerchantChoice = (
  wines: Array<Wine>,
  sortingTypeIndex: number
): Array<Wine> => {
  const sortingType = sortingTypes[sortingTypeIndex];

  switch (sortingType.key) {
    case "winery":
      return wines.sort((a, b) =>
        a.merchantWine.winery.winery.localeCompare(
          b.merchantWine.winery.winery,
          "en",
          { sensitivity: "base" }
        )
      );

    case "denom":
      console.log(
        wines.sort(
          (a, b) =>
            a.merchantWine.winefamily?.winedenom.winedenomText.localeCompare(
              b.merchantWine.winefamily?.winedenom.winedenomText || "",
              "en",
              { sensitivity: "base" }
            ) || 0
        )
      );
      return wines.sort(
        (a, b) =>
          a.merchantWine.winefamily?.winedenom.winedenomText.localeCompare(
            b.merchantWine.winefamily?.winedenom.winedenomText || "",
            "en",
            { sensitivity: "base" }
          ) || 0
      );

    case "wine":
      return wines.sort((a, b) =>
        outputWineNameCV(a.merchantWine, a.bottle, -1).localeCompare(
          outputWineNameCV(b.merchantWine, a.bottle, -1),
          "en",
          {
            sensitivity: "base",
          }
        )
      );

    case "price":
      return wines.sort((a, b) =>
        sortingType.order && sortingType.order === "ASC"
          ? a.price - b.price
          : b.price - a.price
      );

    case "vintage":
      return wines.sort((a, b) =>
        sortingType.order && sortingType.order === "ASC"
          ? a.vintage - b.vintage
          : b.vintage - a.vintage
      );

    default:
      break;
  }

  return wines;
};

const getWineDesc = (wine: MerchantWine) => {
  const winefamilyAneddoto = wine.winefamily?.winefamilyAneddoto
    ? wine.winefamily?.winefamilyAneddoto + "\n\n"
    : "";

  const winefamilyAspetto = wine.winefamily?.winefamilyAspetto
    ? "### Aspetto\n\n" + wine.winefamily?.winefamilyAspetto + "\n\n"
    : "";

  const winefamilyOdore = wine.winefamily?.winefamilyOdore
    ? "### Odore\n\n" + wine.winefamily?.winefamilyOdore + "\n\n"
    : "";

  const winefamilySapore = wine.winefamily?.winefamilySapore
    ? "### Sapore\n\n" + wine.winefamily?.winefamilySapore + "\n\n"
    : "";

  const wineDesc =
    winefamilyAneddoto + winefamilyAspetto + winefamilyOdore + winefamilySapore;

  return wineDesc !== "" ? wineDesc : "";
};

const winetypeEquals = (wine: MerchantWine, winetype: string): boolean =>
  wine &&
  (wine.winefamily?.winetype.winetype === winetype ||
    wine.winetype?.winetype === winetype);

const winecolorEquals = (wine: MerchantWine, winecolor: string): boolean =>
  wine &&
  (wine.winefamily?.winecolor.winecolor === winecolor ||
    wine.winecolor?.winecolor === winecolor);

const whatsappHandler = (whatsappHandler: string, wine: string): string => {
  let url =
    "https://api.whatsapp.com/send?phone=" +
    (whatsappHandler.startsWith("+")
      ? whatsappHandler
      : "+39" + whatsappHandler);

  let text = `Salve, vorrei ordinare una bottiglia di ${wine}, indirizzo e orario di seguito:`;
  text.replace(/ /g, "%20");

  return url + "&text=" + text;
};

const getBounds = (groupedByWineryWines: {
  [key: string]: Wine[];
}): number[][] => {
  let bounds: number[][] = [];

  Object.keys(groupedByWineryWines).forEach((winery) => {
    const { latitude, longitude } = groupedByWineryWines[
      winery
    ][0].merchantWine.winery;

    if (latitude && longitude) bounds.push([latitude!, longitude!]);
  });

  return bounds.length > 0 ? bounds : [[40.683334, 14.766667]]; // Default -> lat/long of Salerno
};

export default {
  renderLink,
  capitalize,
  getBase64,
  isObjectEqual,
  range,
  arrayRange,
  groupByRegion,
  groupByWinery,
  sortWinesByType,
  sortWinesByAvailability,
  objectArrayLength,
  dummyPlural,
  groupBy,
  monthNames,
  getSettingLabel,
  outputWineName,
  outputWineNameCV,
  boldSubString,
  sortingTypes,
  sortByMerchantChoice,
  getWineDesc,
  winetypeEquals,
  winecolorEquals,
  whatsappHandler,
  getBounds,
  stringifyDate,
};
