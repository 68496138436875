import React, { Component } from "react";
import Joi from "joi-browser";

class Form extends Component {
  state = {
    data: {},
    error: null,
    showPassword: false,
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.onSubmit(e);
  };

  handleChange = (e, skipValidation) => {
    const { name, value } = e.currentTarget;
    const state = { ...this.state };
    state.data[name] = value;
    this.setState(state);

    if (!skipValidation) this.validateProperty(name, value);
  };

  /**
   *
   * @param {number} price The price to get ricarico for
   * @param {object} ricarico The ricarico object
   */
  getRicaricoForPrice = (price, ricarico) => {
    if (price < 5) return ricarico.ricarico5;
    else if (price < 10) return ricarico.ricarico10;
    else if (price < 20) return ricarico.ricarico20;
    else if (price < 50) return ricarico.ricarico50;
    else return ricarico.ricaricoMax;
  };

  handleChangeWithValidationSchema = (
    schema,
    e,
    type,
    ricarico = undefined
  ) => {
    const { name, value } = e.currentTarget;
    const state = { ...this.state };

    if (type !== "number") {
      state.data[name] = value;
      this.setState(state);
    } else {
      if (!isNaN(value)) {
        state.data[name] = Number(value);

        if (ricarico && name === "wineprice") {
          const ricaricoForPrice = this.getRicaricoForPrice(
            state.data[name],
            ricarico
          );
          const percentage = parseFloat(ricaricoForPrice) / 100;
          state.data.wineSellPrice = Math.round(
            parseFloat(value) + parseFloat(value) * percentage
          );
        }

        this.setState(state);
      }
    }

    if (schema) this.validatePropertyWithSchema(schema, name, value);
  };

  validate = (callback = undefined) => {
    const { error } = Joi.validate(this.state.data, this.schema);

    this.setState({ error });

    if (callback) callback(error);
  };

  validateWithSchema = (schema, callback = undefined) => {
    let { error } = Joi.validate(this.state.data, schema, {
      allowUnknown: true,
    });

    if (error && error.details[0].context.label === "value") error = null;

    this.setState({ error });

    if (callback) callback(error);
  };

  validateProperty = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema, { allowUnknown: true });

    this.setState({ error });
  };

  validatePropertyWithSchema = (schema, name, value) => {
    if (schema) {
      const obj = { [name]: value };

      if (schema[name]) {
        const joiSchema = { [name]: schema[name] };

        const { error } = Joi.validate(obj, joiSchema, {
          allowUnknown: true,
        });

        this.setState({ error });
      }
    }
  };

  togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  renderInput = (
    name,
    placeholder,
    type = "text",
    autofocus = false,
    value = "",
    skipValidation = false
  ) => {
    return (
      <input
        id={name}
        className="form-control mx-auto"
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={(e) => this.handleChange(e, skipValidation)}
        autoFocus={autofocus}
        value={value}
      />
    );
  };
}

export default Form;
