import * as React from "react";
import { Wine } from "interfaces/wine_interfaces";
import { Modal } from "react-bootstrap";
import { SegmentedControl } from "segmented-control";
import {
  faWineBottle,
  faEuroSign,
  faWineGlassAlt,
} from "@fortawesome/free-solid-svg-icons";

import helpers from "helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

export interface WineCardMagazzinoModalProps {
  wine: Wine;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  sellWine: (
    wineMerchantId: number,
    price: number,
    availability: number,
    purchasePrice: number,
    wineName: string,
    glasses?: number
  ) => void;
  updateWineQuickAction: (
    e: React.SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    wineName: string,
    skipAlert?: boolean
  ) => Promise<void>;
}

const WineCardMagazzinoModal: React.FunctionComponent<WineCardMagazzinoModalProps> = ({
  wine,
  showModal,
  setShowModal,
  sellWine,
  updateWineQuickAction,
}) => {
  type OptionValue = "sell" | "buy";
  const [optionValue, setOptionValue] = React.useState<OptionValue>("sell");

  const [bottles, setBottles] = React.useState(1);
  const [price, setPrice] = React.useState(wine.price);
  const [glasses, setGlasses] = React.useState(0);

  const color = "#93024e";

  const message = `
    Possiedi ${wine.availability} bottiglie, stai ${
    optionValue === "sell" ? "aprendo" : "comprando"
  } ${bottles} ${bottles > 1 ? "bottiglie" : "bottiglia"} ${
    glasses > 0 && optionValue === "sell"
      ? `per vendere ${glasses} ${glasses > 1 ? "calici" : "calice"}`
      : ``
  } al prezzo di € ${price}
  `;

  const renderInputRow = (
    name: string,
    value: number,
    onChange: (value: number) => void,
    icon: JSX.Element
  ) => {
    return (
      <div className="row mt-2">
        <div className="col my-auto">
          <label className="my-auto" htmlFor={name}>
            {name}
          </label>
        </div>
        <div className="col my-auto text-center">
          <div className="row">
            <div className="col-2 my-auto">{icon}</div>
            <div className="col-10">
              <input
                id={name}
                className="form-control mx-2 w-50"
                type="number"
                name={name}
                onChange={(e) => onChange(Number(e.currentTarget.value))}
                value={value > 0 ? value : 0 - value}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const wineName = helpers.outputWineNameCV(
    wine.merchantWine,
    wine.bottle,
    wine.vintage
  );

  const handleSellWine = () => {
    if (bottles === 0 && glasses === 0) {
      toast(
        "Devi specificare un numero di bottiglie aperte e/o un numero di calici serviti",
        { type: "warning" }
      );

      return;
    }

    sellWine(
      wine.id,
      price,
      wine.availability - bottles,
      wine.purchasePrice,
      wineName,
      glasses > 0 ? glasses : undefined
    );

    setShowModal(false);
  };

  const handleBuyWine = () => {
    if (bottles === 0 || price === 0) {
      toast(
        "Devi specificare un numero di bottiglie acquistate e il prezzo per unità",
        {
          type: "warning",
        }
      );

      return;
    }

    updateWineQuickAction(
      {
        currentTarget: {
          name: "availability",
          value: wine.availability + bottles,
        },
      } as any,
      wine.id,
      price,
      wine.availability + bottles,
      wineName,
      true
    );

    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <div className="row px-2">
          <div className="col">
            <Modal.Title>
              Modifica Scorte <br />{" "}
              <h6 className="text-muted h6">
                {helpers.outputWineName(wine.merchantWine)}
              </h6>
            </Modal.Title>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <SegmentedControl
            name="wineFilter"
            className="segmented-control"
            options={[
              { label: "Vendi", value: "sell", default: true },
              { label: "Compra", value: "buy" },
            ]}
            setValue={(newValue: OptionValue) => {
              setOptionValue(newValue);

              if (newValue === "sell") {
                setBottles(bottles % (wine.availability + 1));

                glasses > 0 ? setPrice(wine.glassPrice) : setPrice(wine.price);
              } else if (newValue === "buy") setPrice(wine.purchasePrice || 0);
            }}
            style={{
              color,
            }}
          />
        </div>

        <div className="container-fluid">
          {renderInputRow(
            "Bottiglie da aprire",
            bottles,
            (bottles) => {
              if (glasses === 0) setPrice(wine.price);
              setBottles(
                optionValue === "sell"
                  ? bottles % (wine.availability + 1)
                  : bottles
              );
            },
            <FontAwesomeIcon icon={faWineBottle} />
          )}

          {optionValue === "sell" &&
            wine.glassPrice > 0 &&
            renderInputRow(
              "Calici da vendere",
              glasses,
              (glasses) => {
                if (glasses > 0) setPrice(wine.glassPrice);
                setGlasses(glasses);
              },
              <FontAwesomeIcon icon={faWineGlassAlt} />
            )}

          {renderInputRow(
            "Prezzo dell'unita da vendere",
            price,
            setPrice,
            <FontAwesomeIcon icon={faEuroSign} />
          )}

          <div className="row mt-4">
            <div className="col-12 mt-4">
              <h6 className="h6">Riepilogo</h6>
            </div>
            <div className="col-12">
              <p className="lead">{message}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => setShowModal(false)}
        >
          Annulla
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            optionValue === "sell" ? handleSellWine() : handleBuyWine();
          }}
        >
          {optionValue === "sell" ? "Vendi" : "Acquista"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default WineCardMagazzinoModal;
