import React from "react";
import Form from "../common/Form";
import { Link } from "react-router-dom";
import authService from "../../services/auth";
import Joi from "joi-browser";
import "./css/Login.css";
import Loader from "Components/Loader";

class Login extends Form {
  state = {
    data: {
      email: "",
      password: "",
    },
    error: null,
    signUp: false,
    apiCalling: false,
  };

  schema = {
    email: Joi.string()
      .email({ minDomainSegments: 2 })
      .required()
      .label("Email"),
    password: Joi.string().required().label("Password"),
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.validate();

    const { error } = this.state;

    if (!error) {
      const { email, password } = e.currentTarget;

      this.setState({ apiCalling: true });

      try {
        const response = await authService.login(email.value, password.value);

        const { token } = response;

        if (token) {
          authService.setToken(token);
          this.props.onAuthentication(token);
        } else {
          const error = { details: ["Email o password non valide"] };
          this.setState({ error, apiCalling: false });
        }
      } catch (err) {
        let error = {};
        console.log(err);

        if (err.response) {
          const { message } = err.response.data;

          //Handle the 2 different errors (Validation/Authentication)
          if (message.details) {
            error = { details: message.details };
          } else {
            error = { details: [{ message }] };
          }

          this.setState({ error });
        }
      }
    }
  };

  render() {
    const { email, password } = this.state.data;
    const { error, apiCalling } = this.state;

    return (
      <div
        style={{ height: "100vh" }}
        className="container d-flex justify-content-center align-middle"
      >
        <form className="form-signin align-middle" onSubmit={this.handleSubmit}>
          <div className="d-flex justify-content-center mb-4">
            <img src="/logo-header-black.png" alt="" width="auto" height="28" />
          </div>

          <div className="my-2">
            {this.renderInput("email", "Indirizzo Email", "text", true, email)}
          </div>

          <div className="my-2">
            {this.renderInput(
              "password",
              "Password",
              "password",
              false,
              password
            )}
          </div>

          {error && (
            <div className="alert alert-danger">{error.details[0].message}</div>
          )}

          {!apiCalling && (
            <button
              disabled={email === "" || password === "" || error}
              className="btn btn-lg btn-primary btn-block mt-4"
              type="submit"
            >
              Sign in
            </button>
          )}

          {apiCalling && <Loader />}

          <p className="mx-auto pt-3 text-center">
            Non hai un account?{" "}
            <Link className="anchor-link" to="/app/register">
              Registrati
            </Link>
          </p>
          <p className="mx-auto text-center">
            <a
              className="anchor-link"
              href="mailto:info@wineboard.io?subject=Password%20Dimenticata"
            >
              Ho dimenticato la password
            </a>
          </p>
          <p className="mt-5 mb-3 text-muted mx-auto text-center">
            {this.props.footer}
          </p>
        </form>
      </div>
    );
  }
}

export default Login;
