import * as React from "react";
import { Container, Button, Link } from "react-floating-action-button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWineGlass } from "@fortawesome/free-solid-svg-icons";

import "./board.css";

export interface ActionButtonProps {
  sections: any[];
}

export interface FABStyle {
  backgroundColor?: string;
  color?: string;
  height?: string;
  opacity?: number;
}

interface WrapperStyle {
  backgroundColor?: string;
  boxShadow?: string;
  height?: string;
  opacity?: number;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
  sections,
}) => {
  const [showList, setShowList] = React.useState(false);
  const [FABColor, setFABColor] = React.useState("#7a0c4b");

  const buttonStyle: FABStyle = { backgroundColor: FABColor, color: "#fff" };
  const wrapperStyle: WrapperStyle = {
    backgroundColor: "#ffffff00",
    boxShadow: "none",
  };

  document.onscroll = () => setShowList(false);

  return (
    <Container className="fab-container-sections">
      {sections.map((s) => {
        if (s.color && s.data.length > 0) {
          const linkStyle: FABStyle = {
            color: "#fff",
            backgroundColor: s.color,
          };

          if (showList) {
            linkStyle.height = "40px";
            linkStyle.opacity = 1;

            wrapperStyle.height = "40px";
            wrapperStyle.opacity = 1;
          } else {
            linkStyle.height = "0px";
            linkStyle.opacity = 0;

            wrapperStyle.height = "0px";
            wrapperStyle.opacity = 0;
          }

          return (
            <div
              key={s.title}
              style={wrapperStyle}
              className="fab-item undefined"
              onClick={() => setFABColor(s.color)}
            >
              <Link href={"#" + s.title} tooltip={s.title} styles={linkStyle}>
                <FontAwesomeIcon icon={faWineGlass} />
              </Link>
            </div>
          );
        } else return <React.Fragment key={s.title}></React.Fragment>;
      })}

      <Button
        icon="fas fa-wine-glass"
        styles={buttonStyle}
        onClick={() => setShowList(!showList)}
      />
    </Container>
  );
};

export default ActionButton;
