import * as React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import { UserSettings, Wine } from "interfaces/wine_interfaces";
import helpers, { Font } from "helpers";
import Carta from "Components/Magazzino/Carta";
import { LatLngBounds } from "leaflet";
import MapActionButton from "./MapActionButton";

export interface MapBoardViewProps {
  wines: Wine[];
  fonts: Font[][];
  chosenFont: number;
  editable: boolean;
  wineToLookFor: string;
  userSettings: UserSettings;
  onWineClick: (wineId: number, wine: Wine) => void;
  whatsappHandler: string;
}

const MapBoardView: React.FunctionComponent<MapBoardViewProps> = ({
  wines,
  fonts,
  editable,
  wineToLookFor,
  userSettings,
  onWineClick,
  whatsappHandler,
}) => {
  const winesByWinery = helpers.groupByWinery(wines);
  const bounds = helpers.getBounds(winesByWinery);

  return (
    <>
      <MapContainer
        style={{ height: "100vh" }}
        bounds={(bounds as unknown) as LatLngBounds}
        scrollWheelZoom={false}
        tap={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {Object.keys(winesByWinery).map((wKey) => {
          const { latitude, longitude } = winesByWinery[
            wKey
          ][0].merchantWine.winery;

          return (
            latitude &&
            longitude && (
              <Marker
                key={wKey}
                position={[
                  winesByWinery[wKey][0].merchantWine.winery.latitude!,
                  winesByWinery[wKey][0].merchantWine.winery.longitude!,
                ]}
              >
                <Popup closeOnClick={true}>
                  <Carta
                    className="mt-2"
                    wines={winesByWinery[wKey]}
                    fonts={fonts}
                    editable={editable}
                    wineToLookFor={wineToLookFor}
                    userSettings={userSettings}
                    onWineClick={onWineClick}
                    whatsappHandler={whatsappHandler}
                    hideMap={true}
                    showFAB={false}
                  />
                </Popup>
              </Marker>
            )
          );
        })}
      </MapContainer>

      <MapActionButton
        link={window.location.pathname.replace("/map", "")}
        icon="fa-chevron-left"
      />
    </>
  );
};

export default MapBoardView;
