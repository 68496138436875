import React from "react";
import Form from "../common/Form";
import { toast } from "react-toastify";
import authService from "../../services/auth";
import helpers from "../../helpers";

class ModifyForm extends Form {
  state = {
    data: {},
    original: {},
    regions: [],
    provinces: [],
    sortedProvinces: [],
    error: null,
  };

  async componentDidMount() {
    const {
      socialReason,
      regionId,
      provinceId,
      city,
      countryId,
      p_iva,
      address,
      postal_code,
      tel_number,
      whatsappHandler,
      invoiceCode,
      PEC,
      name,
      houseNumber,
    } = this.props.user;

    const data = {
      socialReason,
      regionId,
      provinceId,
      city,
      countryId,
      p_iva,
      address,
      postal_code,
      tel_number,
      whatsappHandler,
      invoiceCode,
      PEC,
      name,
      houseNumber,
    };

    const pr = await authService.getProvincesAndRegions();

    let provinces = [];
    let regions = [];

    if (pr.success) {
      provinces = pr.provinces;
      regions = pr.regions;
    }

    //Original will be used to check if user has modified some of the fields
    //and to accordingly render the save button
    this.setState({
      data,
      original: { ...data },
      provinces,
      regions,
      sortedProvinces: provinces,
    });
  }

  handleSelectChange = (e) => {
    const { name, value } = e.currentTarget;
    const { data } = this.state;

    data[name] = Number(value);

    if (name === "regionId") {
      const { provinces } = this.state;

      this.setState({
        sortedProvinces: provinces.filter((p) => p.idRegion === Number(value)),
      });
    }

    this.setState({ data });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const registry = { ...this.state.data };

    try {
      const response = await authService.updateRegistry(registry);

      if (response.success) {
        this.props.onUserEdit(registry);

        toast("Dati di Fatturazione aggiornati correttamente!", {
          type: "success",
        });
      }
    } catch (error) {
      toast("Oops! Qualcosa è andato storto", { type: "error" });
    }
  };

  render() {
    const { error, data: user, original } = this.state;

    return (
      <React.Fragment>
        <form className="container pt-4" onSubmit={this.handleSubmit}>
          <div className="row">
            <h1 className="h3 mb-3 font-weight-normal">
              Contatti per l'utente
            </h1>
          </div>

          <div className="m-2 mx-auto form-row">
            <div className="col">
              <label htmlFor="whatsappHandler">Nome del Locale</label>
              {this.renderInput("name", "Nome", "text", false, user.name, true)}
            </div>
          </div>

          <div className="m-2 mx-auto form-row">
            <div className="col">
              <label htmlFor="whatsappHandler">Ordina su Whatsapp</label>
              {this.renderInput(
                "whatsappHandler",
                "Cellulare Whatsapp",
                "text",
                false,
                user.whatsappHandler,
                true
              )}
            </div>
          </div>

          <div className="row mt-4">
            <h1 className="h3 mb-3 font-weight-normal">Dati di Fatturazione</h1>
          </div>

          <div className="m-2 mx-auto form-row">
            <div className="col">
              <label htmlFor="socialReason">Ragione Sociale</label>
              {this.renderInput(
                "socialReason",
                "Ragione Sociale",
                "text",
                false,
                user.socialReason,
                true
              )}
            </div>
          </div>

          <div className="m-2 mt-3 mx-auto form-row">
            <div className="col">
              <label htmlFor="p_iva">Partita IVA</label>
              {this.renderInput(
                "p_iva",
                "Partita IVA",
                "text",
                false,
                user.p_iva,
                true
              )}
            </div>
          </div>

          <div className="m-2 mt-3 mx-auto form-row">
            <div className="col">
              <label htmlFor="p_iva">Posta Elettronica Certificata</label>
              {this.renderInput("PEC", "PEC", "text", false, user.PEC, true)}
            </div>
          </div>

          <div className="m-2 mt-3 mx-auto form-row">
            <div className="col">
              <label htmlFor="p_iva">Codice di Fatturazione Elettronica</label>
              {this.renderInput(
                "invoiceCode",
                "Codice",
                "text",
                false,
                user.invoiceCode,
                true
              )}
            </div>
          </div>

          <div className="m-2 mt-3 mx-auto form-row">
            <div className="col">
              <label htmlFor="province">Regione</label>
              <select
                id="region"
                onChange={this.handleSelectChange}
                name="regionId"
                className="form-control"
                value={this.state.data.regionId}
              >
                <option>Seleziona una regione</option>
                {this.state.regions.map((r) => (
                  <option key={r.regionId} value={r.regionId}>
                    {r.region}
                  </option>
                ))}
              </select>
            </div>

            <div className="col">
              <label htmlFor="province">Provincia</label>
              <select
                id="province"
                onChange={this.handleSelectChange}
                name="provinceId"
                className="form-control"
                value={this.state.data.provinceId}
              >
                <option>Seleziona una provincia</option>
                {this.state.sortedProvinces.map((p) => (
                  <option key={p.idProvince} value={p.idProvince}>
                    {p.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col">
              <label htmlFor="postal_code">Codice Postale</label>
              {this.renderInput(
                "postal_code",
                "Codice Postale",
                "text",
                false,
                user.postal_code,
                true
              )}
            </div>
          </div>

          <div className="m-2 mt-3 mx-auto form-row">
            <div className="col">
              <label htmlFor="city">Città</label>
              {this.renderInput(
                "city",
                "Città",
                "text",
                false,
                user.city,
                true
              )}
            </div>

            <div className="col">
              <label htmlFor="address">Indirizzo</label>
              {this.renderInput(
                "address",
                "Indirizzo",
                "text",
                false,
                user.address,
                true
              )}
            </div>

            <div className="col">
              <label htmlFor="address">Numero Civico</label>
              {this.renderInput(
                "houseNumber",
                "Numero",
                "text",
                false,
                user.houseNumber,
                true
              )}
            </div>
          </div>

          <div className="m-2 mt-3 mx-auto form-row">
            <div className="col">
              <label htmlFor="tel_number">Numero di Telefono</label>
              {this.renderInput(
                "tel_number",
                "Numero di telefono",
                "text",
                false,
                user.tel_number,
                true
              )}
            </div>
          </div>

          {!helpers.isObjectEqual(original, user) && (
            <button
              disabled={error}
              className="mx-auto btn btn-lg btn-primary btn-block m-2 mt-4"
              type="submit"
            >
              Salva
            </button>
          )}
        </form>

        {error && (
          <div className="alert alert-danger mx-auto">
            {error.details[0].message}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ModifyForm;
