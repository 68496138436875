import styled from "styled-components";

export const StyledBurger = styled.button`
  position: absolute;
  top: 5%;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  padding: 0px 100px 0px 0px;
  z-index: 11;
  margin: 10px;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 992px) {
    div {
      width: 2rem;
      height: 3px !important;
      background-color: ${({ open }) => (open ? "#ffffff" : "#93024e")};
      border-radius: 2px;
      transition: transform 0.3s linear;
      position: relative;
      transform-origin: 1px;
      margin-left: 15px;

      :first-child {
        transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-child(2) {
        opacity: ${({ open }) => (open ? "0" : "1")};
        transform: ${({ open }) =>
          open ? "translateX(20px)" : "translateX(0)"};
      }

      :nth-child(3) {
        transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      }
    }
  }
`;
