import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = ({ image }) => {
  const [showing, setShowing] = React.useState(true);

  return (
    <>
      {showing && (
        <LazyLoadImage
          className={image.className || "responsive-img mx-auto"}
          style={image.style}
          alt={image.alt}
          src={image.src}
          height={image.height}
          width={image.width}
          onError={() => setShowing(false)}
        />
      )}
    </>
  );
};

export default Image;
