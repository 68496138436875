import React, { ReactElement, SyntheticEvent } from "react";
import Autosuggest from "react-autosuggest";
import WineGrapes from "./WineGrapes";
import wineService from "../../../services/wine";
import "../wine.css";
import {
  Country,
  Region,
  Winecolor,
  Winefamily,
  Winetype,
} from "interfaces/wine_interfaces";
import { MagazzinoWinegrape, PrecompiledWineData } from "../Magazzino";
import Loader from "Components/Loader";
import helpers from "helpers";

export interface Step2Props {
  suggestions: {
    winetype: Winetype[];
    winecolor: Winecolor[];
    wineregion: Region[];
    winecountry: Country[];
  };
  onDataChange: (data: PrecompiledWineData) => void;
  step: number;
  winegrapes: MagazzinoWinegrape[];
  onWinegrapeChange: (value: string, index: number) => void;
  onWinegrapePercentageChange: (
    e: SyntheticEvent<HTMLInputElement>,
    index: number
  ) => void;
  onAdd: () => void;
  data: PrecompiledWineData;
  onWinegrapeDelete: (index: number) => void;
}

export interface Step2State {
  suggestions: {
    winefamily: Winefamily[] | string[];
    winecountry: Country[];
    wineregion: Region[];
  };
  fetchSuggestionHandler?: NodeJS.Timeout;
  loadingSuggestions: boolean;
}

class Step2 extends React.Component<Step2Props, Step2State> {
  state: Step2State = {
    suggestions: {
      winefamily: [] as Winefamily[],
      winecountry: [],
      wineregion: [],
    },
    loadingSuggestions: false,
    fetchSuggestionHandler: undefined,
  };

  getSuggestions = async (value: string): Promise<Winefamily[]> => {
    const inputValue = value.trim().toLowerCase();
    let suggestions: Winefamily[] = [];

    if (inputValue.length <= 2) return [];

    suggestions = await wineService.getWinefamilies(inputValue);

    return suggestions;
  };

  getSuggestionValue = (suggestion: any, field: string): string => {
    return suggestion[field];
  };

  renderSuggestion = (suggestion: any, field: string): ReactElement => {
    if (this.state.loadingSuggestions && suggestion === "loading")
      return (
        <div className="row">
          <Loader />
        </div>
      );

    const icon = suggestion.winecolor
      ? suggestion.winecolor.winecolor
      : undefined;

    return (
      <div className="container">
        <div className="row">
          <div className="col-1">
            {icon && (
              <img
                src={require("../img/" + icon + ".png")}
                height={32}
                alt={icon}
              />
            )}
          </div>
          <div
            className="col"
            dangerouslySetInnerHTML={{
              __html: helpers.boldSubString(
                suggestion[field],
                this.props.data.winefamilyText || ""
              ),
            }}
          />
        </div>
      </div>
    );
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    if (this.state.fetchSuggestionHandler)
      clearTimeout(this.state.fetchSuggestionHandler);

    let { suggestions } = this.state;
    suggestions.winefamily = ["loading"];

    this.setState({
      suggestions,
      loadingSuggestions: true,
      fetchSuggestionHandler: setTimeout(async () => {
        const { suggestions } = this.state;
        suggestions.winefamily = await this.getSuggestions(value);
        this.setState({ suggestions, loadingSuggestions: false });
      }, 500),
    });
  };

  onSuggestionsClearRequested = () => {
    const { suggestions } = this.state;

    suggestions.winefamily = [];
    this.setState({
      suggestions,
    });
  };

  onChange = (
    e: SyntheticEvent<HTMLInputElement>,
    newValue: any,
    field: string
  ) => {
    if (newValue.method === "click" && field === "winefamilyText") {
      const { suggestions } = this.state;
      const { winetype, winecolor, wineregion } = this.props.suggestions;

      const family = (suggestions.winefamily as Winefamily[]).filter(
        (wf) => wf.winefamilyText === newValue.newValue
      )[0];

      if (!family.winefamilyText) return;

      const type = winetype.filter(
        (wt) => wt.winetypeId === family.winetypeId
      )[0];

      const color = winecolor.filter(
        (wc) => wc.winecolorId === family.winecolorId
      )[0];

      const region = wineregion.filter(
        (wr) => wr.regionId === family.winedenom.regionId
      )[0];

      let { winegrapes } = this.props;

      const { winegrape } = family;

      if (winegrape)
        winegrapes.unshift({
          winegrapeId: winegrape.winegrapeId,
          winegrape: winegrape.winegrape,
          percentage: winegrape.m_wine_winegrape.winegrapePercentage,
        });

      this.props.onDataChange({
        wine: -1,
        [field]: newValue.newValue,
        winefamily: family.winefamilyId,
        winetype: type.winetypeId,
        winecolor: color.winecolorId,
        wineregion: region.regionId,
        winecountry: region.countryId,
        winegrapes,
      });

      return;
    }

    this.props.onDataChange({
      wine: -1,
      winefamily: -1,
      [field]: newValue.newValue,
    });
  };

  renderInputComponent = (
    inputProps: any,
    name: string,
    label?: string,
    placeholder?: string
  ) => (
    <div className="mt-2">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        id={name}
        className="form-control mx-auto"
        type="text"
        name={name}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );

  renderSuggestionsContainer = ({ containerProps, children }) => {
    containerProps.className += " w-100";
    return <div {...containerProps}>{children}</div>;
  };

  renderInput = (
    field: string,
    label: string,
    props: any,
    placeholder: string,
    suggestionProp: any
  ): ReactElement => {
    const { suggestions } = this.state;
    return (
      <Autosuggest
        suggestions={suggestions[suggestionProp]}
        onSuggestionsFetchRequested={(value) =>
          this.onSuggestionsFetchRequested(value)
        }
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) =>
          this.getSuggestionValue(suggestion, field)
        }
        renderSuggestion={(suggestion) =>
          this.renderSuggestion(suggestion, field)
        }
        inputProps={props}
        renderInputComponent={(inputProps) =>
          this.renderInputComponent(inputProps, field, label, placeholder)
        }
        renderSuggestionsContainer={this.renderSuggestionsContainer}
      />
    );
  };

  handleSelectChange = (
    e: SyntheticEvent<HTMLSelectElement>,
    field: string
  ) => {
    const { value } = e.currentTarget;
    const data = { [field]: value, winefamily: -1, wine: -1 };

    this.props.onDataChange(data);
  };

  render() {
    const {
      step,
      suggestions,
      winegrapes,
      onWinegrapeChange,
      onWinegrapePercentageChange,
      onAdd,
      data,
    } = this.props;

    const winefamilyProps = {
      name: "winefamilyText",
      value: this.props.data.winefamilyText,
      onChange: (e, newValue) => this.onChange(e, newValue, "winefamilyText"),
      className: "form-control mx-auto w-100",
      autoFocus: this.props.data.winefamilyText === "" && this.props.step === 2,
    };

    if (step < 2) return null;

    return (
      <>
        <div className="row">
          <h1 className="h3 font-weight-normal text-center w-100 mx-auto mt-4">
            Caratteristiche tecniche
          </h1>
        </div>

        {this.renderInput(
          "winefamilyText",
          "Famiglia o Denominazione di Origine",
          winefamilyProps,
          "Es. Amarone della Valpolicella",
          "winefamily"
        )}

        <div className="row mt-3 mb-2">
          <div style={{ padding: "0" }} className="col pr-1">
            <select
              value={data.winetype}
              className="custom-select w-100"
              onChange={(e) => this.handleSelectChange(e, "winetype")}
            >
              {suggestions &&
                suggestions.winetype.map((wt) => {
                  return (
                    <option key={wt.winetypeId} value={wt.winetypeId}>
                      {wt.winetype}
                    </option>
                  );
                })}
            </select>
          </div>

          <div style={{ padding: "0" }} className="col pl-1">
            <select
              style={{
                color: `${data.winecolor === -1 ? "#a2a2a2" : "#555"}`,
              }}
              value={data.winecolor}
              className="custom-select w-100"
              onChange={(e) => this.handleSelectChange(e, "winecolor")}
            >
              <option value={-1}>Seleziona Colore</option>
              {suggestions &&
                suggestions.winecolor.map((wc) => {
                  return (
                    <option key={wc.winecolorId} value={wc.winecolorId}>
                      {wc.winecolor}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="row mt-3 mb-2">
          <div style={{ padding: "0" }} className="col pr-1">
            <select
              value={data.winecountry}
              className="custom-select w-100"
              onChange={(e) => this.handleSelectChange(e, "winecountry")}
            >
              {suggestions &&
                suggestions.winecountry.map((wc) => {
                  return (
                    <option key={wc.countryId} value={wc.countryId}>
                      {wc.country}
                    </option>
                  );
                })}
            </select>
          </div>

          <div style={{ padding: "0" }} className="col pl-1">
            <select
              style={{
                color: `${data.wineregion === -1 ? "#a2a2a2" : "#555"}`,
              }}
              value={data.wineregion}
              className="custom-select w-100"
              onChange={(e) => this.handleSelectChange(e, "wineregion")}
            >
              <option value={-1}>Non Specificata</option>
              {suggestions &&
                suggestions.wineregion
                  .filter((wr) => wr.countryId === Number(data.winecountry))
                  .map((wr) => {
                    return (
                      <option key={wr.regionId} value={wr.regionId}>
                        {wr.region}
                      </option>
                    );
                  })}
            </select>
          </div>
        </div>

        <WineGrapes
          winegrapes={winegrapes}
          onWinegrapePercentageChange={onWinegrapePercentageChange}
          onWinegrapeChange={onWinegrapeChange}
          onAdd={onAdd}
          onWinegrapeDelete={this.props.onWinegrapeDelete}
        />
      </>
    );
  }
}

export default Step2;
