import React from "react";
import helpers from "helpers";

const LatestSalesTable = ({ latestSales, currentPage, pageSize }) => {
  let tableData = latestSales;

  if (pageSize)
    tableData = latestSales.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );

  return (
    <table className="table mx-auto px-2">
      <thead>
        <tr>
          <th scope="col">Vino</th>
          <th scope="col">Calici venduti</th>
          <th scope="col">Bottiglie aperte</th>
          <th scope="col">Prezzo / unità</th>
          <th scope="col">Data e ora</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((ls) => (
          <tr key={ls.id}>
            <td>{ls.wine}</td>
            <td>{ls.soldGlasses || "Bottiglia"}</td>
            <td>{ls.numberOfBottles}</td>
            <td>€ {ls.priceReturn}</td>
            <td>{helpers.stringifyDate(new Date(ls.createdAt))}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LatestSalesTable;
