import axios, { AxiosRequestConfig } from "axios";
import { WineImages } from "interfaces/admin_interfaces";
import { MerchantWine, WineboardError } from "interfaces/wine_interfaces";
import { toast } from "react-toastify";
import authService from "./auth";

export const getWinesWithoutImage = async (
  skip: number,
  limit: number
): Promise<{ count: number; rows: MerchantWine[] } | WineboardError> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: {
      "x-access-token": authService.getToken(),
      "content-type": "application/json",
    },
    url: `/api/admin/wines-without-image?skip=${skip}&limit=${limit}`,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getWineryImages = async (
  wineryId: number
): Promise<WineImages | WineboardError> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: "/api/admin/images/" + String(wineryId),
    headers: {
      "x-access-token": authService.getToken(),
      "content-type": "application/json",
    },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const setWineImage = async (
  wineId: number,
  filename: string,
  type: "bottle" | "label"
) => {
  const options: AxiosRequestConfig = {
    method: "PUT",
    url: "/api/admin/wine-image",
    headers: { "x-access-token": authService.getToken() },
    data: { wineId, filename, type },
  };

  try {
    const response = await axios(options);

    toast("L'immagine è stata mandata in review", { type: "success" });
    return response.data;
  } catch (error) {
    toast("Oops! Qualcosa è andato storto!", { type: "error" });
    return error.response.data;
  }
};
