import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouse } from "@fortawesome/free-solid-svg-icons";

import "./icons.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface AvailabilityIconProps {}

const AvailabilityIcon: React.FunctionComponent<AvailabilityIconProps> = () => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Bottiglie Disponibili
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="auto"
      delay={{ show: 100, hide: 300 }}
      overlay={renderTooltip}
    >
      <FontAwesomeIcon icon={faWarehouse} />
    </OverlayTrigger>
  );
};

export default AvailabilityIcon;
