import * as React from "react";
import { Link, useParams } from "react-router-dom";

import { Modal } from "react-bootstrap";

import { useWine } from "./useWine";

import Loader from "Components/Loader";
import WineImageUploader from "Components/common/WineImageUploader";
import Image from "Components/common/Image";
import { setWineImage } from "services/admin";

import { domain } from "helpers";

export interface WineDetailsProps {}

interface WineDetailsParams {
  wineId: number;
  wineryId: number;
}

const WineDetails: React.FunctionComponent<WineDetailsProps> = () => {
  const { wineId, wineryId } = (useParams() as unknown) as WineDetailsParams;

  //Getting data
  const [wine, wineImages, apiCalling] = useWine(wineId, wineryId);

  //For WineImageUploader
  const [uploadingFile, setUploadingFile] = React.useState(false);

  //Handles the image selection
  const [selectedImageFilename, setSelectedImageFilename] = React.useState("");

  const [showModal, setShowModal] = React.useState(false);
  const [imageType, setImageType] = React.useState("bottle");

  const handleImageSelection = (filename: string) => {
    setSelectedImageFilename(filename);
    setShowModal(true);
  };

  const handleImageUpdate = async () => {
    await setWineImage(
      wineId,
      selectedImageFilename,
      imageType as "bottle" | "label"
    );
    setShowModal(false);
  };

  if (apiCalling) return <Loader />;

  return (
    <div className="container my-4 pb-4">
      <div className="row">
        <div className="col my-auto">
          <Link className="anchor-link" to="/app/admin">
            {"< "}Torna indietro
          </Link>
        </div>
        <div className="col text-center my-auto">
          <h3 className="h3">Dettagli Vino #{wineId}</h3>
          <h5 className="h5 text-muted">
            {wine.wineOriginalName && wine.wineOriginalName}
          </h5>
          <p className="lead">
            Produttore:{" "}
            {wine.winery.wineryWebUrl && (
              <a
                className="anchor-link"
                href={
                  wine.winery.wineryWebUrl.startsWith("http")
                    ? wine.winery.wineryWebUrl
                    : "http://" + wine.winery.wineryWebUrl
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {wine.winery.winery}
              </a>
            )}
            {!wine.winery.wineryWebUrl && wine.winery.winery}
            {" #" + wine.winery.wineryId}
          </p>
        </div>
        <div className="col text-right my-auto">
          <button
            className="btn btn-primary mx-auto"
            onClick={() => setUploadingFile(true)}
          >
            Carica un'immagine
          </button>
        </div>
      </div>

      {wineImages.wineImages && wineImages.wineImages.length > 0 && (
        <div className="row my-4">
          <div className="col-12">
            <h5 className="h4">Immagini</h5>
          </div>
          <div className="col-12">
            <p className="lead">Seleziona l'immagine giusta per questo vino</p>
          </div>
        </div>
      )}

      <div className="row">
        {wineImages.wineImages &&
          wineImages.wineImages.map((wi, index) => (
            <div key={wi.filename + index} className="col-3 my-auto py-2">
              <div
                className="card"
                style={{
                  backgroundColor: "#ffffff00",
                  borderColor: "#ffffff00",
                }}
                onClick={() => handleImageSelection(wi.filename)}
              >
                <div style={{ maxWidth: "400px" }} className="mx-auto">
                  <Image
                    image={{
                      src: domain + "/api/admin/wine/image/" + wi.filename,
                      alt: wi.filename,
                      style: { maxHeight: "500px" },
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        {!apiCalling &&
          wineImages.wineImages &&
          wineImages.wineImages.length === 0 && (
            <p>Nessuna immagine da mostrare</p>
          )}
      </div>

      {uploadingFile && (
        <WineImageUploader
          wineId={wineId}
          toBottleUrl="/api/admin/wine-image"
          toLabelUrl="/api/admin/label-image"
          show={uploadingFile}
          onClose={setUploadingFile}
        />
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row my-2">
              <div className="col">
                <p>
                  Specifica se l'immagine selezionata raffigura l'intera
                  bottiglia o solo l'etichetta
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col mx-auto">
                <select
                  className="form-control w-75 mx-auto mb-4"
                  id="sortingType"
                  value={imageType}
                  onChange={(e) => setImageType(e.currentTarget.value)}
                >
                  <option value="bottle">Bottiglia</option>
                  <option value="label">Etichetta</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col mx-auto">
                <button
                  onClick={handleImageUpdate}
                  className="btn btn-primary w-100"
                >
                  Manda in review
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WineDetails;
