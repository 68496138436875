import React from "react";

export interface ThreeDotsProps {}

const Loader: React.FunctionComponent<ThreeDotsProps> = () => {
  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-12 my-auto text-center">
          <div className="loader">
            <div className="wineglass loader__left">
              <div className="loader__top"></div>
            </div>
            <div className="wineglass loader__right">
              <div className="loader__top"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
