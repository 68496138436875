import axios, { AxiosRequestConfig } from "axios";
import authService from "./auth";

import stripeConfig from "../configs/stripeConfig.json";
import * as stripelib from "@stripe/stripe-js";
import { RouterHistory } from "@sentry/react/dist/reactrouter";

const loadStripe = async () => {
  return await stripelib.loadStripe(stripeConfig.publishableKey);
};

export interface Roles {
  maxQuery: number;
  hasDashboard: number;
  canRemoveWatermark: number;
}

const registerCustomerForMerchant = async (
  merchantId: number,
  sessionId: string
) => {
  const options: AxiosRequestConfig = {
    url: "/api/subscriptions/merchant/" + merchantId,
    data: { sessionId },
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "x-access-token": authService.getToken(),
    },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return;
  }
};

const createStripeSession = async (planId: string) => {
  const options: AxiosRequestConfig = {
    method: "POST",
    url: "/api/subscriptions/create-stripe-session",
    data: { planId },
    headers: {
      "content-type": "application/json",
      "x-access-token": authService.getToken(),
    },
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return;
  }
};

const redirectToSession = async (sessionId: string) => {
  const stripe = (await loadStripe()) as stripelib.Stripe;

  stripe.redirectToCheckout({
    sessionId: sessionId,
  });
};

const retrieveCustomerPortal = async (
  customerId: string,
  history: RouterHistory
) => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: "/api/subscriptions/portal/customer/" + customerId,
    headers: {
      "content-type": "application/json",
      "x-access-token": authService.getToken(),
    },
  };

  try {
    const response = await axios(options);
    const { portalUrl } = response.data;

    history.replace("/app");
    window.location.href = portalUrl;
  } catch (error) {
    return;
  }
};

const hasSubscription = async () => {
  const user = await authService.getLoggedUser();

  console.log(user.stripeCustomerId);

  if (!user.stripeCustomerId || !user.stripeSubscriptionId) return false;

  const options: AxiosRequestConfig = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "x-access-token": authService.getToken(),
    },
    url: `/api/subscriptions/customer/${user.id}`,
  };

  try {
    const response = await axios(options);
    const subscription = response.data;
    console.log(subscription.plan.active);
    return subscription.plan.active;
  } catch (error) {
    return false;
  }
};

const getSubscriptions = async () => {
  const options: AxiosRequestConfig = {
    method: "GET",
    headers: { "x-access-token": authService.getToken() },
    url: "/api/subscriptions",
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return;
  }
};

const getSubscription = async () => {
  const user = await authService.getLoggedUser();

  if (!user.stripeCustomerId) return false;

  const options: AxiosRequestConfig = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "x-access-token": authService.getToken(),
    },
    url: `/api/subscriptions/customer/${user.id}`,
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return false;
  }
};

const getMerchantRoles = async (): Promise<Roles> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: "/api/roles/merchant-roles",
    headers: {
      "x-access-token": authService.getToken(),
      "content-type": "application/json",
    },
    params: { merchantId: authService.getLoggedUserFromToken().id },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export default {
  getSubscription,
  getSubscriptions,
  hasSubscription,
  getMerchantRoles,
  createStripeSession,
  redirectToSession,
  registerCustomerForMerchant,
  retrieveCustomerPortal,
};
