import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
// @ts-ignore
import MDEditor from "@uiw/react-md-editor";
import WineCardModalRow from "./WineCardModalRow";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import Image from "../common/Image";
import helpers, { Font, domain } from "helpers";
import {
  Award,
  UserSettings,
  Wine,
  Winegrape,
} from "interfaces/wine_interfaces";
import WineryMap from "./WineryMap";

export interface WineCardPublicProps {
  onWineUserClick: (wineId: number, wine: Wine) => void;
  font: Font[];
  wine: Wine;
  userSettings: UserSettings;
  whatsappHandler: string;
  hideMap?: boolean;
}

export interface WineCardPublicState {
  showModal: boolean;
}

class WineCardPublic extends React.Component<
  WineCardPublicProps,
  WineCardPublicState
> {
  state = {
    showModal: false,
  };

  handleShow = async (wineId: number, wine: Wine) => {
    this.props.onWineUserClick(wineId, wine);

    this.setState({ showModal: true });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  renderWinegrapes = (winegrapes: Winegrape[]) => {
    return (
      <div className="w-100 text-center">
        <h5 className="h5">Uvaggi</h5>
        <p>
          {winegrapes.map((wg) => {
            return (
              <React.Fragment key={wg.winegrape}>
                <b>{wg.winegrape}</b>{" "}
                {wg.m_wine_winegrape &&
                  wg.m_wine_winegrape.winegrapePercentage &&
                  wg.m_wine_winegrape.winegrapePercentage + "%"}
                {winegrapes.indexOf(wg) !== winegrapes.length - 1 && " - "}
              </React.Fragment>
            );
          })}
        </p>
      </div>
    );
  };

  renderRewards = (awards: Award[], wineVintage: number): ReactElement => {
    if (
      awards.length > 0 &&
      awards.filter(
        (aw) => aw.wineVintage === wineVintage && aw.score && aw.score !== ""
      ).length > 0
    ) {
      return (
        <div className="row">
          <div className="col">
            <h5 className="h5 text-center">Awards</h5>
            {awards
              .filter((a) => a.wineVintage === wineVintage)
              .map((award) => (
                <p
                  key={
                    (award.score || "") +
                    (award.wineVintage || "") +
                    award.authority?.idAuthority +
                    award.awardYear
                  }
                >
                  {"🏆 "}
                  <b>{award.authority?.name || ""}</b>
                  {" - "}
                  {(award.score || "") + " - " + (award.awardYear || "")}
                </p>
              ))}
          </div>
        </div>
      );
    }

    return <></>;
  };

  render() {
    const { font, wine, userSettings, whatsappHandler, hideMap } = this.props;

    const { showModal } = this.state;
    const { winetype } = wine.merchantWine.winefamily?.winetype ||
      wine.merchantWine.winetype || { winetype: "" };
    const { winecolor } = wine.merchantWine.winefamily?.winecolor ||
      wine.merchantWine.winecolor || { winecolor: "" };

    let img = "";

    if (winetype.slice(0, 8) === "Spumante") img = winetype.slice(0, 8);
    else img = winecolor;

    const wineName = helpers.outputWineNameCV(
      wine.merchantWine,
      wine.bottle,
      userSettings.showYear ? wine.vintage : undefined
    );

    return (
      <div className="container wine-card my-3">
        <Modal show={showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <div className="row px-2">
              <div className="col-2 my-auto text-right">
                <img
                  src={img !== "" && require("./img/" + img + ".png")}
                  height="64"
                  alt=""
                />
              </div>
              <div className="col-10">
                <Modal.Title style={font[0]}>{wineName}</Modal.Title>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="container px-4 mx-auto">
              <WineCardModalRow
                left="Prodotto da"
                right={wine.merchantWine.winery.winery}
                image={
                  "/api/wine/winery/image/" + wine.merchantWine.winery.wineryId
                }
              />
              {wine.merchantWine.winefamily &&
                wine.merchantWine.winefamily.winedenom && (
                  <>
                    {wine.merchantWine.winefamily.winedenom.region && (
                      <WineCardModalRow
                        left="Regione"
                        right={`${
                          wine.merchantWine.winefamily.winedenom.region.region
                        },${" "}
                    ${
                      wine.merchantWine.winefamily.winedenom.region.country
                        ?.country || ""
                    }`}
                      />
                    )}

                    <WineCardModalRow
                      left="Denominazione"
                      right={
                        wine.merchantWine.winefamily.winedenom.winedenomText
                      }
                      mt="mt-4"
                    />

                    <WineCardModalRow
                      left="Tipologia"
                      right={wine.merchantWine.winefamily.winetype.winetype}
                    />

                    <WineCardModalRow
                      left="Colore"
                      right={wine.merchantWine.winefamily.winecolor.winecolor}
                    />
                  </>
                )}
              {wine.merchantWine.dolcezza && (
                <WineCardModalRow
                  left="Dolcezza"
                  right={wine.merchantWine.dolcezza.nome}
                />
              )}
              {wine.vintage && wine.vintage > 0 && (
                <WineCardModalRow left="Annata" right={String(wine.vintage)} />
              )}
              {wine.idBottle && (
                <WineCardModalRow
                  left="Formato"
                  right={
                    String(wine.bottle.bottle) +
                    ` - ${Number(wine.bottle.liters).toFixed(2)} L`
                  }
                />
              )}

              {whatsappHandler && whatsappHandler.length > 3 && (
                <div className="row mt-4">
                  <div className="col text-center">
                    <a
                      className="btn btn-outline-primary"
                      href={helpers.whatsappHandler(whatsappHandler, wineName)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} />
                      <span className="ml-2">Ordina a casa</span>
                    </a>
                  </div>
                </div>
              )}

              <div className="row my-4">
                {wine.merchantWine.winegrapes.length > 0 &&
                  this.renderWinegrapes(wine.merchantWine.winegrapes)}

                {wine.merchantWine.winegrapes.length === 0 &&
                  wine.merchantWine.winefamily?.winegrape &&
                  this.renderWinegrapes([
                    wine.merchantWine.winefamily!.winegrape!,
                  ])}
              </div>

              <div className="row text-center my-3">
                <Image
                  image={{
                    src: domain + "/api/wine/image/" + wine.merchantWine.wineId,
                    alt: wineName,
                    style: { maxHeight: "500px" },
                    width: "auto",
                  }}
                />
              </div>

              {wine.vintage > 0 &&
                wine.merchantWine.awards &&
                this.renderRewards(wine.merchantWine.awards, wine.vintage)}

              <MDEditor.Markdown
                className="my-4"
                source={
                  wine.description &&
                  wine.description !== helpers.getWineDesc(wine.merchantWine) &&
                  wine.description !== ""
                    ? wine.description
                    : helpers.getWineDesc(wine.merchantWine)
                }
              />
              {!hideMap &&
                wine.merchantWine.winery.latitude &&
                wine.merchantWine.winery.longitude && (
                  <WineryMap
                    latitude={wine.merchantWine.winery.latitude!}
                    longitude={wine.merchantWine.winery.longitude!}
                    wineryName={wine.merchantWine.winery.winery}
                  />
                )}
            </div>
          </Modal.Body>
        </Modal>

        <div className="row py-2">
          <div className="col-9 text-left">
            <div className="row">
              <div className="col">
                <p
                  style={{ cursor: "pointer", fontFamily: font[0].fontFamily }}
                  className="primary-wine-row h5"
                  onClick={() => this.handleShow(wine.id, wine)}
                >
                  {wineName}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <small
                  style={{ fontFamily: font[1].fontFamily }}
                  className="h6"
                >
                  {Boolean(userSettings.showProducer) &&
                    wine.merchantWine.winery.winery}{" "}
                  {Boolean(userSettings.showAlcol) && wine.alcohol > 0 && (
                    <React.Fragment>
                      {" "}
                      - <strong className="volume">vol. {wine.alcohol}%</strong>
                    </React.Fragment>
                  )}
                </small>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <small
                  style={{ fontFamily: font[1].fontFamily }}
                  className="h6"
                >
                  {Boolean(userSettings.showWinegrapes) &&
                    wine.merchantWine.winegrapes.length > 0 &&
                    wine.merchantWine.winegrapes.map((wg, i) =>
                      helpers.capitalize(
                        wg.winegrape +
                          " " +
                          (wg.m_wine_winegrape.winegrapePercentage
                            ? wg.m_wine_winegrape.winegrapePercentage + "%"
                            : "") +
                          (i < wine.merchantWine.winegrapes.length - 1
                            ? ", "
                            : "")
                      )
                    )}
                </small>
              </div>
            </div>
          </div>

          {Boolean(userSettings.showPrice) && (
            <div className="col-3 my-auto text-right">
              <div className="row">
                <div
                  style={{ fontFamily: font[0].fontFamily }}
                  className="col-12 text-right h5"
                >
                  {wine.price} {Boolean(userSettings.showCurrency) && <i> €</i>}
                </div>
                <div
                  style={{ fontFamily: font[1].fontFamily }}
                  className="col-12 text-right"
                >
                  {wine.glassPrice !== 0 &&
                    Boolean(userSettings.showGlassPrice) && (
                      <>
                        cal.{" "}
                        {wine.glassPrice % Math.floor(wine.glassPrice) === 0.5
                          ? wine.glassPrice
                          : Math.round(wine.glassPrice)}{" "}
                        {Boolean(userSettings.showCurrency) && <span>€ </span>}
                      </>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WineCardPublic;
