import React from "react";
import LatestSalesTable from "./LatestSalesTable";
import TableCard from "../common/TableCard";

const LatestSales = ({ latestSales, className }) => {
  return (
    <TableCard
      className={className}
      title="Vendite Recenti"
      linkTo="/app/dashboard/ultime-vendite"
      subtitle={`Ultime ${latestSales.length}`}
    >
      <LatestSalesTable latestSales={latestSales} />
    </TableCard>
  );
};

export default LatestSales;
