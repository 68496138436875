import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Loader from "Components/Loader";
import LatestShoppingTable from "./LatestShoppingTable";

import wineService from "../../../services/wine";
import TablePaginationIT from "../../common/TablePaginationIT";

const SalesPage = () => {
  const [latestShops, setLatestShops] = useState([]);
  const [apiCalling, setApiCalling] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    (async function fetchData() {
      if (latestShops.length === 0) {
        const latestShops = await wineService.getBoughtWines();
        if (!latestShops.error) {
          setLatestShops(latestShops);
          setApiCalling(false);
        }
      }
    })();
  }, [latestShops]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(parseInt(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-2 my-auto">
          <Link className="anchor-link my-auto" to="/app/dashboard">
            {"< Torna Indietro"}
          </Link>
        </div>
        <div className="col">
          <h1 className="h1 text-center mx-auto">Acquisti</h1>
        </div>
        <div className="col-2"></div>
      </div>

      {apiCalling && <Loader />}

      {!apiCalling && (
        <div className="row my-4">
          <div className="col-12">
            <TablePaginationIT
              data={latestShops}
              currentPage={currentPage}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>

          <div className="col-12">
            <div className="chart-card p-4">
              <LatestShoppingTable
                latestShopping={latestShops}
                currentPage={currentPage}
                pageSize={rowsPerPage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesPage;
