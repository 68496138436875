import React from "react";
import { X, Check } from "react-feather";

const SubscriptionCard = ({ onSubSelection, sub }) => {
  return (
    <div
      onClick={() => onSubSelection(sub.planId)}
      className="col-lg-4 col-md-6 col-12 mt-4 pt-2"
    >
      <div className="card pricing-rates business-rate shadow bg-light border-0 rounded">
        <div className="card-body">
          <h3 className="title text-uppercase mb-4">{sub.title}</h3>
          <div className="d-flex">
            <span className="h4 mb-0 mt-2">€</span>
            <span className="price h1 mb-0">{sub.amount}</span>
            <span className="h4 align-self-end mb-1">/anno</span>
          </div>

          {sub.amount === 0 && <p className="mb-4">Fino a 30 referenze</p>}
          {sub.amount === 599 && <p className="mb-4">Fino a 100 referenze</p>}
          {sub.amount === 1499 && <p className="mb-4">Referenze illimitate</p>}

          <ul className="feature list-unstyled pl-0">
            {sub.description.split(",").map((feature) => {
              if (feature.slice(-5) === "(not)") {
                return (
                  <li key={feature} className="feature-list text-muted">
                    <X color="red" />
                    <del>{feature.replace("(not)", "")}</del>
                  </li>
                );
              } else
                return (
                  <li key={feature} className="feature-list text-muted">
                    <Check color="green" />
                    {feature}
                  </li>
                );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
