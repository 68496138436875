import * as React from "react";
import { Redirect } from "react-router-dom";
import Loader from "Components/Loader";
import WineTable from "./WineTable";

import { User } from "interfaces/user_interfaces";
import { getWinesWithoutImage } from "services/admin";
import authService from "../../services/auth";
import { MerchantWine, WineboardError } from "interfaces/wine_interfaces";

export interface AdminProps {
  merchantId: number;
}

export interface AdminState {
  count: number;
  user: User;
  wines: MerchantWine[];
  error: WineboardError;
  apiCalling: boolean;
  appendingWines: boolean;
}

class Admin extends React.Component<AdminProps, AdminState> {
  state = {
    count: 0,
    user: {} as User,
    wines: [] as MerchantWine[],
    error: {} as WineboardError,
    apiCalling: true,
    appendingWines: false,
  };

  async componentDidMount() {
    const user = await authService.getUserById(this.props.merchantId);
    const wines = await getWinesWithoutImage(0, 20);

    if ((wines as WineboardError).error)
      this.setState({
        user,
        apiCalling: false,
        error: wines as WineboardError,
      });
    else
      this.setState({
        user,
        apiCalling: false,
        wines: (wines as { count: number; rows: MerchantWine[] }).rows,
        count: (wines as { count: number; rows: MerchantWine[] }).count,
      });
  }

  handleAppendingWines = async () => {
    this.setState({ appendingWines: true });

    let { wines } = this.state;

    const newWines = await getWinesWithoutImage(wines.length, 20);

    if ((newWines as WineboardError).error)
      this.setState({
        error: newWines as WineboardError,
        appendingWines: false,
      });
    else {
      wines = wines.concat(
        (newWines as { count: number; rows: MerchantWine[] }).rows
      );
      this.setState({
        wines,
        appendingWines: false,
      });
    }
  };

  render() {
    const {
      user,
      apiCalling,
      wines,
      error,
      appendingWines,
      count,
    } = this.state;

    if (apiCalling) return <Loader />;

    if (!user.isAdmin && !apiCalling) return <Redirect to="/app/dashboard" />;

    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col">
            <h1>Admin Dashboard</h1>
          </div>
        </div>

        <div className="row">
          <div className="col">
            Totale Vini: <b>{count}</b>
          </div>
        </div>

        {error.error && (
          <div className="alert alert-danger">
            {JSON.stringify(error.details)}
          </div>
        )}

        <hr />

        <div className="row mt-4">
          <div className="col">
            <WineTable error={error} wines={wines} />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col mx-auto">
            {!appendingWines && (
              <button
                onClick={this.handleAppendingWines}
                className="btn btn-primary w-100"
              >
                Carica Altro
              </button>
            )}

            {appendingWines && <Loader />}
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
