import React from "react";

const WineSorting = ({ sortingTypes, userSettings, onSettingChange }) => {
  return (
    <div className="form-group mt-4">
      <label htmlFor="sortingType my-2">Ordinamento Vini</label>
      <select
        className="form-control"
        id="sortingType"
        value={userSettings.sortingType}
        onChange={(e) => onSettingChange("sortingType", e.currentTarget.value)}
      >
        {sortingTypes.map((st, index) => (
          <option key={index} value={index}>
            {st.type}
          </option>
        ))}
      </select>
    </div>
  );
};

export default WineSorting;
