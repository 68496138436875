import React, { Component } from "react";
import axios from "axios";
import authService from "../../services/auth";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

class ImageUploader extends Component {
  state = {
    file: undefined,
    changing: false,
    show: false,
  };

  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  handleUpload = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("file", this.state.file);

    const options = {
      method: "POST",
      headers: { "x-access-token": authService.getToken() },
      data,
      url: "/api/image/upload",
    };

    try {
      await axios(options);

      window.location.reload();
    } catch (err) {
      toast("Oops! Qualcosa è andato storto!", { type: "error" });
    }
  };

  handleChanging = () => {
    this.setState({ changing: true });
  };

  handleShow = (e) => {
    e.preventDefault();
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const { handleShow, handleClose } = this;
    const { show } = this.state;
    const { currentPic } = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col text-center">
                {
                  <img
                    style={{ cursor: "pointer" }}
                    src={currentPic}
                    height="150"
                    width="150"
                    alt=""
                    onClick={handleShow}
                  />
                }
              </div>
            </div>
            <div className="row mt-2">
              <div className="col text-center">
                <a
                  className="anchor-link"
                  href="/app/profile"
                  onClick={handleShow}
                >
                  Modifica
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex col align-items-center">
            <h6>
              L'utilizzo di un'immagine profilo contribuisce alla
              personalizzazione del tuo profilo.
            </h6>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Carica un'immagine</Modal.Title>
          </Modal.Header>
          <form method="post" action="#" id="#" onSubmit={this.handleUpload}>
            <div className="form-group files">
              <input
                id="picFile"
                type="file"
                className="form-control"
                multiple={false}
                onChange={this.onFileChange}
              />
              <label htmlFor="picFile"></label>
            </div>

            <Modal.Footer>
              {this.state.file && (
                <button
                  disabled={!this.state.file}
                  className="btn btn-primary w-100"
                  type="submit"
                  onClick={handleClose}
                >
                  Upload
                </button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ImageUploader;
