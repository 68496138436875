import "./js/app";

import React, { Component } from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";

import {
  X,
  Check,
  Target,
  CheckCircle,
  ChevronsRight,
  Shield,
} from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

import "./css/magnific-popup.css";
import "./css/materialdesignicons.min.css";
import "./css/owl.theme.default.min.css";
import Image from "Components/common/Image";
import Loader from "Components/Loader";

class Landing extends Component {
  constructor() {
    super();
    this.homeRef = React.createRef();
    this.loaderRef = React.createRef();
  }

  state = {
    loading: true,
    showingLoader: true,
  };

  scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    window.onload = () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ showingLoader: false }), 350);
    };
  }

  render() {
    const { loading, showingLoader } = this.state;

    const loadingStyle = loading ? {} : { opacity: 0 };

    return (
      <div className="landing-page">
        {showingLoader && (
          <div style={loadingStyle} id="preloader">
            <div id="status">
              <Loader />
            </div>
          </div>
        )}

        <header id="topnav" className="defaultscroll sticky">
          <div className="container">
            <div>
              <a className="logo" href="/">
                <Image
                  image={{
                    src: "/logo-header-black.png",
                    height: 30,
                    alt: "logo",
                  }}
                />
              </a>
            </div>
            <div className="buy-button">
              <a href="/app">
                <div className="btn btn-primary login-btn-primary">Login</div>
                <div className="btn btn-light login-btn-light">Login</div>
              </a>
            </div>
          </div>
        </header>
        <section
          className="bg-half-170 d-table w-100 overflow-hidden"
          id="home"
          ref={this.homeRef}
        >
          <div className="container">
            <div className="row align-items-center pt-5">
              <div className="col-lg-7 col-md-6">
                <div className="title-heading">
                  <h1 className="heading mb-3">
                    Gestisce la <b>cantina</b> e <br />
                    la tua <b>carta dei vini</b>
                  </h1>
                  <p className="para-desc text-muted">
                    Mantieni la carta dei vini sempre aggiornata.
                    <br />
                    Porta l'esperienza di scelta del vino al livello successivo.
                    <br />
                    Conosci i tuoi clienti e supporta le tue scelte con i dati
                    sulle vendite.
                  </p>
                  <div className="mt-4 pt-2">
                    <a
                      href="/app"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                    >
                      Provalo Gratuitamente
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="classic-saas-image position-relative">
                  <div className="bg-saas-shape position-relative">
                    <Image
                      image={{
                        src: "/WB_imac.png",
                        className: "mx-auto d-block",
                        alt: "Wineboard mockup",
                        width: "800",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section overflow-hidden">
          <div className="container pb-5 mb-md-5">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="features text-center">
                  <div className="image position-relative d-inline-block">
                    <Image
                      image={{
                        src: require("./images/icon/database.svg"),
                        className: "avatar avatar-small",
                        alt: "illustrazione database",
                      }}
                    />
                  </div>

                  <div className="content mt-4">
                    <h4 className="title-2">Gestionale Semplificato</h4>
                    <p className="text-muted mb-0">
                      Imposta scorte, ricarichi e prezzi con un click.
                      <br />
                      Rifornisciti al momento giusto selezionando le bottiglie
                      che contano per la tua attività.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-12 mt-5 mt-sm-0">
                <div className="features text-center">
                  <div className="image position-relative d-inline-block">
                    <Image
                      image={{
                        src: require("./images/icon/report.svg"),
                        className: "avatar avatar-small",
                        alt: "illustrazione report",
                      }}
                    />
                  </div>

                  <div className="content mt-4">
                    <h4 className="title-2">Statistiche che contano</h4>
                    <p className="text-muted mb-0">
                      Conosci i tuoi clienti e i vini che bevono.
                      <br />
                      Ottimizza la tua carta dei vini e crea valore per la tua
                      attività.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-12 mt-5 mt-sm-0">
                <div className="features text-center">
                  <div className="image position-relative d-inline-block">
                    <Image
                      image={{
                        src: require("./images/icon/insurance.svg"),
                        className: "avatar avatar-small",
                        alt: "illustrazione sicurezza",
                      }}
                    />
                  </div>

                  <div className="content mt-4">
                    <h4 className="title-2">Carta vini Intelligente</h4>
                    <p className="text-muted mb-0">
                      Mai più vini terminati e fuori menu.
                      <br />
                      La carta dei vini si aggiorna da sola in base alle tue
                      scorte.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container pb-5 mb-md-5 mt-100 mt-60">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="saas-feature-shape-left position-relative">
                  <Image
                    image={{
                      src: "/WB_iphone.png",
                      className: "img-fluid mx-auto d-block rounded",
                      alt: "wineboard iphone mockup",
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div className="section-title ml-lg-4">
                  <h1 className="title mb-3">
                    Molto più di una semplice <br />
                    lista di vini
                  </h1>
                  <p className="para-desc text-muted">
                    Rendi la tua carta dei vini interattiva.
                    <br />
                    <br />
                    Aumenta le tue vendite presentando scheda tecnica, note di
                    degustazione ed etichetta senza compromettere l'aspetto
                    minimale e stiloso della tua carta.
                    <br />
                    <br />
                    Personalizza la carta con i colori del tuo brand, aggiungi
                    il tuo logo Logo e scegli i layout che preferisci.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-100 mt-60">
            <div className="row align-items-center">
              <div className="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div className="section-title mr-lg-4">
                  <h1 className="title mb-3">Perchè scegliere Wineboard?</h1>
                  <p className="para-desc text-muted">
                    Per migliorare la tua produttività e le tue vendite.
                    <br />
                    Concentrati solo su ciò che conta per far crescere la tua
                    attività.
                  </p>

                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center pt-4">
                        <h2>
                          <Target />
                        </h2>
                        <div className="ml-3">
                          <h5>Intuitivo</h5>
                          <p className="text-muted mb-0">
                            Fatto da Sommelier per Sommelier.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-flex align-items-center pt-4">
                        <h2>
                          <CheckCircle />
                        </h2>
                        <div className="ml-3">
                          <h5>Completo</h5>
                          <p className="text-muted mb-0">
                            Il nostro database consta di oltre 500.000 vini.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center pt-4">
                        <h2>
                          <ChevronsRight />
                        </h2>
                        <div className="ml-3">
                          <h5>Veloce</h5>
                          <p className="text-muted mb-0">
                            Aggiungi un vino alle tue scorte con un click.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-flex align-items-center pt-4">
                        <h2>
                          <Shield />
                        </h2>
                        <div className="ml-3">
                          <h5>Sicuro</h5>
                          <p className="text-muted mb-0">
                            I tuoi dati finanziari sono al sicuro.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 order-1 order-lg-2">
                <div className="saas-feature-shape-right position-relative">
                  {/*<img
                  src={require("./images/saas/classNameic02.png")}
                  className="img-fluid mx-auto d-block rounded shadow"
                  alt=""
                />*/}
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-4">Scegli l'offerta che fa per te</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div className="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <a style={{ color: "#333" }} href="/app">
                    <div className="card-body">
                      <h2 className="title text-uppercase mb-4">GRATUITO</h2>
                      <div className="d-flex">
                        <span className="h4 mb-0 mt-2">€</span>
                        <span className="price h1 mb-0">0</span>
                        <span className="h4 align-self-end mb-1">/anno</span>
                      </div>

                      <p className="mb-4">Fino a 30 referenze</p>

                      <ul className="feature list-unstyled pl-0">
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Carta dei vini digitale
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Gestionale cantina
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>Pannello Analytics</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>QR-Card da Vetro</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>QR-Card Lavabile</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>Rimuovi Watermark</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>Setup iniziale</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>Design Personalizzato</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>Supporto h24</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>2h di formazione</del>
                        </li>
                      </ul>
                    </div>
                  </a>
                </div>
              </div>

              {/* <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div className="card pricing-rates business-rate shadow bg-white border-0 rounded">
                  <div className="card-body">
                    <h2 className="title text-uppercase mb-4">Sviluppo</h2>
                    <div className="d-flex mb-4">
                      <span className="h4 mb-0 mt-2">€</span>
                      <span className="price h1 mb-0">19</span>
                      <span className="h4 align-self-end mb-1">/anno</span>
                    </div>

                    <ul className="feature list-unstyled pl-0">
                      <li className="feature-list text-muted">
                        <Check color="green" />
                        Carta dei vini digitale
                      </li>
                      <li className="feature-list text-muted">
                        <Check color="green" />
                        Gestionale cantina
                      </li>
                      <li className="feature-list text-muted">
                        <Check color="green" />
                        Pannello Analytics
                      </li>
                      <li className="feature-list text-muted">
                        <Check color="green" />
                        QR-Card da Vetro
                      </li>
                      <li className="feature-list text-muted">
                        <X color="red" />
                        <del>QR-Card Lavabile</del>
                      </li>
                      <li className="feature-list text-muted">
                        <X color="red" />
                        <del>Rimuovi Watermark</del>
                      </li>
                      <li className="feature-list text-muted">
                        <X color="red" />
                        <del>Fino a 100 Referenze</del>
                      </li>
                    </ul>
                    <a href="/app/subscribe" className="btn btn-primary mt-4">
                      Attiva Subito
                    </a>
                  </div>
                </div>
              </div> */}

              <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div className="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <a style={{ color: "#333" }} href="/app">
                    <div className="card-body">
                      <h2 className="title text-uppercase text-primary mb-4">
                        Professionale
                      </h2>
                      <div className="d-flex">
                        <span className="h4 mb-0 mt-2">€</span>
                        <span className="price h1 mb-0">599</span>
                        <span className="h4 align-self-end mb-1">/anno</span>
                      </div>

                      <p className="mb-4">Fino a 100 referenze</p>

                      <ul className="feature list-unstyled pl-0">
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Carta dei vini digitale
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Gestionale cantina
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Pannello Analytics
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          QR-Card da Vetro
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          QR-Card Lavabile
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Rimuovi Watermark
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Setup iniziale
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>Design Personalizzato</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>Supporto h24</del>
                        </li>
                        <li className="feature-list text-muted">
                          <X color="red" />
                          <del>2h di formazione</del>
                        </li>
                      </ul>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div className="card pricing-rates business-rate shadow bg-light border-0 rounded">
                  <a style={{ color: "#333" }} href="/app">
                    <div className="card-body">
                      <h2 className="title text-uppercase mb-4">
                        Personalizzato
                      </h2>
                      <div className="d-flex">
                        <span className="h4 mb-0 mt-2">€</span>
                        <span className="price h1 mb-0">1.499</span>
                        <span className="h4 align-self-end mb-1">/anno</span>
                      </div>

                      <p className="mb-4">Referenze illimitate</p>

                      <ul className="feature list-unstyled pl-0">
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Carta dei vini digitale
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Gestionale cantina
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Pannello Analytics
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          QR-Card da Vetro
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          QR-Card Lavabile
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Rimuovi Watermark
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Setup iniziale
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Design Personalizzato
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          Supporto h24
                        </li>
                        <li className="feature-list text-muted">
                          <Check color="green" />
                          2h di formazione
                        </li>
                      </ul>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container pt-4 mt-100">
            <div className="row justify-content-center">
              <div className="col-12">
                <h3 className="h3 text-center">
                  Prova Wineboard gratuitamente
                </h3>
              </div>
              <div className="col-12 text-center">
                <div className="mt-4 pt-2">
                  <a
                    href="/app/register"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary"
                  >
                    Crea un account
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container pt-4 mt-100 mt-60">
            <div className="row justify-content-center" id="counter">
              <div className="col-12 text-center">
                <div className="section-title">
                  <h4 className="title mb-4">
                    Adottato da oltre{" "}
                    <span className="text-primary">
                      <span className="counter-value" data-count="150">
                        150
                      </span>
                    </span>{" "}
                    ristoranti ed enoteche in tutta Italia.
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-100 mt-60">
            <div className="rounded bg-primary-custom p-lg-5 p-4">
              <div className="row align-items-end">
                <div className="col-md-8">
                  <div className="section-title text-md-left text-center">
                    <h4 className="title mb-3 text-white title-dark">
                      Domande o Suggerimenti?
                    </h4>
                    <p className="text-white mb-0">
                      Non esitare a contattarci.
                      <br />
                      <br />
                      Proveremo a costruire la soluzione che fa per te.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mt-4 mt-sm-0">
                  <div className="text-md-right text-center">
                    <a
                      href="mailto:info@wineboard.io"
                      className="btn btn-light"
                    >
                      Contattaci Ora
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <MessengerCustomerChat
          appId="683438122246474"
          pageId="100197268438903"
        />

        <footer className="footer bg-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                <a href="/" className="logo-footer">
                  <Image
                    image={{
                      src: "/logo-header-white.png",
                      height: 24,
                      alt: "logo",
                    }}
                  />
                </a>
                <p className="mt-4">
                  Prova Wineboard gratuitamente.
                  <br />
                  Porta la tua carta dei vini al livello successivo.
                </p>
                <ul className="list-unstyled social-icon social mb-0 mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/wineboard/"
                      className="rounded"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 className="text-light footer-head">Inizia Subito</h4>
                <ul className="list-unstyled footer-list mt-4">
                  <li>
                    <a href="/app/login" className="text-foot">
                      <i className="mdi mdi-chevron-right mr-1"></i> Login
                    </a>
                  </li>
                  <li>
                    <a href="/app/register" className="text-foot">
                      <i className="mdi mdi-chevron-right mr-1"></i> Registrati
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 className="text-light footer-head">Link Utili</h4>
                <ul className="list-unstyled footer-list mt-4">
                  <li>
                    <a
                      href="https://www.iubenda.com/termini-e-condizioni/67730356"
                      className="text-foot"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-chevron-right mr-1"></i> Termini e
                      Condizioni
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.iubenda.com/privacy-policy/67730356"
                      className="text-foot"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-chevron-right mr-1"></i> Privacy
                      Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.iubenda.com/privacy-policy/67730356/cookie-policy"
                      className="text-foot"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-chevron-right mr-1"></i> Cookie
                      Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="text-sm-left">
                  <p className="mb-0">
                    © 2020,{" "}
                    <b>
                      <a
                        href="http://www.squidbay.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-reset"
                      >
                        Squidbay
                      </a>{" "}
                      Srl
                    </b>
                    <br />
                    P.IVA: 05652870659 <br />
                    Via Picarielli 13, 84133, Salerno (SA)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <button
          onClick={() => {
            this.scroll(this.homeRef);
          }}
          className="btn btn-icon btn-soft-primary back-to-top"
        >
          <i data-feather="arrow-up" className="icons">
            <FontAwesomeIcon icon={faArrowUp} />
          </i>
        </button>
      </div>
    );
  }
}

export default Landing;
