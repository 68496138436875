import React, { Component } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-toastify/dist/ReactToastify.css";

import "./Components/Landing/css/style.css";
import "./Components/Landing/css/colors/red.css";

import "./App.css";

import Main from "./Components/Main";
import Board from "./Components/Board/Board";
import AccountActivation from "./Components/AccountActivation";
import Landing from "./Components/Landing";
import { toast } from "react-toastify";
import { Switch, Route } from "react-router-dom";

toast.configure();

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/app" component={Main} />
          <Route
            path="/board/:id"
            component={(props) => <Board pdf={false} {...props} />}
          />
          <Route path="/confirm" component={AccountActivation} />
          <Route path="/" component={Landing} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
