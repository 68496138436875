import React, { Component } from "react";
import authService from "../../services/auth";
import wineService from "../../services/wine";
import Loader from "../Loader";
import { toast } from "react-toastify";

type RicaricoProperty = {
  id: number;
  max: string;
  value: number;
};

export interface SettingsProps {}

export interface SettingsState {
  ricarico: {
    [key: string]: RicaricoProperty;
  };
  inputValueOnFocus: string;
  fetchingData: boolean;
}

class Settings extends Component<SettingsProps, SettingsState> {
  state = {
    ricarico: {
      ricarico5: { id: 1, max: "Fino a € 5", value: 0 },
      ricarico10: { id: 2, max: "Da € 5 a € 10", value: 0 },
      ricarico20: { id: 3, max: "Da € 10 a € 20", value: 0 },
      ricarico50: { id: 4, max: "Da € 20 a € 50", value: 0 },
      ricaricoMax: { id: 5, max: "Oltre € 50", value: 0 },
    },
    inputValueOnFocus: "",
    fetchingData: true,
  };

  async componentDidMount() {
    const { ricarico } = this.state;
    const userSettings = await authService.getUserSettings();

    ricarico.ricarico10.value = userSettings.ricarico10;
    ricarico.ricarico5.value = userSettings.ricarico5;
    ricarico.ricarico20.value = userSettings.ricarico20;
    ricarico.ricarico50.value = userSettings.ricarico50;
    ricarico.ricaricoMax.value = userSettings.ricaricoMax;

    this.setState({ ricarico, fetchingData: false });
  }

  handleRicaricoChange = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { ricarico } = this.state;
    const { value } = e.currentTarget;

    if (!isNaN(Number(value))) {
      ricarico[key].value = Number(value);
      this.setState({ ricarico });
    }
  };

  handleRicaricoSubmit = async (key: string): Promise<any> => {
    const { ricarico, inputValueOnFocus } = this.state;
    const value = ricarico[key].value;

    if (Number(inputValueOnFocus) !== Number(value)) {
      const res = await wineService.updateRicarico(key, value);

      if (res.success)
        toast("Ricarico aggiornato con successo!", { type: "success" });
      else
        toast("Oops! Qualcosa è andato storto, riprova tra poco!", {
          type: "error",
        });
    }
  };

  handleRicaricoFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValueOnFocus } = e.currentTarget;
    this.setState({ inputValueOnFocus });
  };

  render() {
    const { ricarico, fetchingData } = this.state;

    if (fetchingData)
      return (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      );

    return (
      <div className="container my-4">
        <div className="row">
          <div className="col">
            <h1 className="h1">Impostazioni</h1>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4 className="mt-4 mb-2 h4">Ricarico Standard</h4>
            <div className="my-4">
              {Object.keys(ricarico).map((rkey) => (
                <div className="row my-4" key={ricarico[rkey].id}>
                  <div className="col-md-12 col-lg-3 text-center my-auto">
                    <b>{ricarico[rkey].max}</b>
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <div className="row">
                      <div className="col text-center">
                        <input
                          className="material-input my-auto mx-auto"
                          type="text"
                          value={ricarico[rkey].value}
                          onChange={(e) => this.handleRicaricoChange(rkey, e)}
                          onBlur={() => this.handleRicaricoSubmit(rkey)}
                          onFocus={this.handleRicaricoFocus}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.currentTarget.blur();
                            }
                          }}
                        />
                        <span className="absolute-percentage">%</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
