import React from "react";
import Image from "../common/Image";

import { domain } from "../../helpers";

export interface WineCardModalRowProps {
  left: string;
  right: string;
  mt?: string;
  image?: string;
}

const WineCardModalRow: React.FunctionComponent<WineCardModalRowProps> = ({
  left,
  right,
  mt,
  image,
}) => {
  return (
    <>
      {image && (
        <div className="row text-center my-3">
          <Image
            image={{
              src: domain + image,
              alt: right,
              height: 48,
              width: "auto",
            }}
          />
        </div>
      )}

      <div className={`row text-center ${mt ? mt : "mt-1"} mx-4`}>
        <div className="col-5 text-right my-auto">
          <b>{left}</b>
        </div>
        <div className="col text-left my-auto">
          <p className="mx-auto">{right}</p>
        </div>
      </div>
    </>
  );
};

export default WineCardModalRow;
