import React from "react";
import helpers from "../../../helpers";

const LatestShoppingTable = ({ latestShopping, currentPage, pageSize }) => {
  let tableData = latestShopping;

  if (pageSize)
    tableData = latestShopping.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );

  return (
    <table className="table mx-auto px-2">
      <thead>
        <tr>
          <th scope="col">Vino</th>
          <th scope="col">Q.ta</th>
          <th scope="col">Prezzo</th>
          <th scope="col">Data e ora</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((ls) => (
          <tr>
            <td>{ls.wine}</td>
            <td>{ls.numberOfBottles}</td>
            <td>€ {ls.price}</td>
            <td>{helpers.stringifyDate(new Date(ls.createdAt))}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LatestShoppingTable;
