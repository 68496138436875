import React from "react";
import ReactToPrint from "react-to-print";

import Loader from "../Loader";

const Header = ({ downloadingPdf, componentToPrintRef }) => {
  return (
    <div className="row">
      <div className="col-8 text-left w-50 my-auto">
        <h1 className="h1">Carta dei Vini</h1>
      </div>
      <div className="col-4 text-right w-50 my-auto">
        {downloadingPdf && <Loader />}
        {!downloadingPdf && (
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-primary mx-auto my-auto">
                Genera PDF
              </button>
            )}
            content={() => componentToPrintRef}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
