import axios, { AxiosRequestConfig } from "axios";
import { User } from "interfaces/user_interfaces";
import jwtDecode from "jwt-decode";

const login = async (email, password) => {
  const options: AxiosRequestConfig = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify({ email, password }),
    url: "/api/auth/login",
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

const setToken = (token) => {
  localStorage.setItem("x-auth-token", token);
};

const getToken = () => {
  return localStorage.getItem("x-auth-token");
};

const getLoggedUser = async (): Promise<User> => {
  const token = getToken();
  const payload: { id: number; email: string } = jwtDecode(token);

  const options: AxiosRequestConfig = {
    method: "GET",
    url: `/api/auth/user/${payload.id}`,
    headers: { "x-access-token": token },
  };

  try {
    const response = await axios(options);

    if (response.data.error) await logout();
    return response.data;
  } catch (error) {
    await logout();
    return error.response.data;
  }
};

const getLoggedUserFromToken = (): { id: number; email: string } => {
  return jwtDecode(getToken());
};

const getUserById = async (id: number): Promise<User> => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: "/api/auth/user/" + id,
    headers: { "x-access-token": getToken() },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const logout = async () => {
  const options: AxiosRequestConfig = {
    method: "DELETE",
    headers: { "content-type": "application/json" },
    data: JSON.stringify({ token: getToken() }),
    url: "/api/auth/logout",
  };

  const deleteTokenAndRedirect = () => {
    localStorage.removeItem("x-auth-token");
    window.location.href = "/app";
  };

  try {
    await axios(options);
    deleteTokenAndRedirect();
  } catch (error) {
    deleteTokenAndRedirect();
  }
};

const firstStepRegister = async (
  name: string,
  email: string,
  password: string,
  captcha: string,
  termsAccepted: boolean
) => {
  const options: AxiosRequestConfig = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify({ name, email, password, captcha, termsAccepted }),
    url: "/api/auth/first-step-register",
  };

  return await axios(options);
};

const register = async (registrationData: any, password: string) => {
  const { email } = registrationData;

  const options: AxiosRequestConfig = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify(registrationData),
    url: "/api/auth/register",
  };

  const response = await axios(options);

  if (response.status === 200) {
    return login(email, password);
  }
};

const confirmRegistration = async (activationUrl) => {
  const options: AxiosRequestConfig = {
    method: "PUT",
    data: { activationUrl },
    url: "/api/auth/confirm-account",
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateRegistry = async (registry) => {
  const options: AxiosRequestConfig = {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "x-access-token": getToken(),
    },
    data: JSON.stringify(registry),
    url: "/api/auth/update-registry",
  };

  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    return error.data || error;
  }
};

const destroyUser = async () => {
  const options: AxiosRequestConfig = {
    method: "DELETE",
    headers: { "x-access-token": getToken() },
    url: "/api/auth/delete-user",
  };

  try {
    const response = await axios(options);

    await logout();

    return response.data;
  } catch (error) {
    return error.data || error;
  }
};

const updateUserSettings = async (settings) => {
  const options: AxiosRequestConfig = {
    method: "PUT",
    url: "/api/auth/update-user-settings",
    headers: { "x-access-token": getToken() },
    data: settings,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getUserSettings = async () => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: "/api/auth/get-user-settings",
    headers: { "x-access-token": getToken() },
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return { error: true };
  }
};

const getUserSettingsPublic = async (userId) => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: `/api/auth/get-user-settings-public?merchant=${userId}`,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return { error: true };
  }
};

const getProvincesAndRegions = async () => {
  const options: AxiosRequestConfig = {
    method: "GET",
    url: `/api/auth/provinces-and-regions`,
  };

  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    return { error: true };
  }
};

export default {
  login,
  setToken,
  getToken,
  getLoggedUser,
  logout,
  register,
  firstStepRegister,
  updateRegistry,
  destroyUser,
  confirmRegistration,
  updateUserSettings,
  getUserSettings,
  getUserSettingsPublic,
  getUserById,
  getLoggedUserFromToken,
  getProvincesAndRegions,
};
