import React from "react";
import { StyledMenu } from "./Menu.styled";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const Menu = ({
  open,
  links,
  toggleMenu,
  handleLogout,
  renderFooter,
  propic,
  user,
}) => {
  return (
    <StyledMenu open={open}>
      <ul className="nav flex-row my-auto mx-4 px-4 h-100 text-center">
        <li className="nav-item w-100">
          <NavLink className="nav-link" onClick={toggleMenu} to="/app/profile">
            <div className="justify-content-center text-center row">
              {propic && (
                <img
                  style={{ borderRadius: "50%" }}
                  src={propic}
                  height="100"
                  width="100"
                  alt=""
                />
              )}
              <p className="py-4 user-name px-2">{user.name || ""}</p>
            </div>
          </NavLink>
        </li>

        {links.map((link) => (
          <li className="nav-item py-2 w-100" key={link.path}>
            <div className="justify-content-center text-left">
              {link.component && (
                <NavLink
                  className="nav-link"
                  exact
                  onClick={toggleMenu}
                  to={link.path}
                >
                  <span className="mr-2">{link.icon}</span>
                  {link.name}
                </NavLink>
              )}

              {!link.component && (
                <a className="nav-link" href={link.path}>
                  <span className="mr-2">{link.icon}</span>
                  {link.name}
                </a>
              )}
            </div>
          </li>
        ))}

        <li className="nav-item py-2 w-100">
          <div className="justify-content-center text-left">
            <Link onClick={handleLogout} className="nav-link" to="/logout">
              <span className="mr-2">
                <FontAwesomeIcon icon={faSignOutAlt} />
              </span>
              Sign out
            </Link>
          </div>
        </li>

        <div className="justify-content-center w-100 text-center mt-4">
          {renderFooter("w-100 text-center")}
        </div>
      </ul>
    </StyledMenu>
  );
};

export default Menu;
