import React, { ReactElement, SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import Autosuggest from "react-autosuggest";

// @ts-ignore
import MDEditor, { commands } from "@uiw/react-md-editor";

import { MagazzinoWinegrape, PrecompiledWineData } from "../Magazzino";

import { MerchantWine, Winery } from "interfaces/wine_interfaces";
import * as wineService from "../../../services/wine";
import helpers from "../../../helpers";
import Loader from "Components/Loader";

export interface Step1Props {
  data: PrecompiledWineData;
  onChange: (data: PrecompiledWineData) => void;
  step?: number;
  renderTextArea: (
    name: string,
    placeholder: string,
    value: string
  ) => ReactElement;
  userIsEditingWine?: boolean;
  originalWineId: number;
  onWineDelete: (wineId: number) => void;
}

export interface Step1State {
  suggestions: { wine: MerchantWine[] | string[]; winery: Winery[] | string[] };
  fetchSuggestionHandler?: NodeJS.Timeout;
  loadingSuggestions: boolean;
}

class Step1 extends React.Component<Step1Props, Step1State> {
  state: Step1State = {
    suggestions: {
      wine: [] as MerchantWine[],
      winery: [] as Winery[],
    },
    fetchSuggestionHandler: undefined,
    loadingSuggestions: false,
  };

  updateMarkdown = (value?: string) => {
    this.props.onChange({ wineDesc: value });
  };

  getSuggestions = async (
    value: string,
    field: string
  ): Promise<Winery[] | MerchantWine[]> => {
    const inputValue = value.trim().toLowerCase();
    let suggestions: Winery[] | MerchantWine[] = [];

    if (inputValue.length <= 2) return [];

    if (field === "wine") suggestions = await wineService.getWines(inputValue);

    if (field === "winery")
      suggestions = await wineService.getWineries(inputValue);

    return suggestions;
  };

  getSuggestionValue = (suggestion: any, field: string) => {
    return suggestion[field];
  };

  renderSuggestion = (suggestion: any, field: string): ReactElement => {
    if (this.state.loadingSuggestions && suggestion === "loading")
      return (
        <div className="row">
          <Loader />
        </div>
      );

    let icon = undefined;

    if (suggestion.winefamily && suggestion.winefamily.winecolor)
      icon = suggestion.winefamily.winecolor.winecolor;

    if (field === "wine")
      return (
        <div className="row">
          <div className="col-1">
            {icon && (
              <img
                src={require("../img/" + icon + ".png")}
                height={32}
                alt={icon}
              />
            )}
          </div>
          <div
            className="col"
            dangerouslySetInnerHTML={{
              __html: helpers.boldSubString(
                helpers.outputWineName(suggestion) +
                  '<br /> <p style="font-size: 10pt; color: #888888">' +
                  suggestion.winery.winery +
                  "</p>",
                this.props.data.wineText || ""
              ),
            }}
          />
        </div>
      );
    else
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: helpers.boldSubString(
              suggestion.winery,
              this.props.data.wineryText || ""
            ),
          }}
        />
      );
  };

  onSuggestionsFetchRequested = async ({ value }, suggestion: string) => {
    if (this.state.fetchSuggestionHandler)
      clearTimeout(this.state.fetchSuggestionHandler);

    let { suggestions } = this.state;
    suggestions = { wine: ["loading"], winery: ["loading"] };

    this.setState({
      suggestions,
      loadingSuggestions: true,
      fetchSuggestionHandler: setTimeout(async () => {
        const { suggestions } = this.state;
        suggestions[suggestion] = await this.getSuggestions(value, suggestion);
        this.setState({ suggestions, loadingSuggestions: false });
      }, 500),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: {
        wine: [],
        winery: [],
      },
    });
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    containerProps.className += " w-100 container-fluid";
    return <div {...containerProps}>{children}</div>;
  };

  onChange = (
    e: SyntheticEvent<HTMLInputElement>,
    newValue: any,
    field: string
  ) => {
    if (newValue.method === "click") {
      if (field === "wineText") {
        const wine = (this.state.suggestions.wine as MerchantWine[]).filter(
          (s: MerchantWine) => s.wine === newValue.newValue
        )[0];

        if (!wine.winegrapes) return;

        let winegrapes: MagazzinoWinegrape[] = [
          { winegrapeId: undefined, winegrape: "", percentage: 100.0 },
        ];

        if (wine.winegrapes.length > 0)
          wine.winegrapes.forEach((wg) => {
            const winegrape: MagazzinoWinegrape = {
              winegrapeId: wg.winegrapeId,
              winegrape: wg.winegrape,
              percentage: wg.m_wine_winegrape.winegrapePercentage,
            };

            winegrapes.unshift(winegrape);
          });
        else if (wine.winefamily?.winefamilyText) {
          const { winegrape } = wine.winefamily;

          if (winegrape)
            winegrapes.unshift({
              winegrapeId: winegrape.winegrapeId,
              winegrape: winegrape.winegrape,
              percentage: winegrape.m_wine_winegrape?.winegrapePercentage || 0,
            });
        }

        const bestBeforeArray = wine.wineBestBefore
          ? wine.wineBestBefore.split("/")
          : [undefined];
        const wineBestBefore = bestBeforeArray[bestBeforeArray.length - 1];

        const bestBeforeValue = parseInt(wineBestBefore || "0");

        const data: PrecompiledWineData = {
          wine: wine.wineId,
          wineText: helpers.outputWineName(wine),
          winery: wine.wineryId,
          wineryText: wine.winery.winery,
          winefamily: wine.winefamilyId,
          winefamilyText: wine.winefamily?.winefamilyText || "",
          winecolor: wine.winefamily?.winecolorId || wine.winecolorId,
          winetype: wine.winefamily?.winetypeId || wine.winetypeId,
          wineregion:
            wine.winefamily?.winedenom.region?.regionId ||
            wine.region?.regionId ||
            -1,
          winecountry:
            wine.winefamily?.winedenom.region?.countryId ||
            wine.region?.countryId ||
            1,
          winealcohol: wine.wineAlcohol || -1,
          winevintage: wine.wineYear || -1,
          winegrapes,
          suggestedPrice: wine.winePrice,
          step: 3,
          wineDesc: helpers.getWineDesc(wine),
          showBestBefore: !!wineBestBefore,
          bestBefore: isNaN(bestBeforeValue) ? 0 : bestBeforeValue,
          createdByMerchant: !!wine.createdByMerchant,
          isWinePublic: !!wine.public,
        };

        this.props.onChange(data);

        return;
      }

      if (field === "wineryText") {
        const winery = (this.state.suggestions.winery as Winery[]).filter(
          (s: Winery) => s.winery === newValue.newValue
        )[0];

        this.props.onChange({
          wine: -1,
          winery: winery.wineryId,
          wineryText: newValue.newValue,
        });

        return;
      }
    }

    if (field === "wineryText")
      this.props.onChange({
        wine: -1,
        winery: -1,
        wineryText: newValue.newValue,
      });
    else this.props.onChange({ wineText: newValue.newValue, wine: -1 });
  };

  renderInputComponent = (inputProps, name, label = undefined, placeholder) => (
    <div className="mt-2">
      {label && <label htmlFor={name}>{label}</label>}
      <span className="required-form-field">*</span>
      <input
        id={name}
        className="form-control mx-auto"
        type="text"
        name={name}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );

  renderInput = (field, label, props, placeholder) => {
    const { suggestions } = this.state;

    return (
      <Autosuggest
        suggestions={suggestions[field]}
        onSuggestionsFetchRequested={(value) =>
          this.onSuggestionsFetchRequested(value, field)
        }
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) =>
          this.getSuggestionValue(suggestion, field)
        }
        renderSuggestion={(suggestion) =>
          this.renderSuggestion(suggestion, field)
        }
        inputProps={props}
        renderInputComponent={(inputProps) =>
          this.renderInputComponent(inputProps, field, label, placeholder)
        }
        renderSuggestionsContainer={this.renderSuggestionsContainer}
      />
    );
  };

  render() {
    if ((this.props.step || 0) < 1) return null;

    const { data, onChange } = this.props;
    const alcol = helpers.arrayRange(10, 20, 0.5);

    const wineryProps = {
      name: "wineryText",
      value: data.wineryText,
      onChange: (e, newValue: string) =>
        this.onChange(e, newValue, "wineryText"),
      className: "form-control mx-auto w-100",
    };

    const wineProps = {
      name: "wineText",
      value: data.wineText,
      onChange: (e, newValue: string) => this.onChange(e, newValue, "wineText"),
      className: "form-control mx-auto w-100",
      autoFocus: !this.props.userIsEditingWine,
    };

    const today = new Date();
    const size = today.getFullYear() - 1970;
    const vintages = helpers.range(size + 1, 1970);

    return (
      <>
        <div className="row">
          <div className="col text-left">
            <Link className="anchor-link" to="/app/magazzino">
              {"< Torna al magazzino"}
            </Link>
          </div>

          <div className="col text-center">
            <h1 className="h3 font-weight-normal text-center w-100 mx-auto">
              {this.props.userIsEditingWine && "Modifica il Vino"}
              {!this.props.userIsEditingWine && "Aggiungi un vino"}
            </h1>
          </div>

          <div className="col text-right"></div>
        </div>

        <div className="row">
          <div className="col-8">
            {this.renderInput("wine", "Vino", wineProps, "Vino")}
          </div>

          <div className="col">
            <div className="mt-2">
              <label htmlFor="vintage">Annata</label>
              <select
                id="vintage"
                value={data.winevintage}
                className="custom-select w-100"
                onChange={(e) =>
                  onChange({ winevintage: Number(e.currentTarget.value) })
                }
              >
                <option value={-1}>Non specificata</option>
                {vintages.map((v) => {
                  return (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        {this.renderInput("winery", "Produttore", wineryProps, "Produttore")}

        <div className="mt-2 mb-4">
          <label htmlFor="alcol">Alcool [%]</label>
          <select
            id="alcol"
            value={data.winealcohol}
            className="custom-select w-100"
            onChange={(e) =>
              onChange({ winealcohol: Number(e.currentTarget.value) })
            }
          >
            <option value={-1}>Non specificato</option>
            {alcol.map((a) => {
              return (
                <option key={a} value={a}>
                  {a}
                </option>
              );
            })}
          </select>
        </div>

        <MDEditor
          value={data.wineDesc}
          onChange={this.updateMarkdown}
          height={250}
          commands={[
            commands.bold,
            commands.italic,
            commands.hr,
            commands.title,
            commands.link,
            commands.quote,
            commands.orderedListCommand,
            commands.unorderedListCommand,
            commands.fullscreen,
          ]}
          autoFocus={false}
        />
      </>
    );
  }
}

export default Step1;
