import * as React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

export interface WineryMapProps {
  latitude: number;
  longitude: number;
  wineryName: string;
}

const WineryMap: React.FunctionComponent<WineryMapProps> = ({
  latitude,
  longitude,
  wineryName,
}) => {
  return (
    <div className="row">
      <div className="col">
        <MapContainer
          style={{ height: "240px" }}
          center={[latitude, longitude]}
          zoom={6}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[latitude, longitude]}>
            <Popup>{wineryName}</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default WineryMap;
