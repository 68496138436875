import React, { SyntheticEvent } from "react";
import Loader from "../Loader";
import AddWine from "./AddWine";
import Carta from "./Carta";
import Select from "../common/Select";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import wineService from "../../services/wine";
import authService from "../../services/auth";
import helpers, { Font } from "../../helpers";
import { Wine, UserSettings } from "interfaces/wine_interfaces";
import { User } from "interfaces/user_interfaces";

interface SelectKey {
  name: string;
  id: string;
}

export interface MagazzinoProps {
  wineToLookFor: string;
  userSettings: UserSettings;
  isAddingWine: boolean;
  onStopAddingWine: () => void;
  user: User;
}

export interface MagazzinoWinegrape {
  winegrapeId?: number;
  winegrape: string;
  percentage: number;
}

export interface PrecompiledWineData {
  wine?: number;
  wineText?: string;
  winery?: number;
  wineryText?: string;
  winefamily?: number;
  winefamilyText?: string;
  winecolor?: number;
  winetype?: number;
  wineregion?: number;
  winecountry?: number;
  winealcohol?: number;
  winevintage?: number;
  winegrapes?: MagazzinoWinegrape[];
  suggestedPrice?: number;
  wineDesc?: string;
  step?: number;
  idBottle?: number;
  createdByMerchant?: boolean;
  clonedWineId?: number;
  isWinePublic?: boolean;
  bestBefore?: number;
  showBestBefore?: boolean;
}

export interface MagazzinoState {
  magazzinoKeys: SelectKey[];
  selectedMagazzinoKey: string;
  tipologiaKeys: SelectKey[];
  selectedTipologiaKey: string;
  isAddingWine: boolean;
  isUpdatingWine: boolean;
  dataForWineUpdate: PrecompiledWineData;
  notSortedWines: Array<Wine>;
  sortedWines: Array<Wine>;
  sorting: boolean;
  apiCalling: boolean;
  wineFilter: string;
  fonts: Font[][];
  userSettings: UserSettings;
  hasReachedMaxWines: boolean;
  inputValueOnFocus: string;
}

class Magazzino extends React.Component<MagazzinoProps, MagazzinoState> {
  state = {
    magazzinoKeys: [
      { name: "Tutti i vini censiti", id: "magazzino-tutti" },
      { name: "Ultime aggiunte", id: "nuovi" },
      { name: "Scorte terminate", id: "esauriti" },
      { name: "Scorte in esaurimento", id: "in-esaurimento" },
      { name: "Soltanto i disponibili", id: "disponibili" },
      { name: "A rischio deterioramento", id: "deterioramento" },
    ],
    selectedMagazzinoKey: "disponibili",
    tipologiaKeys: [
      { name: "Tutti", id: "colors-tutti" },
      { name: "Bollicine", id: "spumanti" },
      { name: "Rossi", id: "rossi" },
      { name: "Rosati", id: "rosati" },
      { name: "Bianchi", id: "bianchi" },
      { name: "Passiti", id: "passiti" },
      { name: "Liquorosi", id: "liquorosi" },
    ],
    selectedTipologiaKey: "colors-tutti",
    isAddingWine: false,
    isUpdatingWine: false,
    dataForWineUpdate: {} as PrecompiledWineData,
    notSortedWines: [] as Wine[],
    sortedWines: [] as Wine[],
    sorting: false,
    apiCalling: true,
    wineFilter: "",
    fonts: [
      [
        {
          fontFamily: "Futura,Trebuchet MS,Arial,sans-serif",
        },
        {
          fontFamily:
            "Bodoni MT,Didot,Didot LT STD,Hoefler Text,Garamond,Times New Roman,serif",
        },
      ],
      [
        {
          fontFamily: "Gill Sans,Gill Sans MT,Calibri,sans-serif",
        },
        {
          fontFamily:
            "Garamond,Baskerville,Baskerville Old Face,Hoefler Text,Times New Roman,serif",
        },
      ],
      [
        {
          fontFamily: "Audrey",
        },
        {
          fontFamily: "Muli",
        },
      ],
    ],
    userSettings: {} as UserSettings,
    hasReachedMaxWines: false,
    inputValueOnFocus: "",
  };

  async componentDidMount() {
    const { wines, hasReachedMaxWines } = await wineService.getWinesForMerchant(
      authService.getLoggedUserFromToken().id
    );

    const userSettings = await authService.getUserSettings();

    let sortedWines = [];
    helpers.sortWinesByAvailability("disponibili", wines, sortedWines);

    this.setState({
      apiCalling: false,
      notSortedWines: wines,
      sortedWines,
      sorting: true,
      userSettings,
      isAddingWine: this.props.isAddingWine,
      hasReachedMaxWines,
    });
  }

  handleMagazzinoKeyChange = (e) => {
    const { value } = e.currentTarget;
    const { notSortedWines } = this.state;
    let wines = [];

    helpers.sortWinesByAvailability(value, notSortedWines, wines);

    this.setState({
      selectedMagazzinoKey: value,
      selectedTipologiaKey: "colors-tutti",
      sortedWines: value !== "magazzino-tutti" ? wines : [],
      sorting: value !== "magazzino-tutti" ? true : false,
    });
  };

  handleTipologiaKeyChange = (e) => {
    const { value } = e.currentTarget;
    const { notSortedWines } = this.state;
    let wines = [];

    helpers.sortWinesByType(value, notSortedWines, wines);

    this.setState({
      selectedMagazzinoKey: "magazzino-tutti",
      selectedTipologiaKey: value,
      sortedWines: wines,
      sorting: value !== "colors-tutti" ? true : false,
    });
  };

  handleWineAdd = async (data) => {
    this.setState({ apiCalling: true });
    data.merchantId = this.props.user.id;
    const newWine = await wineService.addWine(data);

    this.toggleAddingWineAction();
    this.setState({ apiCalling: false });

    if (!newWine.error) await this.componentDidMount();
  };

  toggleAddingWineAction = () => {
    const { isAddingWine } = this.state;
    this.setState({ isAddingWine: !isAddingWine });
  };

  // Used to get the input value and check onblur is it has been modified or not (WineCard)
  inputFocused = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ inputValueOnFocus: value });
  };

  handleFilter = (e) => {
    const { value } = e.currentTarget;

    this.setState({ wineFilter: value });
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>, id: number) => {
    const { name: property, value } = e.currentTarget;

    const { notSortedWines } = this.state;

    if (!isNaN(Number(value)))
      (notSortedWines.filter((wine: Wine) => wine.id === id)[0][
        property
      ] as String) = value;

    this.setState({ notSortedWines });
  };

  update = async (
    value: string,
    id: number,
    price: number,
    availability: number,
    wantsToUpdateGlassPrice: boolean
  ) => {
    if (value !== this.state.inputValueOnFocus) {
      const updatedWine = await wineService.updateWineQuickAction(
        id,
        price,
        availability,
        wantsToUpdateGlassPrice
      );

      if (!updatedWine.error) {
        const { notSortedWines } = this.state;

        ((notSortedWines as Array<Wine>).filter(
          (wine: Wine) => wine.id === id
        )[0].glassPrice as number) = updatedWine.glassPrice as number;

        this.setState({ notSortedWines });

        toast(`Vino modificato con successo!`, {
          type: "success",
        });
      } else
        toast(`Oops! Qualcosa è andato storto`, {
          type: "error",
        });
    }
  };

  updatePriceQuickAction = async (
    e: SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    glassPrice: number
  ) => {
    const { value } = e.currentTarget;

    if (value !== this.state.inputValueOnFocus) {
      const oldGlassPrice =
        glassPrice % Math.floor(glassPrice) === 0.5
          ? glassPrice
          : Math.round(glassPrice);

      let newGlassPrice =
        (oldGlassPrice * Number(value)) / Number(this.state.inputValueOnFocus);

      newGlassPrice =
        newGlassPrice % Math.floor(newGlassPrice) === 0.5
          ? newGlassPrice
          : Math.round(newGlassPrice);

      const alertOptions = {
        title: "Prezzo al Calice",
        message: `Vuoi aggiornare il precedente prezzo al calice di ${oldGlassPrice} in base al vecchio prezzo? Il nuovo prezzo sarebbe ${newGlassPrice} a calice`,
        buttons: [
          {
            label: "Sì",
            onClick: async () =>
              this.update(value, id, price, availability, true),
          },
          {
            label: "No",
            onClick: async () =>
              this.update(value, id, price, availability, false),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      };

      if (glassPrice !== 0) confirmAlert(alertOptions);
      else this.update(value, id, price, availability, false);
    }
  };

  sellWine = async (
    id: number,
    price: number,
    availability: number,
    purchasePrice?: number,
    wineName?: string,
    soldGlasses?: number
  ) => {
    if (availability >= 0) {
      const updatedWine = await wineService.updateWineQuickAction(
        id,
        price,
        availability,
        false,
        purchasePrice,
        wineName,
        soldGlasses
      );

      if (!updatedWine.error) {
        const { notSortedWines } = this.state;

        (notSortedWines as Array<Wine>).filter(
          (wine: Wine) => wine.id === id
        )[0].availability = availability;

        this.setState({ notSortedWines });

        toast(`Scorte aggiornate!`, {
          type: "success",
        });
      } else {
        toast(`Oops! Qualcosa è andato storto`, {
          type: "error",
        });
      }
    }
  };

  updateWineQuickAction = async (
    e: SyntheticEvent<HTMLInputElement>,
    id: number,
    price: number,
    availability: number,
    wineName: string,
    skipAlert: boolean = false
  ) => {
    const { value, name } = e.currentTarget;

    const yes = async () => {
      const updatedWine = await wineService.updateWineQuickAction(
        id,
        price,
        availability,
        false,
        undefined,
        wineName
      );

      if (!updatedWine.error) {
        const { notSortedWines } = this.state;

        const wine = (notSortedWines as Array<Wine>).filter(
          (wine: Wine) => wine.id === id
        )[0];

        wine.glassPrice = updatedWine.glassPrice;
        wine.availability = updatedWine.availability;
        wine.price = updatedWine.price;

        this.setState({ notSortedWines });

        toast(`Vino modificato con successo!`, {
          type: "success",
        });
      } else
        toast(`Oops! Qualcosa è andato storto`, {
          type: "error",
        });
    };

    if (value !== this.state.inputValueOnFocus) {
      const alertOptions = {
        title: "Modifica Vino",
        message: `Vuoi aggiornare il vino con prezzo € ${price} e ${availability} bottiglie in magazzino?`,
        buttons: [
          {
            label: "Sì",
            onClick: async () => {
              await yes();
            },
          },
          {
            label: "No",
            onClick: () => {
              const { notSortedWines } = this.state;

              (notSortedWines as Array<Wine>).filter(
                (wine: Wine) => wine.id === id
              )[0][name] = this.state.inputValueOnFocus;

              this.setState({ notSortedWines });
            },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      };

      if (skipAlert) await yes();
      else confirmAlert(alertOptions);
    }
  };

  toggleWineUpdateAction = (id: number | undefined, data: any) => {
    data.wineMerchantId = id;
    this.setState({
      isUpdatingWine: !this.state.isUpdatingWine,
      dataForWineUpdate: data,
    });
  };

  handleWineUpdate = async (data: any) => {
    this.setState({ apiCalling: true });
    const updatedWine = await wineService.updateWine(data);
    this.toggleWineUpdateAction(undefined, {});

    this.setState({ apiCalling: false });

    if (!updatedWine.error) await this.componentDidMount();
  };

  handleWineDelete = async (wineId: number): Promise<void> => {
    this.setState({ apiCalling: true });
    const response = await wineService.deleteWine(wineId);

    this.setState({ apiCalling: false });

    if (response.success) await this.componentDidMount();
  };

  render() {
    const {
      isAddingWine,
      magazzinoKeys,
      selectedMagazzinoKey,
      tipologiaKeys,
      selectedTipologiaKey,
      notSortedWines,
      apiCalling,
      fonts,
      isUpdatingWine,
      dataForWineUpdate,
      sorting,
      sortedWines,
      wineFilter,
      userSettings,
      hasReachedMaxWines,
    } = this.state;

    const { onStopAddingWine } = this.props;

    return (
      <div className="overflow-visible">
        <div className="container justify-content-center">
          <div className="row mt-4">
            <div className="col">
              <h1 className="h1">Magazzino</h1>
            </div>
          </div>

          {hasReachedMaxWines && (
            <div className="row mt-4 pt-2">
              <div className="alert alert-danger mx-auto">
                Attenzione, hai raggiunto il numero massimo di referenze per il
                tuo piano.
              </div>
            </div>
          )}
          {isAddingWine && (
            <AddWine
              onWineAdd={this.handleWineAdd}
              onStopAddingWine={onStopAddingWine}
            />
          )}

          {isUpdatingWine && (
            <AddWine
              onWineUpdate={this.handleWineUpdate}
              onStopAddingWine={onStopAddingWine}
              data={dataForWineUpdate}
              onWineDelete={this.handleWineDelete}
            />
          )}
        </div>

        {!isAddingWine && !isUpdatingWine && (
          <React.Fragment>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h5 className="pt-4">Mostra:</h5>
                  <Select
                    keys={magazzinoKeys}
                    selectedKey={selectedMagazzinoKey}
                    onChange={this.handleMagazzinoKeyChange}
                  />
                </div>

                <div className="col">
                  <h5 className="pt-4">Tipologie:</h5>
                  <Select
                    keys={tipologiaKeys}
                    selectedKey={selectedTipologiaKey}
                    onChange={this.handleTipologiaKeyChange}
                  />
                </div>
              </div>

              <div className="row mt-3 mb-4">
                <div className="col">
                  <input
                    id="winefilter"
                    className="form-control mx-auto w-100"
                    style={{ borderColor: "#33333320" }}
                    name="wine"
                    placeholder="Cerca un vino in magazzino"
                    onChange={this.handleFilter}
                    value={wineFilter}
                  />
                </div>
              </div>
            </div>
            {apiCalling && (
              <div className="my-4 py-4">
                <Loader />
              </div>
            )}

            {!apiCalling && notSortedWines.length > 0 && (
              <React.Fragment>
                {sorting && (
                  <Carta
                    wines={sortedWines}
                    fonts={fonts}
                    inputFocused={this.inputFocused}
                    toggleWineUpdateAction={this.toggleWineUpdateAction}
                    handleChange={this.handleChange}
                    updateWineQuickAction={this.updateWineQuickAction}
                    updatePriceQuickAction={this.updatePriceQuickAction}
                    sellWine={this.sellWine}
                    editable={true}
                    wineToLookFor={wineFilter}
                    userSettings={userSettings}
                  />
                )}

                {!sorting && (
                  <Carta
                    wines={notSortedWines}
                    fonts={fonts}
                    inputFocused={this.inputFocused}
                    toggleWineUpdateAction={this.toggleWineUpdateAction}
                    handleChange={this.handleChange}
                    updateWineQuickAction={this.updateWineQuickAction}
                    updatePriceQuickAction={this.updatePriceQuickAction}
                    sellWine={this.sellWine}
                    editable={true}
                    wineToLookFor={wineFilter}
                    userSettings={userSettings}
                  />
                )}
              </React.Fragment>
            )}

            {!apiCalling && notSortedWines.length === 0 && (
              <div className="row">
                <div className="col text-center">
                  <p className="lead mt-4">
                    Ehi, sembra che il tuo magazzino sia vuoto, clicca su{" "}
                    <b>Aggiungi Vino</b> per esplorare migliaia di vini e dare
                    vita alla tua carta vini
                  </p>
                </div>
              </div>
            )}
          </React.Fragment>
        )}

        {!isAddingWine && !isUpdatingWine && (
          <button
            onClick={this.toggleAddingWineAction}
            className="btn btn-primary mt-4 mr-4 col-12 mb-4"
          >
            Aggiungi un Vino
          </button>
        )}
      </div>
    );
  }
}

export default Magazzino;
